import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, TransitionGroup as _TransitionGroup, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "shared-access__layout",
  key: "1"
}
const _hoisted_2 = {
  class: "shared-access",
  key: "2"
}
const _hoisted_3 = { class: "shared-access__title" }
const _hoisted_4 = { class: "purple-text" }
const _hoisted_5 = { class: "shared-access__content" }
const _hoisted_6 = { class: "shared-access__details" }
const _hoisted_7 = { class: "shared-access__btns" }

import { computed, ref } from 'vue';
import { concatName } from '@/utils/text';
import { storeToRefs } from 'pinia';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useSharedTasksStore } from '@/stores/sharingHistory/sharedTasks';
import UiPictoStatus from '@/components/text/UiPictoStatus.vue';
import { formatDateToCurrentUTC, isAllDayDate } from '@/utils/time';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { format, isValid } from 'date-fns';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalAcceptSharedTask',
  setup(__props) {

const { t } = useI18n();
const sharedTasks = useSharedTasksStore();
const { changeVisibility } = sharedTasks;
const { requests, sharedTasksView } = storeToRefs(sharedTasks);

const { formatWithLocale } = useCurrentLanguage();

const pending = ref(false);
const isOpen = computed(() => requests.value.length > 0);
const current = computed(() => requests.value[requests.value.length - 1]);

const date = computed(() => {
  if (!current.value) return '';
  const utcDateRaw = formatDateToCurrentUTC(current.value.taskInfo.deadline);
  const utcDate = new Date(utcDateRaw);
  let dayView = formatWithLocale(utcDate, 'dd MMMM yyyy');
  if (!isAllDayDate(utcDateRaw)) {
    dayView += ` | ${format(utcDate, 'HH:mm')}`;
    const end = new Date(
      formatDateToCurrentUTC(current.value.taskInfo.deadlineLast),
    );
    if (isValid(end)) dayView += ` - ${format(end, 'HH:mm')}`;
  }
  return dayView;
});

const requestingUserName = computed(
  () =>
    concatName(
      current.value?.userInfo.firstName,
      current.value?.userInfo.lastName,
    ) || 'guest',
);

const creatorName = computed(() =>
  concatName(current.value?.creator.firstName, current.value?.creator.lastName),
);

const switchToNextRequest = () => requests.value.pop();
const onSetRequestStatus = async (isAccepted: boolean) => {
  try {
    pending.value = true;
    await changeVisibility({
      linkId: current.value.shareId,
      availableToOpen: isAccepted,
    });
    switchToNextRequest();
    if (!requests.value.length && sharedTasksView.value?.content.length)
      await sharedTasks.fetch();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "blur",
    appear: ""
  }, {
    default: _withCtx(() => [
      (isOpen.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      (isOpen.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "purple-text" }, "Request to work with the task via the link", -1)),
              _createElementVNode("span", null, [
                _createTextVNode(" User " + _toDisplayString(requestingUserName.value) + " requests access to work with the \"", 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(current.value.taskInfo.name), 1),
                _cache[2] || (_cache[2] = _createTextVNode(" \" task. "))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", null, _toDisplayString(current.value.taskInfo.name), 1),
              _createElementVNode("span", null, _toDisplayString(current.value.taskInfo.taskContent), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", null, "Status", -1)),
                  _createVNode(UiPictoStatus, {
                    status: current.value.taskInfo.taskStatus
                  }, null, 8, ["status"])
                ]),
                _createElementVNode("div", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", null, "Date & Time", -1)),
                  _createTextVNode(" " + _toDisplayString(date.value), 1)
                ]),
                _createElementVNode("div", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("span", null, "Priority", -1)),
                  _createVNode(UiColorfilledText, {
                    type: current.value.taskInfo.taskPriority,
                    badge: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`task_priority.${current.value.taskInfo.taskPriority}`)), 1)
                    ]),
                    _: 1
                  }, 8, ["type"])
                ]),
                _createElementVNode("div", null, [
                  _cache[7] || (_cache[7] = _createElementVNode("span", null, "Creator", -1)),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(creatorName.value) + " ", 1),
                    _createVNode(UiAvatar, {
                      size: "3rem",
                      avatarLink: current.value.creator.avatar.uuidLink,
                      fallbackName: creatorName.value
                    }, null, 8, ["avatarLink", "fallbackName"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ui_button, {
                plain: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (onSetRequestStatus(false))),
                "is-disabled": pending.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('workspaces.accept.reject')), 1)
                ]),
                _: 1
              }, 8, ["is-disabled"]),
              _createVNode(_component_ui_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (onSetRequestStatus(true))),
                "is-disabled": pending.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('workspaces.accept.accept')), 1)
                ]),
                _: 1
              }, 8, ["is-disabled"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})