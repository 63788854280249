import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

interface Props {
  isLoading: boolean;
  isReadonly?: boolean;
  number?: number;
  status?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderInfo',
  props: {
    isLoading: { type: Boolean },
    isReadonly: { type: Boolean },
    number: {},
    status: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _directive_skeleton = _resolveDirective("skeleton")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.wrapper)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.number)
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("span", null, "№", -1)),
      _cache[1] || (_cache[1] = _createTextVNode(" ")),
      _createElementVNode("span", null, _toDisplayString(_ctx.number), 1)
    ], 2),
    (_ctx.isReadonly)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.number)
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "read only", -1)
        ]), 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2)), [
    [
      _directive_skeleton,
      _ctx.isLoading,
      void 0,
      { large: true }
    ]
  ])
}
}

})