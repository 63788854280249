<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2253 18.9902L5.78186 7.6765C5.42504 7.28994 5.42504 6.64883 5.78186 6.26228C6.13867 5.87573 6.73047 5.87573 7.08728 6.26228L17.5307 17.576C17.8875 17.9625 17.8875 18.6037 17.5307 18.9902C17.1739 19.3768 16.5821 19.3768 16.2253 18.9902Z"
      fill="currentColor"
    />
    <path
      d="M5.78179 18.9902C5.42498 18.6037 5.42498 17.9625 5.78179 17.576L16.2252 6.26228C16.582 5.87573 17.1738 5.87573 17.5306 6.26228C17.8875 6.64883 17.8875 7.28994 17.5306 7.6765L7.08722 18.9902C6.7304 19.3768 6.13861 19.3768 5.78179 18.9902Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
