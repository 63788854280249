import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, renderList as _renderList, Transition as _Transition, resolveComponent as _resolveComponent, Teleport as _Teleport } from "vue"
import _imports_0 from '@images/integrations/double-chevron-right.svg'
import _imports_1 from '@images/integrations/google-calendar.svg'


const _hoisted_1 = { class: "onboard__header" }
const _hoisted_2 = { class: "onboard__row speech" }
const _hoisted_3 = { class: "onboard__row" }
const _hoisted_4 = { class: "onboard__title" }
const _hoisted_5 = { class: "onboard__name-row" }
const _hoisted_6 = {
  key: 0,
  class: "onboard__row"
}
const _hoisted_7 = { class: "onboard__title" }
const _hoisted_8 = {
  key: 1,
  class: "onboard__accounts with-scroll"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "onboard__accounts-credentials" }
const _hoisted_11 = { class: "onboard__accounts-setup" }
const _hoisted_12 = {
  key: 0,
  class: "green-text"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }

import { computed, provide, ref, watch } from 'vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import UiInputUnderscore from '@/components/control/UiInputUnderscore.vue';
import services from '@/api/services';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { Workspace } from '@/types/workspaces';
import { useUserStore } from '@/stores/user';
import { useSettingsStore } from '@/stores/settings/settings';
import IconMarker from '@/components/icon/IconMarker.vue';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import EmptyAddButton from '@/components/empty/EmptyAddButton.vue';
import { googleSdkLoaded } from 'vue3-google-login';
import { GOOGLE_LOGIN_CONFIG } from '@/configs/google-login';
import { ElNotification } from 'element-plus';
import { useGoogleAccounts } from '@/stores/integrations/google';
import ModalSetupAccountGoogle from '@/components/modals/Integrations/google/ModalSetupAccountGoogle.vue';
import { GoogleAccount } from '@/types/integrations';
import { GoogleCalendarWithDirty } from '@/hooks/integrations/useCalendarsState';
import AccountSetupSpinner from '@/components/integrations/AccountSetupSpinner.vue';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalOnboarding',
  emits: ['close'],
  setup(__props) {



const isOpen = ref(false);
const pending = ref(false);

const onboardForm = ref({
  firstName: '',
  lastName: '',
  workspaceName: '',
});

provide(
  'startWorkspace',
  computed(() => onboardForm.value.workspaceName),
);

const isConfirmDisabled = computed(
  () =>
    !onboardForm.value.firstName ||
    !onboardForm.value.lastName ||
    !isAllAccountsViewed.value,
);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const settingsStore = useSettingsStore();
const usersStore = useSubordinatesStore();

const workspacesStore = useWorkspaceStore();
const { currentWorkspace, isWorkspaceOwner, list } =
  storeToRefs(workspacesStore);

const googleAccounts = useGoogleAccounts();
const { accountsList, fetchPending } = storeToRefs(googleAccounts);
const openedAccount = ref<GoogleAccount | null>();
const accountsUpdatesState = ref<
  Record<number, GoogleAccount & { calendars?: GoogleCalendarWithDirty[] }>
>({});

const viewedAccounts = ref<number[]>([]);
const isAllAccountsViewed = computed(
  () =>
    Object.keys(accountsUpdatesState.value).length ===
    viewedAccounts.value.length,
);

watch(
  () => accountsList.value,
  () => {
    const firstAccount = accountsList.value?.[0];
    !onboardForm.value.firstName &&
      (onboardForm.value.firstName = firstAccount?.firstName || '');
    !onboardForm.value.lastName &&
      (onboardForm.value.lastName = firstAccount?.secondName || '');
  },
  { immediate: true },
);

watch(
  () => accountsList.value,
  () => {
    accountsUpdatesState.value = accountsList.value.reduce((acc, a) => {
      !acc[a.id] && (acc[a.id] = a);
      return acc;
    }, {} as Record<number, GoogleAccount>);
  },
  { deep: true },
);

const onApplyUpdate = ({
  state,
  accountId,
}: {
  state: GoogleCalendarWithDirty;
  accountId: number;
}) => {
  openedAccount.value?.id && viewedAccounts.value.push(openedAccount.value.id);
  openedAccount.value = null;
  const account = accountsUpdatesState.value[accountId];
  const calendarId = account.calendars?.findIndex((c) => c.id === state.id);
  if (!calendarId) return;
  if (calendarId > -1) account.calendars?.splice(calendarId, 1, state);
};

const onCloseUpdate = () => {
  openedAccount.value?.id && viewedAccounts.value.push(openedAccount.value.id);
  openedAccount.value = null;
};

const onConfirm = async () => {
  if (!userData.value?.email) return;
  const requests = [];
  const generateUpdatesRequests = () =>
    Object.entries(accountsUpdatesState.value).map(([accountId, account]) =>
      (account.calendars as GoogleCalendarWithDirty[])
        ?.filter((c) => c.dirty)
        .map((c) => googleAccounts.setCalendar(c, accountId)),
    );

  try {
    pending.value = true;
    const { workspaceName, ...credentials } = onboardForm.value;
    requests.push(services.auth.changeUserInfo(credentials));
    if (workspaceName) {
      const modifyWorkspaceParams: Partial<Workspace> = {
        name: workspaceName,
        id: currentWorkspace.value?.id,
      };
      requests.push(services.workspaces.modify(modifyWorkspaceParams));
    }
    requests.push(...generateUpdatesRequests());
    await Promise.allSettled(requests);
    await Promise.allSettled([
      settingsStore.getUserData(),
      usersStore.fetch(),
      userStore.getUserData(),
    ]);

    const firstGoogleCalendar = accountsList.value?.[0]?.calendars?.[0];
    if (workspaceName || firstGoogleCalendar) await workspacesStore.fetchList();

    if (firstGoogleCalendar) {
      const id = list.value.find(
        (w) =>
          w.externalCalendarItem?.id === firstGoogleCalendar.id &&
          ['CREATE', 'CONFIRMED'].includes(w.externalCalendarItem?.status),
      )?.id;
      id && (await workspacesStore.setCurrent(id));
      await userStore.getUserData();
    }
  } catch (e) {
    console.error(e);
  } finally {
    isOpen.value = false;
    pending.value = false;
  }
};

const googleSignInAccountCallback = async () => {
  googleSdkLoaded((google) => {
    google.accounts.oauth2
      .initCodeClient({
        ...GOOGLE_LOGIN_CONFIG,
        callback: async (response) => {
          if (!response.code) return ElNotification.error('Error');
          const account = await googleAccounts.add({
            googleAuthorizationCode: response.code,
          });
          if (!account) return;
          const lastNumber = accountsList.value.push(account);
          await googleAccounts.fetchAccountCalendars(
            accountsList.value[lastNumber - 1],
          );
        },
      })
      .requestCode();
  });
};

watch(
  () => [userData.value, currentWorkspace.value],
  () => {
    if (!userData.value || !currentWorkspace.value) return;
    isOpen.value =
      !userData.value?.firstName &&
      !userData.value?.lastName &&
      !!currentWorkspace.value;
  },
  { deep: true, immediate: true },
);

watch(
  () => isOpen.value,
  () => isOpen.value && googleAccounts.fetchAccountsWithCalendars(),
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(ModalNotification, {
    width: "56rem",
    "is-open": isOpen.value,
    onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, [
        (_unref(isWorkspaceOwner))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('onboard.title')), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('onboard.join_title')) + " ", 1),
              _createVNode(IconMarker, {
                color: _unref(currentWorkspace)?.color
              }, null, 8, ["color"]),
              _createTextVNode(" " + _toDisplayString(_unref(currentWorkspace)?.name), 1)
            ], 64))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "onboard__form",
        onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[8] || (_cache[8] = _createElementVNode("span", null, "🔥 You have your first task!", -1)),
          _createElementVNode("div", null, [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "disabled-text" }, "Performer", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("span", null, "You", -1)),
            _createVNode(UiColorfilledText, {
              type: "URGENT",
              badge: ""
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("URGENT")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('onboard.first_last_name')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(UiInputUnderscore, {
              label: _ctx.$t('onboard.name_placeholder'),
              modelValue: onboardForm.value.firstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((onboardForm.value.firstName) = $event)),
              pictogram: false
            }, null, 8, ["label", "modelValue"]),
            _createVNode(UiInputUnderscore, {
              label: _ctx.$t('onboard.last_name_placeholder'),
              modelValue: onboardForm.value.lastName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((onboardForm.value.lastName) = $event)),
              pictogram: false
            }, null, 8, ["label", "modelValue"])
          ])
        ]),
        (_unref(isWorkspaceOwner))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t('onboard.workspace')), 1),
              _createVNode(UiInputUnderscore, {
                label: _ctx.$t('onboard.workspace_placeholder'),
                modelValue: onboardForm.value.workspaceName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((onboardForm.value.workspaceName) = $event)),
                pictogram: false
              }, null, 8, ["label", "modelValue"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_Transition, {
        name: "blur",
        appear: "",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_unref(fetchPending) && !_unref(accountsList).length)
            ? (_openBlock(), _createBlock(UiSpinner, {
                key: 0,
                class: "onboard__pending"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsUpdatesState.value, (account) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "onboard__accounts-item",
                    key: account.id,
                    onClick: ($event: any) => (openedAccount.value = account)
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", null, _toDisplayString(`${account?.firstName} ${account?.secondName}`), 1),
                      _createElementVNode("span", null, _toDisplayString(account?.email), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      (viewedAccounts.value.includes(account.id))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Calendar is set up "))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _cache[9] || (_cache[9] = _createElementVNode("span", null, "Set up calendar", -1)),
                            _cache[10] || (_cache[10] = _createElementVNode("img", {
                              src: _imports_0,
                              alt: ""
                            }, null, -1))
                          ], 64))
                    ])
                  ], 8, _hoisted_9))
                }), 128)),
                _createElementVNode("div", {
                  class: "onboard__accounts-empty",
                  onClick: googleSignInAccountCallback
                }, [
                  _cache[12] || (_cache[12] = _createElementVNode("img", {
                    src: _imports_1,
                    alt: ""
                  }, null, -1)),
                  (!_unref(accountsList)?.length)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _cache[11] || (_cache[11] = [
                        _createTextVNode(" Do you want to add your "),
                        _createElementVNode("span", { class: "purple-text" }, "Google Account", -1),
                        _createTextVNode("? All your calendars will be in Voiset at once! ")
                      ])))
                    : (_openBlock(), _createElementBlock("span", _hoisted_14, " Add another account ")),
                  _createVNode(EmptyAddButton)
                ])
              ]))
        ]),
        _: 1
      }),
      _createVNode(_component_ui_button, {
        class: "onboard__confirm",
        "is-disabled": isConfirmDisabled.value,
        "is-loading": pending.value,
        onClick: onConfirm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('onboard.confirm')), 1)
        ]),
        _: 1
      }, 8, ["is-disabled", "is-loading"]),
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        _createVNode(ModalSetupAccountGoogle, {
          account: openedAccount.value,
          onOnApply: onApplyUpdate,
          onOnClose: onCloseUpdate
        }, null, 8, ["account"])
      ])),
      _createVNode(AccountSetupSpinner, { pending: pending.value }, {
        label: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode("Connecting your Google calendars")
        ])),
        description: _withCtx(() => _cache[14] || (_cache[14] = [
          _createTextVNode(" Connect Google Calendars to the Voiset platform for automatic synchronization, updates, and event management. ")
        ])),
        _: 1
      }, 8, ["pending"])
    ]),
    footer: _withCtx(() => _cache[15] || (_cache[15] = [
      _createElementVNode("div", null, null, -1)
    ])),
    _: 1
  }, 8, ["is-open"]))
}
}

})