import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "14",
  height: "12",
  viewBox: "0 0 14 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconTick',
  props: {
  color: {
    type: String,
    required: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M1.13452 7.0001L5.46144 11.0626L12.673 1.58344",
      stroke: __props.color ? __props.color : 'currentColor',
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ]))
}
}

})