import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "note-transform-steps"
}

import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { storeToRefs } from 'pinia';
import NoteTransformStepPrepare from '@/components/notes/transform/steps/NoteTransformStepPrepare.vue';
import NoteTransformStepWorkspace from '@/components/notes/transform/steps/NoteTransformStepWorkspace.vue';
import NoteTransformStepCalendar from '@/components/notes/transform/steps/NoteTransformStepCalendar.vue';
import NoteTransformStepGenerate from '@/components/notes/transform/steps/NoteTransformStepGenerate.vue';
import NoteTransformStepTask from '@/components/notes/transform/steps/NoteTransformStepTask.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformSteps',
  setup(__props) {

const notesTransformStore = useNotesTransformStore();
const { currentStep, isStarted } = storeToRefs(notesTransformStore);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    appear: ""
  }, {
    default: _withCtx(() => [
      (_unref(isStarted))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_TransitionGroup, { name: "fade-slide" }, {
              default: _withCtx(() => [
                (_unref(currentStep) === 'prepare')
                  ? (_openBlock(), _createBlock(NoteTransformStepPrepare, { key: "1" }))
                  : (_unref(currentStep) === 'workspace')
                    ? (_openBlock(), _createBlock(NoteTransformStepWorkspace, { key: "2" }))
                    : (_unref(currentStep) === 'calendar')
                      ? (_openBlock(), _createBlock(NoteTransformStepCalendar, { key: "3" }))
                      : (_unref(currentStep) === 'generate')
                        ? (_openBlock(), _createBlock(NoteTransformStepGenerate, { key: "4" }))
                        : (_unref(currentStep) === 'task')
                          ? (_openBlock(), _createBlock(NoteTransformStepTask, { key: "5" }))
                          : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})