<template>
  <div class="popup-share__container">
    <div class="popup-share__create" @click="copyText">
      <ui-input v-model="linkInput" readonly :class="{ success: isCopied }">
        <template #action>
          <icon-copy class="popup-share__create-copy" />
        </template>
      </ui-input>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiInput from '../control/UiInput.vue';
import services from '@/api/services';
import { copyToClipboard } from '@/utils/copyToClipboard';
import IconCopy from '../icon/IconCopy.vue';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { useI18n } from 'vue-i18n';
import { ElNotification } from 'element-plus';

const { t } = useI18n();
const { activeTask } = storeToRefs(useViewTaskStore());

const emits = defineEmits(['onClose']);

const isLoading = ref(false);
const isCopied = ref(false);
const linkInput = ref('');

const createLink = async () => {
  try {
    isLoading.value = true;
    if (activeTask.value?.id) {
      const { data } = await services.share.create({
        taskId: activeTask.value?.id,
      });
      linkInput.value = data;
    }
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const copyText = () => {
  if (!linkInput.value) return;
  const result = copyToClipboard(linkInput.value);
  if (!result) return;
  isCopied.value = true;
  ElNotification.success({
    message: t('task_sharing.link_copied'),
    offset: 80,
  });
  emits('onClose');
};

onMounted(() => createLink());
</script>

<style lang="scss" scoped>
.popup-share {
  &__create {
    &-copy {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }
  }
}
</style>
