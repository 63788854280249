import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import services from '@/api/services';
import { LangUI } from '@/types/lang';
import { ElNotification } from 'element-plus';
import { User } from '@/types/user';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';

export const useSettingsStore = defineStore('settings', () => {
  const { t } = useI18n();

  const finishLoad = ref(false);
  const pending = ref(false);

  const avatarURL = ref('');
  const name = ref('');
  const surName = ref('');
  const email = ref('');
  const position = ref('');
  const role = ref('');
  const lang = ref<LangUI['abbreviation']>();

  const newTask = ref();
  const statusChanges = ref();
  const overdueTasks = ref();
  const newComments = ref();
  const inviteConfirmation = ref();

  const userStore = useUserStore();

  const voiceRecognitionList = ref<Array<LangUI>>([]);
  const localeUiList = ref();
  const selectVoiceRecognition = ref<User['locale']['name']>();

  const InterfaceLanguageList = ref(['English', 'Russian']);
  const selectInterfaceLanguage = ref('English');

  const timezoneList = ref(['Europe/Istanbul', 'Africa/Abidjan']);
  const selectTimezone = ref('Europe/Istanbul');

  const getAvatar = async (id: number) => {
    try {
      const { data } = await services.files.getFileById(id);
      avatarURL.value = URL.createObjectURL(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getUserData = async () => {
    const { data } = await services.auth.userData();
    if (data.avatar?.id) {
      await getAvatar(data.avatar?.id);
    }

    name.value = data.firstName;
    surName.value = data.lastName;
    email.value = data.email;
    position.value = data.position.name;
    role.value = data.role;
    selectVoiceRecognition.value = data.locale.name;
    lang.value = data.uiLocale.abbreviation;
  };

  const setUserData = async () => {
    try {
      pending.value = true;
      const payload = {
        firstName: name.value,
        lastName: surName.value,
        position: position.value,
      };
      await services.auth.changeUserInfo(payload);
      ElNotification.success({
        message: t('settings.changes_saved'),
        offset: 80,
      });
      await userStore.getUserData();
    } catch (e) {
      console.error(e);
      ElNotification.error({ message: t('some_error'), offset: 80 });
    } finally {
      pending.value = false;
    }
  };

  const getNotificationsData = async () => {
    const { data } = await services.notifications.notificationData();

    const findNotificationByType = (type: number) =>
      data.find(({ notificationTypeId }) => notificationTypeId === type)
        ?.isActive;

    newTask.value = findNotificationByType(1);
    statusChanges.value = findNotificationByType(3);
    overdueTasks.value = findNotificationByType(7);
    newComments.value = findNotificationByType(2);
    inviteConfirmation.value = findNotificationByType(6);
  };

  const setNotificationsData = async (
    isActive: boolean,
    notificationTypeId: number,
  ) => {
    if (!finishLoad.value) return;
    await services.notifications.notificationSetup({
      isActive,
      notificationTypeId,
    });
  };

  const getLocaleVoiceData = async () => {
    const { data } = await services.locale.getLocaleVoice();
    voiceRecognitionList.value = data;
  };

  const getLocaleUiList = async () => {
    const { data } = await services.locale.getLocaleUiList();
    localeUiList.value = data;
  };

  async function saveVoiceRecognition() {
    if (!finishLoad.value) return;

    try {
      pending.value = true;
      const payload = { locale: selectVoiceRecognition.value };
      await services.auth.changeUserInfo(payload);
      ElNotification.success({
        message: t('settings.changes_saved'),
        offset: 80,
      });

      await userStore.getUserData();
    } catch (e) {
      console.error(e);
      ElNotification.error({ message: t('some_error'), offset: 80 });
    } finally {
      pending.value = false;
    }
  }

  async function saveUiLocale(lang: LangUI['abbreviation']) {
    try {
      const payload = {
        uiLocale: lang,
      };
      await services.auth.changeUserInfo(payload);
      ElNotification.success({
        message: t('settings.changes_saved'),
        offset: 80,
      });
    } catch (e) {
      console.error(e);
      ElNotification.error({ message: t('some_error'), offset: 80 });
    }
  }

  async function getAll() {
    finishLoad.value = false;
    await Promise.allSettled([
      getUserData(),
      getNotificationsData(),
      getLocaleVoiceData(),
    ]);
    finishLoad.value = true;
  }

  watch(newTask, (val) => setNotificationsData(val, 1));
  watch(statusChanges, (val) => setNotificationsData(val, 3));
  watch(overdueTasks, (val) => setNotificationsData(val, 7));
  watch(newComments, (val) => setNotificationsData(val, 2));
  watch(inviteConfirmation, (val) => setNotificationsData(val, 6));

  const $reset = () => {
    finishLoad.value = false;
    pending.value = false;

    avatarURL.value = '';
    name.value = '';
    surName.value = '';
    email.value = '';
    position.value = '';
    role.value = '';
    lang.value = undefined;
    newTask.value = undefined;
    statusChanges.value = undefined;
    overdueTasks.value = undefined;
    newComments.value = undefined;
    inviteConfirmation.value = undefined;
  };

  return {
    name,
    surName,
    email,
    position,
    lang,
    role,
    newTask,
    statusChanges,
    overdueTasks,
    newComments,
    inviteConfirmation,
    voiceRecognitionList,
    localeUiList,
    selectVoiceRecognition,
    InterfaceLanguageList,
    selectInterfaceLanguage,
    timezoneList,
    selectTimezone,
    avatarURL,
    finishLoad,
    getAll,
    getAvatar,
    getLocaleUiList,
    saveUiLocale,
    setUserData,
    saveVoiceRecognition,
    getLocaleVoiceData,
    getUserData,
    pending,
    $reset,
  };
});
