import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "24",
  viewBox: "0 0 21 24",
  fill: "none"
}
const _hoisted_2 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconPlay',
  props: {
  color: {
    type: String,
    required: false,
    default: '#F2F7F7',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M19.5 10.268C20.8333 11.0378 20.8333 12.9623 19.5 13.7321L3 23.2583C1.66666 24.0281 7.67973e-07 23.0659 8.35271e-07 21.5263L1.66808e-06 2.47372C1.73538e-06 0.934117 1.66667 -0.0281314 3 0.741669L19.5 10.268Z",
      fill: __props.color
    }, null, 8, _hoisted_2)
  ]))
}
}

})