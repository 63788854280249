import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-create-footer footer" }
const _hoisted_2 = { class: "footer__lang" }
const _hoisted_3 = { class: "footer__record" }
const _hoisted_4 = { class: "footer__control" }

import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed, toRefs, watch } from 'vue';
import LangRecognitionSelector from '@/components/modals/Tasks/ModalCreateTask/LangRecognitionSelector.vue';
import IconCheck from '@/components/icon/IconCheck.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import UiAudioRecorder from '@/components/ui/UiAudioRecorder.vue';
import { sockets } from '@/api/services';

type Props = {
  isSubmitLoading: boolean;
  isOpenModal: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateNoteModalFooter',
  props: {
    isSubmitLoading: { type: Boolean },
    isOpenModal: { type: Boolean }
  },
  emits: ['click-submit'],
  setup(__props: any) {

const userStore = useUserStore();
const createNoteStore = useCreateNoteStore();
const { audio, state } = storeToRefs(createNoteStore);





const onStartRecord = async () => {
  sockets.speech.emitter
    .subscribe('AddPartialTranscript', (speech: string) => {
      audio.value.runtimeSpeech = speech;
    })
    .subscribe('AddTranscript', (speech: string) => {
      audio.value.runtimeSpeech = '';
      state.value.description += speech;
    })
    .subscribe('onClose', () => (audio.value.runtimeSpeech = ''));
};

const onStopRecord = async () => {
  sockets.speech.emitter.reset();
  await createNoteStore.generate(state.value.description);
};

const isCreateBtnDisabled = computed(() => {
  const { title, description } = state.value;

  return !(title && description) || isRecording.value;
});

const { isRecording, recordFile } = toRefs(audio.value);

watch(recordFile, (val) => (audio.value.blobData = val || undefined));

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(LangRecognitionSelector)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(UiAudioRecorder, {
        audio: _unref(audio),
        onOnStartRecord: onStartRecord,
        onOnStopRecord: onStopRecord
      }, null, 8, ["audio"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ui_button, {
        isDisabled: isCreateBtnDisabled.value,
        isLoading: _ctx.isSubmitLoading,
        class: "footer__control-submit",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-submit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('notes.create')) + " ", 1),
          _createVNode(IconCheck, { color: "#ffffff" })
        ]),
        _: 1
      }, 8, ["isDisabled", "isLoading"])
    ])
  ]))
}
}

})