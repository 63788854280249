import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachAnalytics',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, {
    title: _ctx.$t('coaches.home.4.title'),
    content: _ctx.$t('coaches.home.4.content')
  }, null, 8, ["title", "content"]))
}
}

})