import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

import LayoutHeader from '@/components/layout/default/LayoutHeader.vue';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'default',
  setup(__props) {

const tariffStore = useTariffStore();
const { isPlansModalVisible } = storeToRefs(tariffStore);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.meta.header)
      ? (_openBlock(), _createBlock(LayoutHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(ModalPlanBuy, {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (isPlansModalVisible.value = false)),
        "is-open": _unref(isPlansModalVisible)
      }, null, 8, ["is-open"])
    ])
  ], 64))
}
}

})