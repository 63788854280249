import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

type Props = { isOpen?: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalInvalidLink',
  props: {
    isOpen: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    "model-value": _ctx.isOpen,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, _ctx.$attrs, { "modal-class": "modal-notification-medium" }), {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("p", null, "⛔️", -1),
      _createElementVNode("p", null, "The task cannot be opened", -1)
    ])),
    footer: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("div", null, null, -1)
    ])),
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createTextVNode(" Check that the link is correct "))
    ]),
    _: 1
  }, 16, ["model-value"]))
}
}

})