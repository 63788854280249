import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IconView',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M2.53876 10.6314C2.48708 10.4931 2.48708 10.3436 2.53876 10.2054C3.57739 7.42335 6.52553 5.41669 10.0001 5.41669C13.4732 5.41669 16.4198 7.42135 17.4607 10.202C17.5131 10.34 17.5131 10.4894 17.4607 10.628C16.4228 13.41 13.4747 15.4167 10.0001 15.4167C6.52703 15.4167 3.57963 13.412 2.53876 10.6314Z",
      stroke: "#9BC95E",
      "stroke-width": "1.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M2.53876 10.6314C2.48708 10.4931 2.48708 10.3436 2.53876 10.2054C3.57739 7.42335 6.52553 5.41669 10.0001 5.41669C13.4732 5.41669 16.4198 7.42135 17.4607 10.202C17.5131 10.34 17.5131 10.4894 17.4607 10.628C16.4228 13.41 13.4747 15.4167 10.0001 15.4167C6.52703 15.4167 3.57963 13.412 2.53876 10.6314Z",
      stroke: "black",
      "stroke-opacity": "0.2",
      "stroke-width": "1.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.6667 10.4167C11.6667 10.8587 11.4911 11.2826 11.1785 11.5952C10.8659 11.9077 10.442 12.0833 10 12.0833C9.55797 12.0833 9.13404 11.9077 8.82148 11.5952C8.50892 11.2826 8.33333 10.8587 8.33333 10.4167C8.33333 9.97464 8.50892 9.55072 8.82148 9.23816C9.13404 8.92559 9.55797 8.75 10 8.75C10.442 8.75 10.8659 8.92559 11.1785 9.23816C11.4911 9.55072 11.6667 9.97464 11.6667 10.4167Z",
      stroke: "#9BC95E",
      "stroke-width": "1.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.6667 10.4167C11.6667 10.8587 11.4911 11.2826 11.1785 11.5952C10.8659 11.9077 10.442 12.0833 10 12.0833C9.55797 12.0833 9.13404 11.9077 8.82148 11.5952C8.50892 11.2826 8.33333 10.8587 8.33333 10.4167C8.33333 9.97464 8.50892 9.55072 8.82148 9.23816C9.13404 8.92559 9.55797 8.75 10 8.75C10.442 8.75 10.8659 8.92559 11.1785 9.23816C11.4911 9.55072 11.6667 9.97464 11.6667 10.4167Z",
      stroke: "black",
      "stroke-opacity": "0.2",
      "stroke-width": "1.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
}

})