import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ui-filter" }
const _hoisted_2 = { class: "ui-filter__header" }
const _hoisted_3 = { class: "ui-filter-dropdown" }
const _hoisted_4 = { class: "ui-filter__header" }
const _hoisted_5 = { class: "ui-filter-dropdown" }

import { computed, inject } from 'vue';
import { TableFilter } from '@/types/common';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { completedTasksStatuses, ITaskStatusItem } from '@/consts';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTableFiltersPresetList',
  props: {
    icon: {},
    options: {},
    label: {},
    filterField: {},
    component: {}
  },
  setup(__props: any) {

const { t, te } = useI18n();

const getLabel = (label?: string) => (label && te(label) ? t(label) : label);

const props = __props;

const filtersState: Record<string, any> | undefined = inject('filtersState');
const setFilter: ((...args: any[]) => void) | undefined = inject('setFilter');

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const isEnabledAssignPreset = computed(
  () =>
    filtersState?.executorIdList?.length === 1 &&
    filtersState?.executorIdList.includes(userData.value?.id),
);

const setAssignPreset = () => {
  if (!filtersState?.executorIdList || !userData.value?.id) return;
  filtersState.executorIdList = [userData.value.id];
};

const isEnabledCompletedTasksPreset = computed(
  () =>
    filtersState?.statusList.length === 2 &&
    filtersState.statusList.every((s: ITaskStatusItem) =>
      completedTasksStatuses.includes(s),
    ),
);

const setCompletedTasksPreset = () => {
  if (!filtersState?.statusList) return;
  filtersState.statusList = completedTasksStatuses;
};

const resetFilters = () => {
  const filter = filtersState?.[props.filterField];
  setFilter && setFilter(props.filterField, filter instanceof Array ? [] : '');
};

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(getLabel(_ctx.label)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: _normalizeClass(['ui-filter__button', { active: isEnabledAssignPreset.value }]),
        onClick: setAssignPreset
      }, _toDisplayString(_ctx.$t('tasks.filters.values.ASSIGMENT_TO_ME')), 3),
      _createElementVNode("button", {
        class: _normalizeClass([
          'ui-filter__button',
          { active: isEnabledCompletedTasksPreset.value },
        ]),
        onClick: setCompletedTasksPreset
      }, _toDisplayString(_ctx.$t('tasks.filters.values.COMPLETED_TASKS')), 3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('tasks.filters.time_presets')), 1),
      _createElementVNode("div", {
        class: "ui-filter__clear",
        onClick: resetFilters
      }, _toDisplayString(_ctx.$t('tasks.filters.clear')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ui-filter-dropdown__row",
          key: option?.label
        }, [
          _createVNode(_component_el_radio_group, {
            name: props.filterField,
            "model-value": _unref(filtersState)?.[_ctx.filterField],
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(setFilter)?.(_ctx.filterField, $event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, {
                value: option.value,
                label: getLabel(option?.label) || option.value
              }, null, 8, ["value", "label"])
            ]),
            _: 2
          }, 1032, ["name", "model-value"])
        ]))
      }), 128))
    ])
  ]))
}
}

})