import { defineStore } from 'pinia';
import { ref } from 'vue';
import services from '@/api/services';
import { Pageable } from '@/types/pagination';
import { Comment } from '@/types/comment';
import { format, isToday } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';

type CommentListItem = {
  unparseDate: Date;
  date: string;
  block: string;
  messages: Array<Comment>;
};

interface ServicesList {
  task: any;
  share: any;
}

export const useViewTaskCommentsStore = defineStore('viewTaskComments', () => {
  const { t } = useI18n();
  const { formatWithLocale } = useCurrentLanguage();
  const commentsInfo = ref<Pageable<Comment> | null>(null);
  const isLoadingComment = ref(true);
  const currentPage = ref(1);
  const currentTask = ref<number | string>(0);

  const commentsList = ref<CommentListItem[] | null>(null);

  const servicesList: ServicesList = {
    task: {
      base: services.comments,
    },
    share: {
      base: services.share,
    },
  };

  const currentService = ref<keyof ServicesList>('task');

  const toggleLoadingComment = (val: boolean) => {
    isLoadingComment.value = val;
  };

  const formatDateBlock = (date: string) => {
    return format(new Date(date), 'yyyyMMdd');
  };

  const formatDateText = (date: string) => {
    const pareDate = new Date(date);
    if (isToday(pareDate)) {
      return t('today');
    }
    return formatWithLocale(pareDate, 'dd MMM');
  };

  const parseCommentData = (messages: Array<Comment>) => {
    messages.forEach((message) => {
      if (commentsList.value) {
        const currentMessageBlock = commentsList.value.find(
          (item) => item.block === formatDateBlock(message.createTime),
        );
        if (currentMessageBlock) {
          const currentMessage = currentMessageBlock?.messages.find(
            (item) => item.commentId === message.commentId,
          );

          if (!currentMessage) {
            currentMessageBlock?.messages.push(message);

            currentMessageBlock?.messages.sort(
              (a, b) =>
                new Date(a.createTime).getTime() -
                new Date(b.createTime).getTime(),
            );
          }
        } else {
          commentsList.value.push({
            unparseDate: new Date(message.createTime),
            date: formatDateText(message.createTime),
            block: formatDateBlock(message.createTime),
            messages: [message],
          });

          commentsList.value.sort(
            (a, b) =>
              new Date(a.unparseDate).getTime() -
              new Date(b.unparseDate).getTime(),
          );
        }
      } else {
        commentsList.value = [
          {
            unparseDate: new Date(message.createTime),
            date: formatDateText(message.createTime),
            block: formatDateBlock(message.createTime),
            messages: [message],
          },
        ];
      }
    });
  };

  const getComments = async (
    id: number | string,
    page?: number,
    service?: keyof ServicesList,
  ) => {
    if (service) {
      currentService.value = service;
    }
    if (page) {
      currentPage.value = page;
    }

    currentTask.value = id;
    toggleLoadingComment(true);
    const { data } = await servicesList[currentService.value].base.getComments(
      id,
      {
        pageNum: currentPage.value,
        pageSize: 10,
      },
    );

    commentsInfo.value = data;
    parseCommentData(data.content);
    toggleLoadingComment(false);
  };

  const createComment = async (id: number | string, text: string) => {
    if (text) {
      await servicesList[currentService.value].base.addComments(id, text);
    }
  };

  const loadNextPage = async () => {
    if (commentsInfo.value) {
      if (commentsInfo.value?.totalPages > currentPage.value) {
        currentPage.value++;
        await getComments(currentTask.value);
      }
    }
  };

  const resetComments = () => {
    commentsInfo.value = null;
    commentsList.value = null;
    currentService.value = 'task';
    currentPage.value = 0;
  };
  return {
    commentsInfo,
    isLoadingComment,
    getComments,
    createComment,
    resetComments,
    commentsList,
    loadNextPage,
  };
});
