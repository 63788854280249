import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "notes-list-workspace" }
const _hoisted_2 = { class: "notes-list-workspace__header" }

import { ref } from 'vue';
import { Note } from '@/types/notes';
import NoteItem from '@/components/notes/item/NoteItem.vue';
import { useSelection } from '@/stores/notes/useNotesList';
import { useNoteToTaskDrag } from '@/hooks/notes/useNoteToTaskDrag';
import NotesSelection from '@/components/notes/NotesSelection.vue';
import { Workspace } from '@/types/workspaces';
import { useVModel } from '@vueuse/core';
import { NoteTransformType } from '@/stores/notes/useNotesTransform';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotesListWorkspace',
  props: {
    workspace: {},
    modelValue: {},
    mode: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const notes = useVModel(props, 'modelValue', emit);

const listRef = ref<HTMLDivElement>();
const { selection, isSelected } = useSelection();
useNoteToTaskDrag({
  el: listRef,
  list: notes,
  isSelected,
  selection,
  group: 'notes-workspaces',
});

const removeNote = (id: number) => {
  const index = notes.value.findIndex((v) => v.id === id);
  if (index !== -1) notes.value.splice(index, 1);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NotesSelection, {
      modelValue: _unref(selection),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selection) ? (selection).value = $event : null))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        style: _normalizeStyle({ backgroundColor: _ctx.workspace.color ?? 'gray' })
      }, null, 4),
      _createElementVNode("p", null, _toDisplayString(_ctx.workspace.name), 1)
    ]),
    _createElementVNode("div", {
      ref_key: "listRef",
      ref: listRef,
      class: "notes-list-workspace__body"
    }, [
      _createVNode(_TransitionGroup, { name: "note-item" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notes), (item) => {
            return (_openBlock(), _createBlock(NoteItem, {
              key: item.id,
              data: item,
              "is-selected": _unref(isSelected)(item.id),
              "hide-controls": "",
              uncheckable: "",
              "hide-number": "",
              closable: _ctx.mode === 'many',
              "disable-open": "",
              onClose: removeNote
            }, null, 8, ["data", "is-selected", "closable"]))
          }), 128))
        ]),
        _: 1
      })
    ], 512)
  ]))
}
}

})