import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "colorfilled-text" }

import { computed, PropType } from 'vue';
import IconBadge from '@/components/icon/IconBadge.vue';
import { mappedRoleType } from '@/consts/task-priority';

export type RoleType = keyof typeof mappedRoleType;

type ColorfilledStyles = {
  color: string;
  background: string | false;
  'text-transform'?: string;
  'font-size'?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UiColorfilledText',
  props: {
  background: {
    type: String,
    required: false,
  },
  color: {
    type: String,
    required: false,
  },
  type: {
    type: String as PropType<RoleType>,
    required: false,
  },
  uppercase: {
    type: Boolean,
    required: false,
  },
  badge: {
    type: Boolean,
    required: false,
  },
  fontSize: {
    type: Number,
    required: false,
  },
  badgeOnly: {
    type: Boolean,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const notificationStyles = {
  'border-radius': '2rem',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  'justify-content': 'center',
  'align-items': 'center',
  'white-space': 'nowrap',
  cursor: 'pointer',
  flex: '0 1 auto',
};

const styles = computed<ColorfilledStyles>(() => {
  const color = props.color || '#1A2A39';

  const background =
    !props.badgeOnly &&
    (props.background ||
      (props.type ? mappedRoleType[props.type]?.background : '#52CB50'));

  return {
    color,
    background,
    'text-transform': props.uppercase ? 'uppercase' : '',
    ...(props.fontSize && { 'font-size': props.fontSize + 'px' }),
    ...(props.type === 'NOTIFICATION' && notificationStyles),
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "colorfilled-text__label",
      style: _normalizeStyle(styles.value)
    }, [
      (__props.badge || __props.badgeOnly)
        ? (_openBlock(), _createBlock(IconBadge, {
            key: 0,
            color: _unref(mappedRoleType)[__props.type]?.badge
          }, null, 8, ["color"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ]))
}
}

})