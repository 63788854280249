import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "workspaces-create-switch__header" }
const _hoisted_2 = { class: "workspaces-create-switch__footer" }

import { Workspace } from '@/types/workspaces';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import UiButton from '@/components/control/UiButton.vue';
import { useUserStore } from '@/stores/user';
import { ref } from 'vue';

type Props = {
  workspace?: Workspace;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalSwitchWorkspace',
  props: {
    workspace: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const pending = ref(false);

const workspacesStore = useWorkspaceStore();
const { setCurrent } = workspacesStore;

const userStore = useUserStore();
const { getUserData } = userStore;

const onSwitch = async () => {
  if (!props.workspace) return;
  try {
    pending.value = true;
    await setCurrent(props.workspace.id);
    await getUserData();
    emits('close');
  } catch (e) {
    console.error(e);
  } finally {
    pending.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalNotification, {
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    width: "34rem",
    "is-open": ""
  }, _createSlots({
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('workspaces.switch.title')), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(UiButton, {
          plain: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('workspaces.switch.stay')), 1)
          ]),
          _: 1
        }),
        _createVNode(UiButton, {
          onClick: onSwitch,
          "is-loading": pending.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('workspaces.switch.switch')), 1)
          ]),
          _: 1
        }, 8, ["is-loading"])
      ])
    ]),
    _: 2
  }, [
    (_ctx.$slots.default)
      ? {
          name: "default",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}
}

})