import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "31",
  height: "32",
  viewBox: "0 0 31 32",
  fill: "none"
}
const _hoisted_2 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconStar',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M16.8803 5.89546L19.1646 10.611C19.3885 11.0739 19.8206 11.3949 20.3217 11.4692L25.4317 12.2293C26.6942 12.4176 27.1966 13.9988 26.2829 14.9048L22.5877 18.5737C22.2246 18.9345 22.0593 19.4532 22.1452 19.9625L23.0172 25.1423C23.232 26.4237 21.9122 27.4013 20.7837 26.7951L16.2165 24.3478C15.7688 24.1077 15.2325 24.1077 14.7835 24.3478L10.2163 26.7951C9.08779 27.4013 7.76799 26.4237 7.98405 25.1423L8.85481 19.9625C8.94071 19.4532 8.77541 18.9345 8.41227 18.5737L4.7171 14.9048C3.80339 13.9988 4.3058 12.4176 5.56833 12.2293L10.6783 11.4692C11.1794 11.3949 11.6128 11.0739 11.8367 10.611L14.1197 5.89546C14.6846 4.72951 16.3154 4.72951 16.8803 5.89546Z",
      stroke: __props.color,
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ]))
}
}

})