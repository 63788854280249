<script setup lang="ts">
import { useNewTaskStore } from '@/stores/newTask';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';

const newTask = useNewTaskStore();
const createNoteStore = useCreateNoteStore();
defineEmits(['closeSidebar']);
</script>

<template>
  <div class="actions">
    <button
      class="actions__item"
      @click="
        newTask.isModalOpen = true;
        $emit('closeSidebar');
      "
    >
      <img src="@images/create-actions/task.svg" alt="" />
      Task
    </button>
    <!--    <button class="actions__item">-->
    <!--      <img src="@images/create-actions/event.svg" alt="" />-->
    <!--      Event-->
    <!--    </button>-->
    <button
      class="actions__item"
      @click="
        createNoteStore.start();
        $emit('closeSidebar');
      "
    >
      <img src="@images/create-actions/note.svg" alt="" />
      {{ $t('notes.one') }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.actions {
  @include flex-column(0.6rem);

  &__item {
    @include f14;
    @include flex-row(0.8rem);
    align-items: center;
    line-height: 2rem;
    border-radius: 0.8rem;
    box-shadow: 10px 10px 40px 0 #42424240;
    background: #fcfcfc;
    padding: 1rem;
    text-align: left;

    transition: all 0.2s ease;

    &:hover {
      background: #f5f5f5;
    }
  }
}
</style>
