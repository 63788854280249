import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@images/integrations-spinner/core-orbit.svg'
import _imports_1 from '@images/integrations-spinner/inner-orbit.svg'
import _imports_2 from '@images/integrations-spinner/center-orbit.svg'
import _imports_3 from '@images/integrations-spinner/outer-orbit.svg'
import _imports_4 from '@images/integrations-spinner/voiset-logo.svg'


const _hoisted_1 = { class: "integration-spinner" }
const _hoisted_2 = ["src"]

type Props = { link?: string };

export default /*@__PURE__*/_defineComponent({
  __name: 'UiIntegrationSpinner',
  props: {
    link: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div data-v-15601cee><img src=\"" + _imports_0 + "\" alt=\"\" data-v-15601cee></div><div data-v-15601cee><img src=\"" + _imports_1 + "\" alt=\"\" data-v-15601cee></div><div data-v-15601cee><img src=\"" + _imports_2 + "\" alt=\"\" data-v-15601cee></div><div data-v-15601cee><img src=\"" + _imports_3 + "\" alt=\"\" data-v-15601cee></div><img src=\"" + _imports_4 + "\" alt=\"\" class=\"voiset\" data-v-15601cee>", 5)),
    _renderSlot(_ctx.$slots, "logo", {}, () => [
      (_ctx.link)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.link,
            alt: "",
            class: "integration-logo"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})