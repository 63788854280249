<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 10.5199C11.59 10.5199 11.25 10.1799 11.25 9.76994V6.43994C11.25 6.02994 11.59 5.68994 12 5.68994C12.41 5.68994 12.75 6.02994 12.75 6.43994V9.76994C12.75 10.1899 12.41 10.5199 12 10.5199Z"
      fill="currentColor"
    />
    <path
      d="M12.0201 20.3502C9.44011 20.3502 6.87011 19.9402 4.42011 19.1202C3.51011 18.8202 2.82011 18.1702 2.52011 17.3502C2.22011 16.5302 2.32011 15.5902 2.81011 14.7702L4.08011 12.6502C4.36011 12.1802 4.61011 11.3002 4.61011 10.7502V8.65023C4.61011 4.56023 7.93011 1.24023 12.0201 1.24023C16.1101 1.24023 19.4301 4.56023 19.4301 8.65023V10.7502C19.4301 11.2902 19.6801 12.1802 19.9601 12.6502L21.2301 14.7702C21.7001 15.5502 21.7801 16.4802 21.4701 17.3302C21.1601 18.1802 20.4801 18.8302 19.6201 19.1202C17.1701 19.9502 14.6001 20.3502 12.0201 20.3502ZM12.0201 2.75023C8.76011 2.75023 6.11011 5.40023 6.11011 8.66023V10.7602C6.11011 11.5702 5.79011 12.7402 5.37011 13.4302L4.10011 15.5602C3.84011 15.9902 3.78011 16.4502 3.93011 16.8502C4.08011 17.2502 4.42011 17.5502 4.90011 17.7102C9.50011 19.2402 14.5601 19.2402 19.1601 17.7102C19.5901 17.5702 19.9201 17.2502 20.0701 16.8302C20.2301 16.4102 20.1801 15.9502 19.9501 15.5602L18.6801 13.4402C18.2601 12.7502 17.9401 11.5802 17.9401 10.7702V8.67023C17.9301 5.40023 15.2801 2.75023 12.0201 2.75023Z"
      fill="currentColor"
    />
    <path
      d="M11.9999 22.8998C10.9299 22.8998 9.87992 22.4598 9.11992 21.6998C8.35992 20.9398 7.91992 19.8898 7.91992 18.8198H9.41992C9.41992 19.4998 9.69992 20.1598 10.1799 20.6398C10.6599 21.1198 11.3199 21.3998 11.9999 21.3998C13.4199 21.3998 14.5799 20.2398 14.5799 18.8198H16.0799C16.0799 21.0698 14.2499 22.8998 11.9999 22.8998Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
