import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "workspace-invitations"
}
const _hoisted_2 = { class: "users-multiselect" }
const _hoisted_3 = { class: "workspace-invitations__footer" }

import UiAvatar from '@/components/ui/UiAvatar.vue';
import { computed, ref } from 'vue';
import { isEmailValid } from '@/utils/text';
import { Workspace } from '@/types/workspaces';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { useWorkspaceStore } from '@/stores/workspaces';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { SubordinateViewType } from '@/utils/subordinates';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCreateWorkspaceInvitations',
  props: {
    workspace: {},
    users: { default: () => [] }
  },
  emits: ['next-step'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const { t } = useI18n();

const workspaceStore = useWorkspaceStore();

const invitationsStore = useInvitationsStore();

const inviteEmailsList = ref<string[]>([]);
const inviteUsersList = ref<SubordinateViewType[]>([]);
const pending = ref(false);
const isInvitationsSent = ref(false);

const invitationsCount = computed(
  () =>
    inviteEmailsList.value.filter(Boolean).length +
    inviteUsersList.value.length,
);

const isInvitationDisabled = computed(
  () =>
    !invitationsCount.value ||
    inviteEmailsList.value.some((_, i) => isErrorState(i)),
);

const isErrorState = (i: number) =>
  inviteEmailsList.value[i] && !isEmailValid(inviteEmailsList.value[i]);

const onSendInvitations = async () => {
  if (!props.workspace) return;
  const companyId = props.workspace.id;

  try {
    pending.value = true;
    const emailInvitations = inviteEmailsList.value.map((email) =>
      invitationsStore.createInvite({ email, companyId }),
    );
    const userInvitations = inviteUsersList.value.map(({ email }) =>
      invitationsStore.createInvite({ email, companyId }),
    );

    const [emailInvitationsResult, userInvitationsResult] = await Promise.all([
      Promise.allSettled(emailInvitations),
      Promise.allSettled(userInvitations),
    ]);

    const getRejectedEmails = (promiseResults: PromiseSettledResult<any>[]) => {
      return (
        promiseResults.filter(
          ({ status }) => status === 'rejected',
        ) as PromiseRejectedResult[]
      ).map((r) => r.reason?.config?.params?.email);
    };

    const rejectedUsersInvitationsEmails = getRejectedEmails(
      userInvitationsResult,
    );
    const rejectedEmailsInvitationsEmails = getRejectedEmails(
      emailInvitationsResult,
    );

    const addUsersToWorkspaceList = inviteUsersList.value.filter(
      ({ email }) => !rejectedUsersInvitationsEmails.includes(email),
    );

    await Promise.allSettled(
      addUsersToWorkspaceList.map(({ id: userId }) =>
        workspaceStore.addUser({ userId, companyId }),
      ),
    );

    isInvitationsSent.value = true;

    const rejectedInvitationsEmails = [
      ...rejectedUsersInvitationsEmails,
      ...rejectedEmailsInvitationsEmails,
    ];

    if (rejectedInvitationsEmails.length) {
      ElNotification.warning({
        message: `Some of invitations not sent! (${rejectedInvitationsEmails
          .filter(Boolean)
          .join(', ')})`,
        offset: 80,
      });
    } else {
      ElNotification.success({
        message: 'All invitations has been successfully sent!',
        offset: 80,
      });
    }
    emits('next-step', 3);
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_ui_input_underscore = _resolveComponent("ui-input-underscore")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "blur",
    appear: "",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (pending.value)
        ? (_openBlock(), _createBlock(UiSpinner, {
            key: 0,
            style: {"height":"25rem"}
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('workspaces.create.title-invite')), 1),
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('workspaces.create.subtitle-invite')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inviteEmailsList.value.length + 1, (email, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: email }, [
                (i < 3)
                  ? (_openBlock(), _createBlock(_component_ui_input_underscore, {
                      key: 0,
                      class: _normalizeClass(['workspace-invitations__input', { error: isErrorState(i) }]),
                      label: "Email",
                      modelValue: inviteEmailsList.value[i],
                      "onUpdate:modelValue": ($event: any) => ((inviteEmailsList.value[i]) = $event),
                      onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (!isInvitationDisabled.value && onSendInvitations()), ["stop"]), ["enter"]))
                    }, null, 8, ["class", "modelValue", "onUpdate:modelValue"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('workspaces.create.choose')), 1),
            _createVNode(_component_el_select, {
              class: "ui__multiselect",
              modelValue: inviteUsersList.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inviteUsersList).value = $event)),
              multiple: "",
              "collapse-tags": "",
              placeholder: "Select users",
              "value-key": "id",
              disabled: !_ctx.users.length
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.fullName || item.email,
                    value: item
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(UiAvatar, {
                          size: "3rem",
                          "avatar-link": item?.avatar?.uuidLink,
                          "fallback-name": item.fullName
                        }, null, 8, ["avatar-link", "fallback-name"]),
                        _createTextVNode(" " + _toDisplayString(item.fullName || item.email), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "disabled"]),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "footer", {
                onInvite: onSendInvitations,
                disabled: isInvitationDisabled.value
              }, () => [
                _createVNode(_component_ui_button, {
                  plain: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('next-step')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('workspaces.create.skip_btn')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ui_button, {
                  "is-disabled": isInvitationDisabled.value,
                  onClick: onSendInvitations
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('workspaces.create.invite_btn')), 1)
                  ]),
                  _: 1
                }, 8, ["is-disabled"])
              ])
            ])
          ]))
    ]),
    _: 3
  }))
}
}

})