import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "12",
  viewBox: "0 0 8 12",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconPause',
  props: {
  color: {
    type: String,
    required: false,
    default: '#F2F7F7',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "2",
      height: "12",
      rx: "1",
      fill: __props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("rect", {
      x: "6",
      width: "2",
      height: "12",
      rx: "1",
      fill: __props.color
    }, null, 8, _hoisted_3)
  ]))
}
}

})