import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "comments-item__message" }
const _hoisted_2 = { class: "comments-item__message-content" }
const _hoisted_3 = { class: "comments-item__message-name" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "comments-item__message-controls-block" }
const _hoisted_6 = {
  key: 0,
  class: "comments-item__message-date"
}

import { computed } from 'vue';
import { IFile } from '@/types/common';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { mapFnTextToHtml } from '@/utils/string';

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalViewCommentsItem',
  props: {
    isMyComment: { type: Boolean, default: true },
    createTime: {},
    avatar: {},
    message: {},
    creatorFullName: {}
  },
  setup(__props: any) {

const props = __props;

const { formatWithLocale } = useCurrentLanguage();

const parseDate = computed(() => {
  if (props.createTime) {
    return formatWithLocale(new Date(props.createTime), 'hh:mmaa');
  }
  return null;
});

const messageWithUrls = computed(() => {
  const words = props.message?.split(' ');
  return words
    ?.map(mapFnTextToHtml)
    .filter(Boolean)
    .join(' ')
    .replace(/"/g, '');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["comments-item", { 'other-comment-item': !_ctx.isMyComment }])
  }, [
    _createVNode(UiAvatar, {
      "avatar-link": _ctx.avatar?.uuidLink,
      "fallback-name": _ctx.creatorFullName,
      class: "comments-item__avatar",
      size: "3rem"
    }, null, 8, ["avatar-link", "fallback-name"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.creatorFullName), 1),
        _createElementVNode("span", {
          class: "comments-item__message-text",
          innerHTML: messageWithUrls.value
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (parseDate.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(parseDate.value), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})