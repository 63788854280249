import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "note-calendar-day" }
const _hoisted_2 = { class: "note-calendar-day__info" }

import { NotesByCalendarDay } from '@/stores/notes/useNotesByCalendar';
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import NoteTransformCalendarTask from '@/components/notes/transform/calendar/NoteTransformCalendarTask.vue';
import NoteTransformCalendarNote from '@/components/notes/transform/calendar/NoteTransformCalendarNote.vue';
import { useNoteCalendarDrag } from '@/hooks/notes/useNoteCalendarDrag';
import { useVModel } from '@vueuse/core';
import { isNote } from '@/utils/note';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformCalendarDay',
  props: {
    date: {},
    items: {},
    color: {}
  },
  emits: ['update:items'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const data = useVModel(props, 'items', emit);

const day = computed(() => format(new Date(props.date), 'd MMMM'));
const weekDay = computed(() => format(new Date(props.date), 'iiii'));
const workload = computed(
  () => Math.min((data.value.length || 0) / 10, 1) * 100,
);
const workloadColor = computed(() => {
  switch (true) {
    case workload.value >= 60:
      return '#EC7975';
    case workload.value >= 30:
      return '#F19F3C';
    default:
      return '#9BC95E';
  }
});

const removeItem = (id: number) => {
  const index = data.value.findIndex((v) => v.id === id);
  if (index !== -1) data.value.splice(index, 1);
};

const listRef = ref<HTMLDivElement>();

useNoteCalendarDrag({
  el: listRef,
  list: data,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(day.value), 1),
      _createElementVNode("p", null, _toDisplayString(weekDay.value), 1),
      _createElementVNode("p", {
        style: _normalizeStyle({ color: workloadColor.value })
      }, _toDisplayString(_ctx.$t('notes.workload.current', { percentage: workload.value })), 5)
    ]),
    _createElementVNode("div", {
      ref_key: "listRef",
      ref: listRef,
      class: "note-calendar-day__items"
    }, [
      _createVNode(_TransitionGroup, { name: "note-item" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_unref(isNote)(item))
                ? (_openBlock(), _createBlock(NoteTransformCalendarNote, {
                    key: item.id + item.title,
                    data: item,
                    color: _ctx.color,
                    onClose: removeItem
                  }, null, 8, ["data", "color"]))
                : (_openBlock(), _createBlock(NoteTransformCalendarTask, {
                    color: _ctx.color,
                    key: item.id + item.name,
                    data: item
                  }, null, 8, ["color", "data"]))
            ], 64))
          }), 256))
        ]),
        _: 1
      })
    ], 512)
  ]))
}
}

})