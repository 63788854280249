import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/shared-tasks/success.webp'
import _imports_1 from '@images/shared-tasks/clocks.svg'


import UiButton from '@/components/control/UiButton.vue';
import { useSharedTasksStore } from '@/stores/sharingHistory/sharedTasks';
import { ref } from 'vue';
import { isAxiosError } from 'axios';

type Props = { isOpen?: boolean; id: string };

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalHasNoAccess',
  props: {
    isOpen: { type: Boolean },
    id: {}
  },
  setup(__props: any) {

const props = __props;

const sharedTasks = useSharedTasksStore();

const isRequestSent = ref(false);
const isRequestAlreadySent = ref(false);

const onRequest = async () => {
  const response = await sharedTasks.requestAccess(props.id);
  console.log(response);

  if (isAxiosError(response) && response.response?.status === 400)
    return (isRequestAlreadySent.value = true);
  isRequestSent.value = true;
};

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, _mergeProps({
      "model-value": _ctx.isOpen,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, _ctx.$attrs, { "modal-class": "modal-notification-medium" }), {
      header: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("p", null, "🙁", -1),
        _createElementVNode("p", null, "Access to the task is closed or expired.", -1)
      ])),
      footer: _withCtx(() => [
        _createVNode(UiButton, { onClick: onRequest }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Request access")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _cache[5] || (_cache[5] = _createTextVNode(" Request access and work with the task using the link. "))
      ]),
      _: 1
    }, 16, ["model-value"]),
    _createVNode(_component_el_dialog, _mergeProps({
      "model-value": isRequestSent.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => {
      isRequestSent.value = false;
      _ctx.$emit('close');
    })
    }, _ctx.$attrs, { "modal-class": "modal-notification-medium" }), {
      header: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode("The request was sent!")
      ])),
      default: _withCtx(() => [
        _cache[7] || (_cache[7] = _createElementVNode("img", {
          src: _imports_0,
          width: "255",
          alt: ""
        }, null, -1))
      ]),
      _: 1
    }, 16, ["model-value"]),
    _createVNode(_component_el_dialog, _mergeProps({
      "model-value": isRequestAlreadySent.value,
      onClose: _cache[2] || (_cache[2] = ($event: any) => {
      isRequestAlreadySent.value = false;
      _ctx.$emit('close');
    })
    }, _ctx.$attrs, { "modal-class": "modal-notification-medium" }), {
      default: _withCtx(() => _cache[8] || (_cache[8] = [
        _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1),
        _createElementVNode("h2", { style: "" }, "The request has already been sent", -1),
        _createElementVNode("span", null, "Contact the task owner or check status later.", -1)
      ])),
      _: 1
    }, 16, ["model-value"])
  ], 64))
}
}

})