import services from '@/api/services';
import {
  subordinatesMappingToView,
  SubordinateViewType,
} from '@/utils/subordinates';
import { computed, ref } from 'vue';

export const useSubordinatesList = () => {
  const isLoading = ref(false);
  const subordinates = ref<SubordinateViewType[]>([]);
  const searchFilter = ref('');

  const fetchSubordinates = async () => {
    try {
      isLoading.value = true;
      const response = await services.positions.getSubordinates({
        pageSize: 50,
      });
      subordinates.value = response.data.content.map(subordinatesMappingToView);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  const getSubordinate = computed(
    () => (id: number) => subordinates.value.find((s) => s.id === id),
  );

  return {
    subordinates,
    searchFilter,
    getSubordinate,
    fetchSubordinates,
    isLoading,
  };
};
