import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-view-modal__body"
}
const _hoisted_2 = { class: "user-view-modal__row header-row" }
const _hoisted_3 = { class: "user-view-modal__label" }
const _hoisted_4 = { class: "user-view-modal__value" }

import UiModal from '@/components/ui/UiModal.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { ISubordinate } from '@/types/subordinate';
import IconClose from '@/components/icon/IconClose.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import ModalDeleteUser from '@/components/modals/UserView/ModalDeleteUser.vue';
import { format } from 'date-fns';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { useWorkspaceStore } from '@/stores/workspaces';
import { concatName } from '@/utils/text';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalUserView',
  props: {
    isOpen: { type: Boolean },
    user: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const workspacesStore = useWorkspaceStore();
const { isWorkspaceOwner } = storeToRefs(workspacesStore);

const userStore = useUserStore();
const { userData: currentUser } = storeToRefs(userStore);

const subordinatesStore = useSubordinatesStore();
const { fetch: usersFetch } = subordinatesStore;

const isUserDeleteConfirmationOpen = ref(false);

const props = __props;

const user = toRef(props, 'user');

const cardRows = computed(() => [
  {
    label: t('popups.user_view.email'),
    value: user.value?.email,
  },
  {
    label: t('popups.user_view.position'),
    value: user.value?.positionName,
  },
  {
    label: t('popups.user_view.registered'),
    value: user.value?.createDateTime
      ? format(new Date(user.value?.rawCreateDateTime), 'dd.MM.yyyy')
      : t('undefined'),
  },
]);

const fullName = computed(() => {
  return `${user.value?.firstName} ${user.value?.lastName}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiModal, {
    title: _ctx.$t('popups.user_view.user_profile'),
    class: "user-view-modal",
    "is-open": _ctx.isOpen,
    onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    body: _withCtx(() => [
      (user.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(UiAvatar, {
                "avatar-link": user.value.avatar?.uuidLink,
                size: "6rem",
                fallbackName: _unref(concatName)(user.value.firstName, user.value.lastName)
              }, null, 8, ["avatar-link", "fallbackName"]),
              _createElementVNode("span", null, _toDisplayString(fullName.value), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cardRows.value, ({ label, value }) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "user-view-modal__row",
                key: label
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(label), 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(value), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_unref(isWorkspaceOwner) && _unref(currentUser)?.id !== user.value?.id)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "user-view-modal__delete-user",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isUserDeleteConfirmationOpen.value = true))
          }, [
            _createVNode(IconClose),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('popups.user_view.delete_user')), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(ModalDeleteUser, {
        user: user.value,
        "is-open": isUserDeleteConfirmationOpen.value,
        onOnClose: _cache[1] || (_cache[1] = ($event: any) => (isUserDeleteConfirmationOpen.value = false)),
        onOnDelete: _cache[2] || (_cache[2] = ($event: any) => {
          isUserDeleteConfirmationOpen.value = false;
          _ctx.$emit('on-close');
          _unref(usersFetch)();
        })
      }, null, 8, ["user", "is-open"])
    ]),
    _: 1
  }, 8, ["title", "is-open"]))
}
}

})