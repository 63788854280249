import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { TaskDTO, TaskFilters, TTaskSorts } from '@/types/tasks';
import { endOfDay, format, startOfDay } from 'date-fns';
import { tableSorting } from '@/utils/table';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { TableFilter } from '@/types/common';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import {
  CREATOR_FILTERS,
  EXECUTOR_FILTERS,
  FAST_FILTERS,
  PRIORITY_FILTERS,
  STATUS_FILTERS,
} from '@/stores/tasks/helpers';

export const useTasksFiltersStore = defineStore(
  'tasksFilters',
  () => {
    const { formatWithLocale } = useCurrentLanguage();

    const initialFilters = (): TaskFilters => {
      const dates = JSON.parse(localStorage.calendarRange || false);
      return {
        dateTo: format(
          endOfDay(dates?.end ? new Date(dates.end) : new Date()),
          'yyyy-MM-dd HH:mm',
        ),
        dateFrom: format(
          startOfDay(dates?.start ? new Date(dates?.start) : new Date()),
          'yyyy-MM-dd HH:mm',
        ),
        pageNum: 1,
        pageSize: 10,
        taskDTO: {
          creatorIdList: [],
          executorIdList: [],
          priorityList: [],
          statusList: [],
          taskFastFilters: '',
          taskAssignment: '',
          taskNameSearch: '',
        },
      };
    };

    const sorts = reactive<TTaskSorts>({
      order: 'DESC',
      columnSort: 'createDateTime',
    });
    const filters = ref<TaskFilters>(initialFilters());

    const subordinates = useSubordinatesStore();
    const { subordinatesOverallListView, overallListPending } =
      storeToRefs(subordinates);

    const setExistingWorkspaceUsers = async () => {
      if (overallListPending.value) return;
      await subordinates.fetchOverallList();
      const actualUsersIds = subordinatesOverallListView.value?.map(
        (s) => s.id,
      );
      if (!actualUsersIds) return;
      const currentActualExecutors =
        filters.value.taskDTO.executorIdList.filter((id) =>
          actualUsersIds.includes(id),
        );
      const currentActualCreators = filters.value.taskDTO.creatorIdList.filter(
        (id) => actualUsersIds.includes(id),
      );
      setFilter('executorIdList', currentActualExecutors);
      setFilter('creatorIdList', currentActualCreators);
    };

    const filtersList = computed<TableFilter[]>(() => [
      FAST_FILTERS,
      STATUS_FILTERS,
      PRIORITY_FILTERS,
      CREATOR_FILTERS(subordinatesOverallListView.value),
      EXECUTOR_FILTERS(subordinatesOverallListView.value),
    ]);

    const dashboardFiltersList = computed<TableFilter[]>(() => [
      FAST_FILTERS,
      STATUS_FILTERS,
      PRIORITY_FILTERS,
      EXECUTOR_FILTERS(subordinatesOverallListView.value),
    ]);

    function setSort(columnSort: TTaskSorts['columnSort']) {
      tableSorting<TTaskSorts>(sorts, columnSort);
    }

    function setFilter<FilterField extends keyof TaskFilters['taskDTO']>(
      field: FilterField,
      values: (typeof filters.value.taskDTO)[FilterField],
    ) {
      filters.value.taskDTO[field] = values;
    }

    const refreshFilters = () =>
      (Object.keys(filters.value.taskDTO) as (keyof TaskDTO)[]).forEach(
        <T extends keyof TaskDTO>(key: T) => {
          filters.value.taskDTO[key] = (
            filters.value.taskDTO[key] instanceof Array ? [] : ''
          ) as TaskDTO[T];
        },
      );

    const refreshDashboardFilters = () =>
      (Object.keys(filters.value.taskDTO) as (keyof TaskDTO)[]).forEach(
        <T extends keyof TaskDTO>(key: T) => {
          if (key === 'creatorIdList') return;
          filters.value.taskDTO[key] = (
            filters.value.taskDTO[key] instanceof Array ? [] : ''
          ) as TaskDTO[T];
        },
      );

    function setPagination(pageNum: number, pageSize: number) {
      filters.value.pageNum = pageNum;
      filters.value.pageSize = pageSize;
    }

    function setPage(page: number) {
      filters.value.pageNum = page;
    }

    function setPageSize(pageSize: number) {
      filters.value.pageSize = pageSize;
    }

    const getDatesRange = computed({
      get() {
        return {
          start: new Date(filters.value.dateFrom),
          end: new Date(filters.value.dateTo),
        };
      },
      set(value: { start: Date; end: Date }) {
        setDatesRange(value);
      },
    });

    const getDatesRangeView = computed(() => {
      const DATE_FORMAT = 'dd MMM';
      const localeFormat = (date: string) =>
        formatWithLocale(new Date(date), DATE_FORMAT);
      const start = localeFormat(filters.value.dateFrom);
      const end = localeFormat(filters.value.dateTo);
      const singleDay = start === end && localeFormat(filters.value.dateTo);
      return { start, end, singleDay };
    });

    function setDatesRange(dates: { start: Date; end: Date }) {
      const DATE_FORMAT = 'yyyy-MM-dd HH:mm';
      const { start, end } = dates;
      localStorage.calendarRange = JSON.stringify(dates);
      filters.value.dateFrom = format(startOfDay(new Date(start)), DATE_FORMAT);
      filters.value.dateTo = format(endOfDay(new Date(end)), DATE_FORMAT);
    }

    return {
      sorts,
      filters,
      filtersList,
      dashboardFiltersList,
      refreshFilters,
      refreshDashboardFilters,
      getDatesRange,
      getDatesRangeView,
      setDatesRange,
      setFilter,
      setSort,
      initialFilters,
      setPagination,
      setPage,
      setPageSize,
      setExistingWorkspaceUsers,
    };
  },
  { persist: { paths: ['filters'] } },
);
