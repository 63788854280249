import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  height: "20",
  viewBox: "0 0 20 20",
  width: "20",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconCrown',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      fill: __props.color,
      d: "M13.9167 16.4421H6.08332C5.46665 16.4421 4.84165 16.0004 4.63332 15.4254L1.18332 5.76706C0.75832 4.55039 1.06665 3.99206 1.39999 3.74206C1.73332 3.49206 2.35832 3.34206 3.40832 4.09206L6.65832 6.41706C6.75832 6.47539 6.84999 6.50039 6.91665 6.48372C6.99165 6.45872 7.04999 6.39206 7.09165 6.27539L8.55832 2.36706C8.99999 1.20039 9.64999 1.02539 9.99999 1.02539C10.35 1.02539 11 1.20039 11.4417 2.36706L12.9083 6.27539C12.95 6.38372 13.0083 6.45872 13.0833 6.48372C13.1583 6.50872 13.25 6.48372 13.3417 6.40872L16.3917 4.23372C17.5083 3.43372 18.1583 3.59206 18.5167 3.85039C18.8667 4.11706 19.1917 4.70872 18.7333 6.00039L15.3667 15.4254C15.1583 16.0004 14.5333 16.4421 13.9167 16.4421ZM2.23332 4.84206C2.24999 4.95872 2.28332 5.12539 2.36665 5.34206L5.81665 15.0004C5.84999 15.0837 5.99999 15.1921 6.08332 15.1921H13.9167C14.0083 15.1921 14.1583 15.0837 14.1833 15.0004L17.55 5.58372C17.6667 5.26706 17.7 5.05039 17.7083 4.92539C17.5833 4.96706 17.3917 5.05872 17.1167 5.25872L14.0667 7.43372C13.65 7.72539 13.1583 7.81706 12.7167 7.68372C12.275 7.55039 11.9167 7.20039 11.7333 6.72539L10.2667 2.81706C10.1583 2.52539 10.0583 2.38372 9.99999 2.31706C9.94165 2.38372 9.84165 2.52539 9.73332 2.80872L8.26665 6.71706C8.09165 7.19206 7.73332 7.54206 7.28332 7.67539C6.84165 7.80872 6.34165 7.71706 5.93332 7.42539L2.68332 5.10039C2.49165 4.96706 2.34165 4.88372 2.23332 4.84206Z"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      fill: __props.color,
      d: "M14.5833 18.9585H5.41663C5.07496 18.9585 4.79163 18.6752 4.79163 18.3335C4.79163 17.9918 5.07496 17.7085 5.41663 17.7085H14.5833C14.925 17.7085 15.2083 17.9918 15.2083 18.3335C15.2083 18.6752 14.925 18.9585 14.5833 18.9585Z"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      fill: __props.color,
      d: "M12.0833 12.2915H7.91663C7.57496 12.2915 7.29163 12.0082 7.29163 11.6665C7.29163 11.3248 7.57496 11.0415 7.91663 11.0415H12.0833C12.425 11.0415 12.7083 11.3248 12.7083 11.6665C12.7083 12.0082 12.425 12.2915 12.0833 12.2915Z"
    }, null, 8, _hoisted_4)
  ]))
}
}

})