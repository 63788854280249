import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@images/logo.svg'


const _hoisted_1 = {
  key: 0,
  class: "sign-up"
}
const _hoisted_2 = { class: "sign-up__left" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "sign-up__content" }
const _hoisted_6 = {
  key: 1,
  class: "sign-up__mobile"
}
const _hoisted_7 = { class: "sign-up__content" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["src"]

import { ElNotification } from 'element-plus';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { onMounted } from 'vue';
import { useWindowSize } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'auth',
  setup(__props) {

const route = useRoute();
const { t } = useI18n();

const { width: windowWidth } = useWindowSize();

onMounted(() => {
  type MessageKeyType = 'error' | 'used' | 'done';
  type NotificationsType = Extract<
    keyof typeof ElNotification,
    'info' | 'error' | 'success'
  >;

  const messageMapping: Record<MessageKeyType, NotificationsType> = {
    error: 'error',
    used: 'info',
    done: 'success',
  };

  const messageKey = route.query.message as MessageKeyType;
  const messageType = messageMapping[messageKey || 'error'];
  if (messageKey) {
    ElNotification[messageType]({
      message: t('messages.' + messageKey),
    });
  }
});

return (_ctx: any,_cache: any) => {
  return (_unref(windowWidth) > 990)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            class: "sign-up__logo",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createElementVNode("h2", {
            class: "sign-up__description",
            innerHTML: _ctx.$t(_ctx.$route.meta?.slogan || 'auth.slogan')
          }, null, 8, _hoisted_3),
          _createVNode(_Transition, {
            name: "blur",
            appear: "",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("img", {
                class: "sign-up__img",
                key: _ctx.$route.meta?.image as (string | undefined),
                src: 
            require(`@images/sign-up/${_ctx.$route.meta?.image || 'ai-head.webp'}`)
          ,
                alt: ""
              }, null, 8, _hoisted_4))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "sign-up__logo",
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("h2", {
          class: "sign-up__description",
          innerHTML: _ctx.$t(_ctx.$route.meta?.slogan || 'auth.slogan')
        }, null, 8, _hoisted_8),
        _createElementVNode("img", {
          class: "sign-up__img",
          src: require(`@images/sign-up/${_ctx.$route.meta?.image || 'ai-head.webp'}`),
          alt: ""
        }, null, 8, _hoisted_9)
      ]))
}
}

})