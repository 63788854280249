import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]
const _hoisted_7 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconFilter',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M18.3333 6.0415H13.3333C12.9916 6.0415 12.7083 5.75817 12.7083 5.4165C12.7083 5.07484 12.9916 4.7915 13.3333 4.7915H18.3333C18.6749 4.7915 18.9583 5.07484 18.9583 5.4165C18.9583 5.75817 18.6749 6.0415 18.3333 6.0415Z",
      fill: __props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M5.00008 6.0415H1.66675C1.32508 6.0415 1.04175 5.75817 1.04175 5.4165C1.04175 5.07484 1.32508 4.7915 1.66675 4.7915H5.00008C5.34175 4.7915 5.62508 5.07484 5.62508 5.4165C5.62508 5.75817 5.34175 6.0415 5.00008 6.0415Z",
      fill: __props.color
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M8.33341 8.95833C6.38341 8.95833 4.79175 7.36667 4.79175 5.41667C4.79175 3.46667 6.38341 1.875 8.33341 1.875C10.2834 1.875 11.8751 3.46667 11.8751 5.41667C11.8751 7.36667 10.2834 8.95833 8.33341 8.95833ZM8.33341 3.125C7.06675 3.125 6.04175 4.15 6.04175 5.41667C6.04175 6.68333 7.06675 7.70833 8.33341 7.70833C9.60008 7.70833 10.6251 6.68333 10.6251 5.41667C10.6251 4.15 9.60008 3.125 8.33341 3.125Z",
      fill: __props.color
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M18.3333 15.2085H15C14.6583 15.2085 14.375 14.9252 14.375 14.5835C14.375 14.2418 14.6583 13.9585 15 13.9585H18.3333C18.675 13.9585 18.9583 14.2418 18.9583 14.5835C18.9583 14.9252 18.675 15.2085 18.3333 15.2085Z",
      fill: __props.color
    }, null, 8, _hoisted_5),
    _createElementVNode("path", {
      d: "M6.66675 15.2085H1.66675C1.32508 15.2085 1.04175 14.9252 1.04175 14.5835C1.04175 14.2418 1.32508 13.9585 1.66675 13.9585H6.66675C7.00841 13.9585 7.29175 14.2418 7.29175 14.5835C7.29175 14.9252 7.00841 15.2085 6.66675 15.2085Z",
      fill: __props.color
    }, null, 8, _hoisted_6),
    _createElementVNode("path", {
      d: "M11.6667 18.1248C9.71667 18.1248 8.125 16.5332 8.125 14.5832C8.125 12.6332 9.71667 11.0415 11.6667 11.0415C13.6167 11.0415 15.2083 12.6332 15.2083 14.5832C15.2083 16.5332 13.6167 18.1248 11.6667 18.1248ZM11.6667 12.2915C10.4 12.2915 9.375 13.3165 9.375 14.5832C9.375 15.8498 10.4 16.8748 11.6667 16.8748C12.9333 16.8748 13.9583 15.8498 13.9583 14.5832C13.9583 13.3165 12.9333 12.2915 11.6667 12.2915Z",
      fill: __props.color
    }, null, 8, _hoisted_7)
  ]))
}
}

})