import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

import { computed } from 'vue';

import IconSpinner from '../icon/IconSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiButton',
  props: {
    color: { type: [String, Boolean], default: 'default' },
    isDisabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isBorder: { type: Boolean, default: false },
    to: {}
  },
  setup(__props: any) {

const props = __props;

const currentComponent = computed(() => {
  return props.to ? 'router-link' : 'button';
});

const classes = computed(() => ({
  [`ui-button-color--${props.color}`]: true,
  [`ui-button--border`]: props.isBorder,
  [`ui-button--disabled`]: props.isLoading || props.isDisabled,
  [`ui-button--loading`]: props.isLoading,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(currentComponent.value), {
    class: _normalizeClass(["ui-button", classes.value]),
    disabled: _ctx.isDisabled || _ctx.isLoading,
    to: _ctx.to || null
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(IconSpinner, {
            key: 0,
            "circle-color": "#ffffff",
            color: "#ffffff",
            class: "ui-button__loading"
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "disabled", "to"]))
}
}

})