import { RouteRecordRaw } from 'vue-router';

const CalendarView = () => import('@/views/calendar/calendar.vue');
const CalendarIndex = () => import('@/views/calendar/index.vue');
const SharedTasks = () => import('@/views/calendar/sharedTasks.vue');

export default {
  path: '/calendar',
  component: CalendarView,
  meta: {
    layout: 'app',
  },
  children: [
    { path: '', name: 'Calendar', component: CalendarIndex },
    {
      path: 'shared-tasks',
      name: 'SharedTasks',
      component: SharedTasks,
    },
  ],
} as RouteRecordRaw;
