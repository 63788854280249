<template>
  <el-popover
    v-model:visible="isShow"
    placement="bottom"
    popper-class="notifications"
    trigger="click"
    width="557"
    @hide="toggleShow(false)"
    @show="toggleShow(true)"
  >
    <div class="user__notification-content">
      <div class="user__notification-header">
        <span class="user__notification-title">
          {{ $t('notifications.title') }}
        </span>
        <ui-input
          v-model="searchNotification"
          :placeholder="$t('invitations.search_by')"
          class="user__notification-input"
          icon="search"
        />
      </div>
      <div class="user__notification-filters">
        <div class="user__notification-unread">
          <span> {{ $t('notifications.show_only_unread') }} </span>
          <el-switch v-model="onlyUnread" class="user__notification-switch" />
        </div>
        <el-popover
          v-model:visible="isShowFilter"
          placement="bottom"
          popper-class="notifications__filters"
          trigger="click"
          width="240px"
          @hide="toggleShowFilter(false)"
          @show="toggleShowFilter(true)"
        >
          <div class="notifications__filters-list">
            <div
              class="notifications__filters-clear"
              @click="resetNotificationFilters"
            >
              {{ $t('clear_all') }}
            </div>
            <div
              v-for="option in notificationFilterList"
              :key="option.id"
              class="notifications__filters-item"
              @click.stop
            >
              <el-checkbox-group v-model="notificationTypeIds">
                <el-checkbox :value="option.value" :label="option.label" />
              </el-checkbox-group>
            </div>
          </div>
          <template #reference>
            <div class="user__notification-filter">
              <span> {{ $t('filters') }} </span>
              <icon-filter />
            </div>
          </template>
        </el-popover>
      </div>
      <div class="user__notification-actions">
        <div
          class="user__notification-action"
          @click="notificationsStore.readAllNotifications"
        >
          <icon-around-check color="#9BC95E" />
          <span> {{ $t('notifications.mark_all') }} </span>
        </div>
        <div
          class="user__notification-action"
          @click="notificationsStore.deleteAllNotifications"
        >
          <icon-trash color="#EC7975" />
          <span> {{ $t('notifications.clear') }} </span>
        </div>
      </div>
      <div ref="notificationListRef" class="user__notification-list">
        <ui-notifications-list-item
          v-for="item in notificationsStore.notificationsList"
          :key="item.notificationHistory.id"
          :item="item"
        />
        <transition appear mode="out-in" name="fade">
          <ui-spinner
            v-if="isLoadingNotifications"
            class="user__notification-spinner"
          />
        </transition>
      </div>
    </div>
    <template #reference>
      <div>
        <el-tooltip
          :content="$t('notifications-widget')"
          placement="bottom"
          effect="customized"
        >
          <div :class="{ 'is-show': isShow }" class="user__notification">
            <IconBadgeLNotification
              v-if="countIsNotRead > 0"
              class="unread__badge"
            />
            <IconNotification />
          </div>
        </el-tooltip>
      </div>
    </template>
  </el-popover>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import IconNotification from '@/components/icon/IconNotification.vue';
import IconBadgeLNotification from '@/components/icon/IconBadgeLNotification.vue';
import UiInput from '@/components/control/UiInput.vue';
import IconFilter from '@/components/icon/IconFilter.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import IconAroundCheck from '@/components/icon/IconAroundCheck.vue';
import UiNotificationsListItem from '@/components/ui/UiNotificationsListItem.vue';

import { useNotificationsStore } from '@/stores/notifications/notifications';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import UiSpinner from '@/components/ui/UiSpinner.vue';

const notificationsStore = useNotificationsStore();
const { isWidgetShow: isShow } = storeToRefs(notificationsStore);
const { t } = useI18n();

const isShowFilter = ref(false);

watch(
  () => isShow.value,
  () => {
    if (isShowFilter.value) {
      isShow.value = true;
    }
  },
);

const notificationListRef = ref<HTMLDivElement>();

const {
  onlyUnread,
  isLoadingNotifications,
  searchNotification,
  notificationTypeIds,
  countIsNotRead,
} = storeToRefs(notificationsStore);

const notificationFilterList = computed(() => {
  return [
    {
      id: 1,
      label: t('notifications.filters.comments'),
      value: 2,
    },
    {
      id: 2,
      label: t('notifications.filters.invitations'),
      value: 6,
    },
    {
      id: 3,
      label: t('notifications.filters.new_task'),
      value: 1,
    },
    {
      id: 4,
      label: t('notifications.filters.status_changes'),
      value: 3,
    },
    {
      id: 5,
      label: t('notifications.filters.expired_task'),
      value: 7,
    },
  ];
});

const resetNotificationFilters = () => (notificationTypeIds.value = []);

const toggleShow = (val: boolean) => {
  if (!isShowFilter.value) {
    isShow.value = val;
  }
};

const toggleShowFilter = (val: boolean) => {
  isShowFilter.value = val;
};

const getNextPageNotifications = async () => {
  notificationListRef.value?.addEventListener('scroll', async () => {
    if (!notificationListRef.value) return;

    let bottomOfWindow =
      notificationListRef.value.scrollTop +
        notificationListRef.value.clientHeight ===
      notificationListRef.value.scrollHeight;

    if (bottomOfWindow) {
      await notificationsStore.getNextPageNotifications();
    }
  });
};

watch(
  () => notificationListRef.value,
  () => notificationListRef.value && getNextPageNotifications(),
);

onMounted(() => {
  notificationsStore.resetNotifications();
  notificationsStore.getNotifications();
  notificationsStore.getCountIsNotRead();
});
</script>
<style lang="scss" scoped>
.user__notification {
  position: relative;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include gray-bg-hover;
  border-radius: 50%;

  & > svg {
    color: #1a2a39;
  }

  &.is-show {
    background-color: var(--color-primary);
    & > svg {
      color: #fcfcfc;
    }
  }

  &-content {
    @include flex-column(2rem);
  }

  &-title {
    @include f20;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-input {
    width: 31.9rem;
  }

  &-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-filter {
    @include f14;
    @include flex-row(1.2rem);
    align-items: center;
    cursor: pointer;
  }

  &-unread {
    @include flex-row(1rem);
    @include f16;
    align-items: center;
  }

  &-switch {
    &.is-checked {
      &:deep(.el-switch__core) {
        background-color: #c8f48d;
        border-color: #c8f48d;
      }
    }
  }

  &-spinner {
    height: 350px;
  }

  &-actions {
    @include flex-row(1.4rem);
    align-items: center;
  }

  &-action {
    @include f14;
    @include flex-row(0.6rem);
    align-items: center;
    padding: 0.5rem 1rem;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: #f2f7f7;
      cursor: pointer;
      border-radius: 0.6rem;
    }
  }

  &-list {
    width: calc(100% + 4.8rem);
    margin-left: -2.4rem;
    border-top: 1px solid #dde3ec;
    max-height: 350px;
    overflow-y: scroll;
  }

  &-item {
    border-bottom: 1px solid #dde3ec;
    border-radius: 0;
  }
}

.unread__badge {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}
.notifications {
  &__filters {
    &-list {
      @include flex-column(2rem);
    }

    &-clear {
      cursor: pointer;
      padding: 0.6rem 0;
      border-bottom: 1px solid #dde3ec;
    }

    &-item {
      &:deep(.el-checkbox-group) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
      }

      &:deep(.el-radio) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }

      &:deep(.el-checkbox__label) {
        overflow: hidden;
        text-wrap: none;
        width: 120px;
        text-overflow: ellipsis;
        padding-left: 0;
      }

      &:deep(.el-checkbox) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>
