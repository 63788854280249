import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "ui-input" }
const _hoisted_2 = ["value", "readonly"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "ui-input__action"
}

import { ref } from 'vue';

type Props = {
  label?: string;
  modelValue?: string;
  readonly?: boolean;
  small?: boolean;
  readonlyNoUnderscore?: boolean;
  pictogram?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UiInputUnderscore',
  props: {
    label: { default: '' },
    modelValue: { default: '' },
    readonly: { type: Boolean },
    small: { type: Boolean },
    readonlyNoUnderscore: { type: Boolean },
    pictogram: { type: Boolean, default: true }
  },
  setup(__props: any, { expose: __expose }) {



const input = ref<HTMLInputElement | null>(null);
__expose({ input });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps(_ctx.$attrs, {
      value: _ctx.modelValue,
      class: [
        'ui-input__control',
        {
          'not-empty': _ctx.modelValue?.length,
          'no-underscore': _ctx.readonlyNoUnderscore,
          small: _ctx.small,
        },
      ],
      readonly: _ctx.readonly,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (
        !_ctx.readonly &&
          _ctx.$emit('update:modelValue', ($event?.target as HTMLInputElement).value)
      )),
      ref_key: "input",
      ref: input
    }), null, 16, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "ui-label",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (input.value?.focus && input.value?.focus(...args)))
        }, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (!_ctx.readonly && !_ctx.$slots.action && _ctx.pictogram)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "ui-input__pictogram",
          src: require(`@images/pictogram/edit.svg`),
          alt: ""
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "action")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})