import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "34",
  height: "34",
  viewBox: "0 0 34 34",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconMicrophone',
  props: {
  color: {
    type: String,
    required: false,
    default: '#F2F7F7',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M17.0002 23.021C13.2885 23.021 10.271 20.0035 10.271 16.2918V8.50016C10.271 4.7885 13.2885 1.771 17.0002 1.771C20.7118 1.771 23.7293 4.7885 23.7293 8.50016V16.2918C23.7293 20.0035 20.7118 23.021 17.0002 23.021ZM17.0002 3.896C14.4643 3.896 12.396 5.96433 12.396 8.50016V16.2918C12.396 18.8277 14.4643 20.896 17.0002 20.896C19.536 20.896 21.6043 18.8277 21.6043 16.2918V8.50016C21.6043 5.96433 19.536 3.896 17.0002 3.896Z",
      fill: __props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M17.0001 27.9792C10.4409 27.9792 5.1001 22.6384 5.1001 16.0792V13.6709C5.1001 13.0901 5.58176 12.6084 6.1626 12.6084C6.74343 12.6084 7.2251 13.0901 7.2251 13.6709V16.0792C7.2251 21.4626 11.6168 25.8542 17.0001 25.8542C22.3834 25.8542 26.7751 21.4626 26.7751 16.0792V13.6709C26.7751 13.0901 27.2568 12.6084 27.8376 12.6084C28.4184 12.6084 28.9001 13.0901 28.9001 13.6709V16.0792C28.9001 22.6384 23.5593 27.9792 17.0001 27.9792Z",
      fill: __props.color
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M18.9693 10.1718C18.856 10.1718 18.7285 10.1576 18.601 10.1151C17.5668 9.73262 16.4335 9.73262 15.3993 10.1151C14.8468 10.3135 14.2377 10.0301 14.0393 9.47762C13.841 8.92512 14.1243 8.31595 14.6768 8.11762C16.1785 7.57928 17.836 7.57928 19.3377 8.11762C19.8902 8.31595 20.1735 8.92512 19.9752 9.47762C19.8052 9.90262 19.3943 10.1718 18.9693 10.1718Z",
      fill: __props.color
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M18.1331 13.1751C18.034 13.1751 17.949 13.161 17.8498 13.1326C17.2831 12.9768 16.7023 12.9768 16.1356 13.1326C15.569 13.2885 14.9881 12.9485 14.8323 12.3818C14.6765 11.8293 15.0164 11.2485 15.5831 11.0926C16.5039 10.8376 17.4956 10.8376 18.4164 11.0926C18.9831 11.2485 19.3231 11.8293 19.1673 12.396C19.0398 12.8635 18.6006 13.1751 18.1331 13.1751Z",
      fill: __props.color
    }, null, 8, _hoisted_5),
    _createElementVNode("path", {
      d: "M17 32.229C16.4192 32.229 15.9375 31.7473 15.9375 31.1665V26.9165C15.9375 26.3357 16.4192 25.854 17 25.854C17.5808 25.854 18.0625 26.3357 18.0625 26.9165V31.1665C18.0625 31.7473 17.5808 32.229 17 32.229Z",
      fill: __props.color
    }, null, 8, _hoisted_6)
  ]))
}
}

})