import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 16 16",
  fill: "none"
}
const _hoisted_2 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconUpload',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M8.00033 10.9999V6.49995M8.00033 6.49995L10.0003 8.49995M8.00033 6.49995L6.00033 8.49995M4.50033 12.9999C3.78662 13.0007 3.09601 12.747 2.55253 12.2844C2.00905 11.8218 1.6483 11.1806 1.53507 10.4759C1.42184 9.77122 1.56355 9.04927 1.93475 8.43968C2.30594 7.83009 2.88231 7.3728 3.56033 7.14995C3.38613 6.2574 3.56617 5.33208 4.06232 4.56996C4.55847 3.80784 5.33178 3.26875 6.21848 3.06685C7.10517 2.86495 8.03564 3.0161 8.81284 3.48829C9.59004 3.96048 10.1529 4.71663 10.3823 5.59662C10.737 5.48127 11.1169 5.46739 11.479 5.55655C11.8412 5.64571 12.1712 5.83435 12.4317 6.10118C12.6923 6.368 12.8731 6.70237 12.9537 7.06652C13.0342 7.43068 13.0114 7.8101 12.8877 8.16195C13.4334 8.37042 13.889 8.76335 14.1754 9.27258C14.4617 9.78181 14.5608 10.3752 14.4554 10.9499C14.35 11.5245 14.0468 12.0441 13.5984 12.4186C13.1499 12.7931 12.5846 12.9987 12.0003 12.9999H4.50033Z",
      stroke: __props.color,
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ]))
}
}

})