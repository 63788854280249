import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

import { computed, inject, onMounted, ref, Ref } from 'vue';
import LayoutHeader from '@/components/layout/app/LayoutHeader.vue';
import LayoutAside from '@/components/layout/app/LayoutAside.vue';
import ModalCreateTask from '@/components/modals/Tasks/ModalCreateTask/ModalCreateTask.vue';
import ModalViewTask from '@/components/modals/Tasks/ModalViewTask/ModalViewTask.vue';
import ModalAcceptWorkspace from '@/components/modals/Workspaces/ModalAcceptWorkspace.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { useSettingsStore } from '@/stores/settings/settings';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import ModalOnboarding from '@/components/modals/ModalOnboarding.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import CreateNoteModal from '@/components/notes/create/CreateNoteModal.vue';
import NoteCardModal from '@/components/notes/card/NoteCardModal.vue';
import { useNoteStore } from '@/stores/notes/useNote';
import NoteTransformSteps from '@/components/notes/transform/NoteTransformSteps.vue';
import { useRoute } from 'vue-router';
import { useWorkspaceStore } from '@/stores/workspaces';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { useSharedTasksStore } from '@/stores/sharingHistory/sharedTasks';
import ModalCreateWorkspace from '@/components/modals/Workspaces/Create/ModalCreateWorkspace.vue';
import CoachActive from '@/components/coaches/CoachActive.vue';
import Mobile from '@/components/layout/mobile.vue';
import ModalAcceptSharedTask from '@/components/modals/Tasks/ModalSharedTask/ModalAcceptSharedTask.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'app',
  setup(__props) {

const route = useRoute();

const newTask = useNewTaskStore();
const settingsStore = useSettingsStore();
const invitationsStore = useInvitationsStore();
const createNoteStore = useCreateNoteStore();
const noteStore = useNoteStore();
const sharedTasks = useSharedTasksStore();
const workspacesStore = useWorkspaceStore();
const { fetchList } = workspacesStore;
const { list, isCreateOpen, isNoMoreWorkspacesModalVisible } =
  storeToRefs(workspacesStore);
const googleAccounts = useGoogleAccounts();

const tariffStore = useTariffStore();
const { isPlansModalVisible } = storeToRefs(tariffStore);

const windowWidth = inject<Ref<number>>('windowWidth', ref(0));

const hasAside = computed(() => windowWidth.value < 1100 || route.meta.noAside);
const padded = computed(() => route.meta.padded);

onMounted(() => {
  Promise.allSettled([
    sharedTasks.fetchRequests(),
    invitationsStore.fetchInternalInvitations(),
    settingsStore.getLocaleUiList(),
    !list.value.length && fetchList(),
    googleAccounts.fetchAccountsWithCalendars(),
  ]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "layout-app",
      style: _normalizeStyle({ background: _ctx.$route.meta.background as string })
    }, [
      _createVNode(LayoutHeader),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(LayoutAside, {
          class: _normalizeClass(['page__aside', { 'has-aside': hasAside.value }])
        }, null, 8, ["class"]),
        _createVNode(ModalCreateTask, {
          "is-open": _unref(newTask).isModalOpen,
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => {
          _unref(newTask).isModalOpen = false;
          _unref(newTask).resetTask();
        })
        }, null, 8, ["is-open"]),
        _createVNode(ModalViewTask),
        _createVNode(ModalAcceptWorkspace),
        _createVNode(ModalAcceptSharedTask),
        _createVNode(ModalOnboarding),
        _createVNode(ModalPlanBuy, {
          onClose: _cache[1] || (_cache[1] = ($event: any) => (isPlansModalVisible.value = false)),
          "is-open": _unref(isPlansModalVisible)
        }, null, 8, ["is-open"]),
        _createVNode(ModalPlanBuy, {
          title: _ctx.$t('card_tariff.no_workspaces_label'),
          onClose: _cache[2] || (_cache[2] = ($event: any) => (isNoMoreWorkspacesModalVisible.value = false)),
          "is-open": _unref(isNoMoreWorkspacesModalVisible)
        }, null, 8, ["title", "is-open"]),
        _createVNode(ModalCreateWorkspace, {
          "is-open": _unref(isCreateOpen),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (isCreateOpen.value = false))
        }, null, 8, ["is-open"]),
        _createVNode(CreateNoteModal, {
          modelValue: _unref(createNoteStore).isStarted,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(createNoteStore).isStarted) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(NoteCardModal, {
          modelValue: _unref(noteStore).isStarted,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(noteStore).isStarted) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(CoachActive),
        (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
          _createVNode(NoteTransformSteps)
        ])),
        _createElementVNode("main", {
          class: _normalizeClass(['page__content', { 'has-aside': hasAside.value, padded: padded.value }])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ])
    ], 4),
    _createVNode(Mobile, { class: "mobile" })
  ], 64))
}
}

})