<template>
  <el-popover
    trigger="click"
    placement="top"
    width="250"
    :disabled="isReadonly"
    v-model:visible="isOpen"
    @hide="isOpen = false"
    @show="isOpen = true"
  >
    <div class="modal-control__dropdown">
      <div
        class="modal-control__dropdown-item"
        :class="{ active: status.title === activeStatus?.title }"
        v-for="status in statusesList"
        :key="status.value"
        @click="updateTaskStatus(status.value)"
        :style="
          status?.disabled && { opacity: '0.5', 'pointer-events': 'none' }
        "
      >
        <img
          width="16"
          :src="
            require(`@images/statuses/${String(
              status.value,
            ).toLowerCase()}.svg`)
          "
          alt="icon"
        />
        {{ status.title }}
        <el-radio
          :model-value="status.title"
          :label="activeStatus?.title || ''"
          class="modal-control__dropdown-radio"
        >
          {{ '' }}
        </el-radio>
      </div>
    </div>
    <template #reference>
      <div
        id="view-status"
        class="modal-control__active-status"
        :class="{ pending: isProcessUpdate }"
        v-skeleton.large="!activeStatus?.value"
      >
        <img
          v-if="activeStatus?.value"
          width="16"
          :src="
            require(`@images/statuses/${String(
              activeStatus.value,
            ).toLowerCase()}.svg`)
          "
          alt="icon"
        />
        {{ activeStatus?.title }}
        <icon-chevron direction="bottom" class="modal-control__chevron" />
      </div>
    </template>
  </el-popover>
</template>
<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { taskStatuses } from '@/consts';
import { storeToRefs } from 'pinia';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { IItemDropdown } from '@/components/ui/UiDropdown/UiDropdown.vue';
import IconChevron from '@/components/icon/IconChevron.vue';
import { useTasksStore } from '@/stores/tasks/tasks';
import { ElNotification } from 'element-plus';

withDefaults(defineProps<{ isReadonly?: boolean }>(), { isReadonly: false });

const { t } = useI18n();
const viewTask = useViewTaskStore();
const tasksStore = useTasksStore();

const { activeTask, state } = storeToRefs(viewTask);

const isProcessUpdate = ref(false);
const isOpen = ref(false);

const activeStatus = computed<IItemDropdown | null>(() => {
  return (
    statusesList.value.find(
      (item) => item.value === activeTask.value.taskStatus,
    ) || null
  );
});

const statusesList = computed(() => {
  return [
    {
      value: taskStatuses.CREATED,
      title: t('view_modal_task.statuses.created'),
      disabled: true,
    },
    {
      value: taskStatuses.APPOINTED,
      title: t('view_modal_task.statuses.appointed'),
    },
    {
      value: taskStatuses.ACCEPTED,
      title: t('view_modal_task.statuses.accepted'),
    },
    {
      value: taskStatuses.DONE,
      title: t('view_modal_task.statuses.done'),
    },
    {
      value: taskStatuses.CONFIRMED,
      title: t('view_modal_task.statuses.confirmed'),
    },
    {
      value: taskStatuses.CLOSED,
      title: t('view_modal_task.statuses.closed'),
    },
    {
      value: taskStatuses.OPEN,
      title: t('view_modal_task.statuses.open'),
    },
  ];
});

const updateTaskStatus = async (status: keyof typeof taskStatuses) => {
  if (status === 'CREATED') return;

  if (!activeTask.value.executor?.id)
    return ElNotification({
      message: t('view_modal_task.select_executor_warning'),
      offset: 80,
    });

  isOpen.value = false;
  isProcessUpdate.value = true;

  if (activeStatus.value) {
    viewTask.setActiveTaskFields('taskStatus', status);
  }

  isProcessUpdate.value = false;

  if (state.value.isShared) return;
  await tasksStore.getTodoCount();
};

onUnmounted(() => (isOpen.value = false));
</script>
<style lang="scss" scoped>
.modal-control {
  &__active-status {
    @include flex-row(0.6rem);
    align-items: center;
    border-radius: 0.8rem;
    border: 0.1rem solid #a3c0f9;
    min-width: 23rem;
    padding: 1.2rem 1.6rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &.pending {
      opacity: 0.5;
      pointer-events: none;
    }

    @media (max-width: 640px) {
      min-width: unset;
      width: 100%;
    }
  }

  &__chevron {
    margin-left: auto;
  }

  &__dropdown {
    flex: 1;
    @include flex-column(1rem);

    &-item {
      @include flex-row(0.6rem);
      align-items: center;
      cursor: pointer;

      &.active {
        color: var(--color-success);
      }
    }

    &-radio {
      margin-left: auto;
    }
  }
}
</style>
