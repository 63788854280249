import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-tariff__header" }
const _hoisted_2 = { class: "card-tariff__description" }
const _hoisted_3 = { class: "card-tariff__body" }
const _hoisted_4 = { class: "card-tariff__price" }
const _hoisted_5 = { class: "card-tariff__price-container" }
const _hoisted_6 = { class: "card-tariff__footer" }

import { ref, computed, onMounted } from 'vue';
import UiButton from '@/components/control/UiButton.vue';
import UiPlanTitle from '@/components/ui/UiPlan/UiPlanTitle.vue';
import UiPrice from '@/components/billing/UiPrice.vue';
import { useI18n } from 'vue-i18n';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { ITariffItemResponse } from '@/types/tariffs';
import { storeToRefs } from 'pinia';
import UiPlanFeatures from '@/components/ui/UiPlan/UiPlanFeatures.vue';
import UiPlanPopular from '@/components/ui/UiPlan/UiPlanPopular.vue';
import { usePrices } from '@/hooks/usePrices';
import { TARIFF_CARD_THEME } from '@/consts/themes';

interface Props {
  item: ITariffItemResponse;
  isActive?: boolean;
  freeMonthsCount?: number;
  popular?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardTariff',
  props: {
    item: {},
    isActive: { type: Boolean },
    freeMonthsCount: { default: 2 },
    popular: { type: Boolean }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "2a68368e": (themeColors.value.background),
  "7b738137": (themeColors.value.border)
}))

const props = __props;

const isAnnually = ref(false);
const isLoadingGenerateLink = ref(false);

const { tariffsView } = storeToRefs(useTariffStore());
const { t } = useI18n();

const { prices } = usePrices(
  computed(() => props.item),
  isAnnually,
);

const isActiveTariff = computed(() => {
  return props.isActive || itemView.value.id === tariffsView?.value?.tariff?.id;
});

const itemView = computed(() =>
  isAnnually.value && props.item?.annualTariff
    ? props.item.annualTariff
    : props.item,
);

const theme = computed(() => {
  return isActiveTariff.value ? 'active' : 'default';
});

const themeColors = computed(() => {
  return TARIFF_CARD_THEME[theme.value];
});

const currentPaymentId = computed(
  () => `${itemView.value?.stripeTariffId}-${itemView.value?.stripePriceId}`,
);

onMounted(() => {
  if (
    tariffsView.value.tariff?.typeRefresh === 'annual' &&
    props.item.annualTariff?.id === tariffsView?.value?.tariff?.id
  )
    isAnnually.value = true;
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card-tariff', { popular: _ctx.popular }])
  }, [
    (_ctx.popular)
      ? (_openBlock(), _createBlock(UiPlanPopular, {
          key: 0,
          theme: theme.value
        }, null, 8, ["theme"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(UiPlanTitle, {
        color: themeColors.value.primaryColor,
        type: itemView.value.title,
        class: "card-tariff__title"
      }, null, 8, ["color", "type"]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)(`card_tariff.services.description.${_ctx.item.title}`)), 1),
      _createElementVNode("div", {
        class: "card-tariff__period",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isAnnually.value = !isAnnually.value))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t(`card_tariff.${isAnnually.value ? 'annually' : 'monthly'}`)) + " ", 1),
        _createVNode(_component_el_switch, {
          class: _normalizeClass(themeColors.value.controls),
          "model-value": isAnnually.value
        }, null, 8, ["class", "model-value"]),
        _createElementVNode("span", {
          class: _normalizeClass(['card-tariff__period-discount', { visible: isAnnually.value }])
        }, _toDisplayString(_ctx.$t('card_tariff.discount', { count: _ctx.freeMonthsCount })), 3)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(['card-tariff__price-container', { hidden: !isAnnually.value }])
        }, [
          _createVNode(UiPrice, {
            period: _ctx.$t('card_tariff.year'),
            price: _unref(prices).initialAnnuallyRawDiscountPrice,
            currency: itemView.value.currency,
            crossed: ""
          }, null, 8, ["period", "price", "currency"])
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(UiPrice, {
            period: _ctx.$t(`card_tariff.${isAnnually.value ? 'year' : 'month'}`),
            price: _unref(prices).currentRawPrice.value,
            currency: itemView.value.currency,
            color: themeColors.value.primaryColor,
            "period-color": themeColors.value?.periodColor
          }, null, 8, ["period", "price", "currency", "color", "period-color"])
        ])
      ]),
      _createVNode(UiPlanFeatures, {
        item: itemView.value,
        theme: theme.value
      }, null, 8, ["item", "theme"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "cardButton", {
        isAnnually: isAnnually.value,
        isActiveTariff: isActiveTariff.value
      }, () => [
        _createVNode(UiButton, {
          class: "card-tariff__button",
          color: themeColors.value.controls,
          "is-loading": isLoadingGenerateLink.value,
          "is-disabled": isActiveTariff.value,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.$emit('change-subscription', _ctx.item.id, isAnnually.value, currentPaymentId.value)
          ))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(isActiveTariff.value
              ? _ctx.$t('card_tariff.current_tariff')
              : `${_ctx.$t('buy')} ${itemView.value.name}`), 1)
          ]),
          _: 1
        }, 8, ["color", "is-loading", "is-disabled"])
      ])
    ])
  ], 2))
}
}

})