import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "note-transform-step-generate__one"
}
const _hoisted_2 = { class: "note-transform-step-generate__animation" }

import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { computed, onBeforeMount } from 'vue';
import NoteTransformGenerateAnimation from '@/components/notes/transform/NoteTransformGenerateAnimation.vue';
import NoteTransformGenerateCard from '@/components/notes/transform/NoteTransformGenerateCard.vue';
import { storeToRefs } from 'pinia';
import IconClose from '@/components/icon/IconClose.vue';
import NoteCardModalFooter from '@/components/notes/card/NoteCardModalFooter.vue';
import NoteCardBody from '@/components/notes/card/NoteCardBody.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformStepGenerate',
  setup(__props) {

const notesTransformStore = useNotesTransformStore();
const { initialNotes, mode } = storeToRefs(notesTransformStore);

const note = computed(() => initialNotes.value[0]);

onBeforeMount(async () => {
  await notesTransformStore.scheduleTasks();
  setTimeout(() => {
    if (notesTransformStore.currentStep === 'generate') {
      if (mode.value === 'one') notesTransformStore.goToNextStep();
      else notesTransformStore.end(false);
    }
  }, 3000);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "note-transform-step-generate",
    style: _normalizeStyle({ width: _unref(mode) === 'many' ? 'auto' : '100%' })
  }, [
    (_unref(mode) === 'many')
      ? (_openBlock(), _createBlock(NoteTransformGenerateAnimation, {
          key: 0,
          height: "180px"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(NoteTransformGenerateCard, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('notes.one')), 1)
            ]),
            actions: _withCtx(() => [
              _createVNode(IconClose)
            ]),
            footer: _withCtx(() => [
              _createVNode(NoteCardModalFooter, { readonly: "" })
            ]),
            default: _withCtx(() => [
              _createVNode(NoteCardBody, {
                title: note.value?.title ?? '',
                description: note.value?.description ?? '',
                readonly: ""
              }, null, 8, ["title", "description"])
            ]),
            _: 1
          }),
          _createVNode(NoteTransformGenerateCard, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('notes.transform.generate.created')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('notes.transform.generate.moving')), 1),
                _createVNode(NoteTransformGenerateAnimation, { height: "180px" })
              ])
            ]),
            _: 1
          })
        ]))
  ], 4))
}
}

})