import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "21",
  height: "20",
  viewBox: "0 0 21 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconAdd',
  props: {
  color: {
    type: String,
    required: false,
    default: 'currentColor',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M17.9332 9.22589L11.022 9.22589L11.022 2.31467C11.022 1.95684 10.7319 1.66675 10.3741 1.66675C10.0162 1.66675 9.72614 1.95684 9.72614 2.31467V9.22589H2.81492C2.45708 9.22589 2.16699 9.51598 2.16699 9.87382C2.16699 10.2317 2.45708 10.5217 2.81492 10.5217L9.72614 10.5217L9.72614 17.433C9.72614 17.7908 10.0162 18.0809 10.3741 18.0809C10.7319 18.0809 11.022 17.7908 11.022 17.433V10.5217H17.9332C18.291 10.5217 18.5811 10.2317 18.5811 9.87382C18.5811 9.51598 18.291 9.22589 17.9332 9.22589Z",
      fill: __props.color
    }, null, 8, _hoisted_2)
  ]))
}
}

})