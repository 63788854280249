import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "user__notification-content" }
const _hoisted_2 = { class: "user__notification-header" }
const _hoisted_3 = { class: "user__notification-title" }
const _hoisted_4 = { class: "user__notification-filters" }
const _hoisted_5 = { class: "user__notification-unread" }
const _hoisted_6 = { class: "notifications__filters-list" }
const _hoisted_7 = { class: "user__notification-filter" }
const _hoisted_8 = { class: "user__notification-actions" }

import { computed, onMounted, ref, watch } from 'vue';
import IconNotification from '@/components/icon/IconNotification.vue';
import IconBadgeLNotification from '@/components/icon/IconBadgeLNotification.vue';
import UiInput from '@/components/control/UiInput.vue';
import IconFilter from '@/components/icon/IconFilter.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import IconAroundCheck from '@/components/icon/IconAroundCheck.vue';
import UiNotificationsListItem from '@/components/ui/UiNotificationsListItem.vue';

import { useNotificationsStore } from '@/stores/notifications/notifications';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import UiSpinner from '@/components/ui/UiSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetNotification',
  setup(__props) {

const notificationsStore = useNotificationsStore();
const { isWidgetShow: isShow } = storeToRefs(notificationsStore);
const { t } = useI18n();

const isShowFilter = ref(false);

watch(
  () => isShow.value,
  () => {
    if (isShowFilter.value) {
      isShow.value = true;
    }
  },
);

const notificationListRef = ref<HTMLDivElement>();

const {
  onlyUnread,
  isLoadingNotifications,
  searchNotification,
  notificationTypeIds,
  countIsNotRead,
} = storeToRefs(notificationsStore);

const notificationFilterList = computed(() => {
  return [
    {
      id: 1,
      label: t('notifications.filters.comments'),
      value: 2,
    },
    {
      id: 2,
      label: t('notifications.filters.invitations'),
      value: 6,
    },
    {
      id: 3,
      label: t('notifications.filters.new_task'),
      value: 1,
    },
    {
      id: 4,
      label: t('notifications.filters.status_changes'),
      value: 3,
    },
    {
      id: 5,
      label: t('notifications.filters.expired_task'),
      value: 7,
    },
  ];
});

const resetNotificationFilters = () => (notificationTypeIds.value = []);

const toggleShow = (val: boolean) => {
  if (!isShowFilter.value) {
    isShow.value = val;
  }
};

const toggleShowFilter = (val: boolean) => {
  isShowFilter.value = val;
};

const getNextPageNotifications = async () => {
  notificationListRef.value?.addEventListener('scroll', async () => {
    if (!notificationListRef.value) return;

    let bottomOfWindow =
      notificationListRef.value.scrollTop +
        notificationListRef.value.clientHeight ===
      notificationListRef.value.scrollHeight;

    if (bottomOfWindow) {
      await notificationsStore.getNextPageNotifications();
    }
  });
};

watch(
  () => notificationListRef.value,
  () => notificationListRef.value && getNextPageNotifications(),
);

onMounted(() => {
  notificationsStore.resetNotifications();
  notificationsStore.getNotifications();
  notificationsStore.getCountIsNotRead();
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    visible: _unref(isShow),
    "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => (_isRef(isShow) ? (isShow).value = $event : null)),
    placement: "bottom",
    "popper-class": "notifications",
    trigger: "click",
    width: "557",
    onHide: _cache[10] || (_cache[10] = ($event: any) => (toggleShow(false))),
    onShow: _cache[11] || (_cache[11] = ($event: any) => (toggleShow(true)))
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_tooltip, {
          content: _ctx.$t('notifications-widget'),
          placement: "bottom",
          effect: "customized"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([{ 'is-show': _unref(isShow) }, "user__notification"])
            }, [
              (_unref(countIsNotRead) > 0)
                ? (_openBlock(), _createBlock(IconBadgeLNotification, {
                    key: 0,
                    class: "unread__badge"
                  }))
                : _createCommentVNode("", true),
              _createVNode(IconNotification)
            ], 2)
          ]),
          _: 1
        }, 8, ["content"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('notifications.title')), 1),
          _createVNode(UiInput, {
            modelValue: _unref(searchNotification),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchNotification) ? (searchNotification).value = $event : null)),
            placeholder: _ctx.$t('invitations.search_by'),
            class: "user__notification-input",
            icon: "search"
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('notifications.show_only_unread')), 1),
            _createVNode(_component_el_switch, {
              modelValue: _unref(onlyUnread),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(onlyUnread) ? (onlyUnread).value = $event : null)),
              class: "user__notification-switch"
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_el_popover, {
            visible: isShowFilter.value,
            "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((isShowFilter).value = $event)),
            placement: "bottom",
            "popper-class": "notifications__filters",
            trigger: "click",
            width: "240px",
            onHide: _cache[5] || (_cache[5] = ($event: any) => (toggleShowFilter(false))),
            onShow: _cache[6] || (_cache[6] = ($event: any) => (toggleShowFilter(true)))
          }, {
            reference: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('filters')), 1),
                _createVNode(IconFilter)
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "notifications__filters-clear",
                  onClick: resetNotificationFilters
                }, _toDisplayString(_ctx.$t('clear_all')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notificationFilterList.value, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: option.id,
                    class: "notifications__filters-item",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                  }, [
                    _createVNode(_component_el_checkbox_group, {
                      modelValue: _unref(notificationTypeIds),
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(notificationTypeIds) ? (notificationTypeIds).value = $event : null))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_checkbox, {
                          value: option.value,
                          label: option.label
                        }, null, 8, ["value", "label"])
                      ]),
                      _: 2
                    }, 1032, ["modelValue"])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["visible"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "user__notification-action",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_unref(notificationsStore).readAllNotifications && _unref(notificationsStore).readAllNotifications(...args)))
          }, [
            _createVNode(IconAroundCheck, { color: "#9BC95E" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('notifications.mark_all')), 1)
          ]),
          _createElementVNode("div", {
            class: "user__notification-action",
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_unref(notificationsStore).deleteAllNotifications && _unref(notificationsStore).deleteAllNotifications(...args)))
          }, [
            _createVNode(IconTrash, { color: "#EC7975" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('notifications.clear')), 1)
          ])
        ]),
        _createElementVNode("div", {
          ref_key: "notificationListRef",
          ref: notificationListRef,
          class: "user__notification-list"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationsStore).notificationsList, (item) => {
            return (_openBlock(), _createBlock(UiNotificationsListItem, {
              key: item.notificationHistory.id,
              item: item
            }, null, 8, ["item"]))
          }), 128)),
          _createVNode(_Transition, {
            appear: "",
            mode: "out-in",
            name: "fade"
          }, {
            default: _withCtx(() => [
              (_unref(isLoadingNotifications))
                ? (_openBlock(), _createBlock(UiSpinner, {
                    key: 0,
                    class: "user__notification-spinner"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ], 512)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})