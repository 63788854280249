import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "note-transform-calendar-task__title" }
const _hoisted_4 = {
  key: 1,
  class: "note-transform-calendar-task__description"
}

import { Task } from '@/types/tasks';
import { isAllDayDate } from '@/utils/time';
import { format } from 'date-fns';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformCalendarTask',
  props: {
    data: {},
    color: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "note-transform-calendar-task",
    style: _normalizeStyle({ '--color': _ctx.color || 'gray' })
  }, [
    (!_unref(isAllDayDate)(_ctx.data.deadline))
      ? (_openBlock(), _createElementBlock("small", _hoisted_1, [
          _createTextVNode(_toDisplayString(_unref(format)(new Date(_ctx.data.deadline), 'hh:mm')) + " ", 1),
          (_ctx.data.deadlineLast)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " — " + _toDisplayString(_unref(format)(new Date(_ctx.data.deadlineLast), 'hh:mm')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.data.name), 1),
    (_ctx.data.taskContent)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.data.taskContent), 1))
      : _createCommentVNode("", true)
  ], 4))
}
}

})