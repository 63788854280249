import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition } from "vue"
import _imports_0 from '@images/earth.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ui-dropdown-lang" }
const _hoisted_3 = { class: "ui-dropdown-lang__list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "ui-dropdown-lang__reference" }
const _hoisted_6 = { class: "ui-dropdown-lang__label" }

import { ref, computed, onMounted } from 'vue';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import { LangUI } from '@/types/lang';
import IconChevron from '@/components/icon/IconChevron.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownLang',
  setup(__props) {

const settingsStore = useSettingsStore();
const { localeUiList, lang } = storeToRefs(settingsStore);

const isOpen = ref(false);

const availableLangList = computed(() => {
  return localeUiList.value?.filter((item: LangUI) =>
    ['RU', 'EN'].includes(item.abbreviation),
  );
});

const setLang = async (item: LangUI) => {
  await settingsStore.saveUiLocale(item.abbreviation);
  await settingsStore.getUserData();
  localStorage.lang = item.abbreviation.toLowerCase();
};

onMounted(() => {
  if (!lang.value) {
    settingsStore.getUserData();
    settingsStore.getLocaleUiList();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "blur",
    appear: ""
  }, {
    default: _withCtx(() => [
      (_unref(lang))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_popover, {
              placement: "bottom",
              width: "fit-content",
              trigger: "click",
              visible: isOpen.value,
              "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event))
            }, {
              reference: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(settingsStore)?.lang), 1),
                  _createVNode(IconChevron, {
                    direction: isOpen.value ? 'top' : 'bottom'
                  }, null, 8, ["direction"])
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableLangList.value, (lang) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass([
                'ui-dropdown-lang__item',
                { current: _unref(settingsStore).lang === lang?.abbreviation },
              ]),
                        key: lang.id,
                        onClick: ($event: any) => {
                setLang(lang);
                isOpen.value = false;
              }
                      }, [
                        _createTextVNode(_toDisplayString(lang.name) + " ", 1),
                        (_unref(settingsStore).lang === lang?.abbreviation)
                          ? (_openBlock(), _createBlock(_component_el_radio, {
                              key: 0,
                              size: "small"
                            }))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_4))
                    }), 128))
                  ])
                ])
              ]),
              _: 1
            }, 8, ["visible"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})