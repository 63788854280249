<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1875 8C6.1875 8.08288 6.15458 8.16237 6.09597 8.22097C6.03737 8.27958 5.95788 8.3125 5.875 8.3125C5.79212 8.3125 5.71263 8.27958 5.65403 8.22097C5.59542 8.16237 5.5625 8.08288 5.5625 8C5.5625 7.91712 5.59542 7.83763 5.65403 7.77903C5.71263 7.72042 5.79212 7.6875 5.875 7.6875C5.95788 7.6875 6.03737 7.72042 6.09597 7.77903C6.15458 7.83763 6.1875 7.91712 6.1875 8ZM6.1875 8H5.875M9.3125 8C9.3125 8.08288 9.27958 8.16237 9.22097 8.22097C9.16237 8.27958 9.08288 8.3125 9 8.3125C8.91712 8.3125 8.83763 8.27958 8.77903 8.22097C8.72042 8.16237 8.6875 8.08288 8.6875 8C8.6875 7.91712 8.72042 7.83763 8.77903 7.77903C8.83763 7.72042 8.91712 7.6875 9 7.6875C9.08288 7.6875 9.16237 7.72042 9.22097 7.77903C9.27958 7.83763 9.3125 7.91712 9.3125 8ZM9.3125 8H9M12.4375 8C12.4375 8.08288 12.4046 8.16237 12.346 8.22097C12.2874 8.27958 12.2079 8.3125 12.125 8.3125C12.0421 8.3125 11.9626 8.27958 11.904 8.22097C11.8454 8.16237 11.8125 8.08288 11.8125 8C11.8125 7.91712 11.8454 7.83763 11.904 7.77903C11.9626 7.72042 12.0421 7.6875 12.125 7.6875C12.2079 7.6875 12.2874 7.72042 12.346 7.77903C12.4046 7.83763 12.4375 7.91712 12.4375 8ZM12.4375 8H12.125M16.5 8C16.5 11.7967 13.1417 14.875 9 14.875C8.28099 14.8759 7.56503 14.7814 6.87083 14.5942C5.8923 15.2824 4.6986 15.5951 3.50833 15.475C3.376 15.4622 3.24422 15.4442 3.11333 15.4208C3.52406 14.9368 3.80456 14.356 3.92833 13.7333C4.00333 13.3525 3.8175 12.9825 3.53917 12.7117C2.275 11.4817 1.5 9.82417 1.5 8C1.5 4.20333 4.85833 1.125 9 1.125C13.1417 1.125 16.5 4.20333 16.5 8Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
