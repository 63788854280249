import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-create__form-row" }
const _hoisted_2 = { class: "modal-create__form-col modal-create__form-autocomplete" }
const _hoisted_3 = { class: "modal-create__form-col" }
const _hoisted_4 = { class: "modal-create__form-row" }

import { priorityOptions } from '@/consts';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import UiUploaderFiles from '@/components/ui/UiUploaderFiles.vue';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';
import { useNoteToTaskStore } from '@/stores/notes/useNoteToTask';
import { useSubordinatesList } from '@/hooks/useSubordinatesList';
import { computed, onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { SubordinateViewType } from '@/utils/subordinates';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformTaskBody',
  setup(__props) {

const { subordinates, fetchSubordinates, getSubordinate, searchFilter } =
  useSubordinatesList();

const noteToTaskStore = useNoteToTaskStore();
const { state, dateView, taskPriority, files } = storeToRefs(noteToTaskStore);

const currentExecutor = computed(() =>
  state.value.executorId ? getSubordinate.value(state.value.executorId) : null,
);

const isOpenAutoComplete = ref(false);

const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

const selectExecutor = (user: SubordinateViewType) => {
  state.value.executorId = user.id;
  isOpenAutoComplete.value = false;
};

onBeforeMount(() => {
  fetchSubordinates();
});

return (_ctx: any,_cache: any) => {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("form", {
    class: "modal-create__form",
    onSubmit: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_unref(noteToTaskStore).end()), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_ui_input, {
          label: _ctx.$t('modal_task.to'),
          class: "modal-create__form-input",
          "model-value": currentExecutor.value?.fullName,
          icon: currentExecutor.value ? 'user-change' : 'contact',
          "icon-align": "right",
          readonly: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpenAutoComplete.value = true))
        }, null, 8, ["label", "model-value", "icon"]),
        (_unref(subordinates)?.length)
          ? (_openBlock(), _createBlock(UiAutocompleteUsers, {
              key: 0,
              items: _unref(subordinates),
              "is-open": isOpenAutoComplete.value,
              search: _unref(searchFilter),
              "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchFilter) ? (searchFilter).value = $event : null)),
              onSelectItem: selectExecutor
            }, null, 8, ["items", "is-open", "search"]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_click_outside, hideAutocomplete]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(PopupTaskDatePicker, {
          "model-value": _unref(state).deadline,
          endTime: _unref(state).deadlineLast || undefined,
          reminder: _unref(state).notifyTime || undefined,
          "date-view": _unref(dateView),
          executor: currentExecutor.value,
          disabled: !_unref(state).executorId,
          "onUpdate:time": _cache[2] || (_cache[2] = ($event: any) => (_unref(noteToTaskStore).setTime($event))),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_unref(noteToTaskStore).setDate($event)))
        }, null, 8, ["model-value", "endTime", "reminder", "date-view", "executor", "disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ui_input, {
        class: _normalizeClass(['modal-create__wide-input', { error: _unref(state).name.length > 75 }]),
        modelValue: _unref(state).name,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).name) = $event)),
        label: _ctx.$t('modal_task.task_name_placeholder')
      }, null, 8, ["class", "modelValue", "label"]),
      _createVNode(UiDropdownPriority, {
        class: "priority-dropdown modal-create__dropdown",
        modelValue: _unref(taskPriority),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(taskPriority) ? (taskPriority).value = $event : null)),
        items: _unref(priorityOptions)
      }, null, 8, ["modelValue", "items"])
    ]),
    _createVNode(_component_ui_input, {
      highlighted: !_unref(state).taskContent.length,
      class: _normalizeClass([
        'modal-create__area',
        { error: _unref(state).taskContent.length > 10_000 },
      ]),
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_unref(state).taskContent = $event)),
      "model-value": _unref(state).taskContent,
      type: "textarea",
      placeholder: _ctx.$t('modal_task.description_placeholder'),
      rows: "8",
      style: {"resize":"vertical"},
      ref: "contentInput"
    }, null, 8, ["highlighted", "class", "model-value", "placeholder"]),
    _createVNode(UiUploaderFiles, {
      modelValue: _unref(files),
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(files) ? (files).value = $event : null))
    }, null, 8, ["modelValue"]),
    _cache[9] || (_cache[9] = _createElementVNode("input", {
      type: "submit",
      hidden: ""
    }, null, -1))
  ], 32))
}
}

})