import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "workspaces-create-title"
}
const _hoisted_2 = { class: "workspaces-create-title__header" }
const _hoisted_3 = { class: "workspaces-create-title__footer" }

import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useWorkspaceStore } from '@/stores/workspaces';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTariffStore } from '@/stores/tariffs/tariffs';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCreateWorkspaceTitle',
  emits: ['create', 'setStep'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const { t } = useI18n();

const billingStore = useTariffStore();

const workspacesStore = useWorkspaceStore();
const { fetchList, create } = workspacesStore;

const pending = ref(false);
const newWorkspaceName = ref('');

const isErrorState = computed(() => newWorkspaceName.value.length > 30);

const onCreateWorkspace = async () => {
  try {
    pending.value = true;
    const createdWorkspace = (await create(newWorkspaceName.value)).data;
    emits('create', createdWorkspace);
    emits('setStep', 2);
    await fetchList();
    await billingStore.fetchUserTariff();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_ui_input_underscore = _resolveComponent("ui-input-underscore")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "blur",
    appear: "",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (pending.value)
        ? (_openBlock(), _createBlock(UiSpinner, {
            key: 0,
            style: {"height":"17rem"}
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('workspaces.create.title')), 1),
            _createVNode(_component_ui_input_underscore, {
              class: _normalizeClass(['workspaces-create-title__input', { error: isErrorState.value }]),
              label: "Workspace name",
              modelValue: newWorkspaceName.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newWorkspaceName).value = $event))
            }, null, 8, ["class", "modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ui_button, {
                onClick: onCreateWorkspace,
                "is-disabled": pending.value || !newWorkspaceName.value || isErrorState.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('workspaces.create.create_btn')), 1)
                ]),
                _: 1
              }, 8, ["is-disabled"])
            ])
          ]))
    ]),
    _: 1
  }))
}
}

})