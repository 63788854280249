<template>
  <div class="modal-create-footer footer">
    <div class="footer__lang">
      <!--        <div v-if="isSwitchTranslation">-->
      <!--          <ui-dropdown-autocomplete-->
      <!--            v-model="currentData.languageFrom"-->
      <!--            :items="languagesList"-->
      <!--            @select-item="(value) => (currentData.languageFrom = value)"-->
      <!--          />-->
      <!--          <ui-dropdown-autocomplete-->
      <!--            v-model="currentData.languageTo"-->
      <!--            :items="languagesList"-->
      <!--            @select-item="(value) => (currentData.languageTo = value)"-->
      <!--          />-->
      <!--        </div>-->

      <LangRecognitionSelector />
    </div>
    <div class="footer__record">
      <ui-audio-recorder
        id="task-record"
        :audio="audio"
        @onStopRecord="onStopRecord"
        @onStartRecord="onStartRecord"
      />
    </div>
    <div class="footer__control">
      <ui-button
        :isDisabled="isCreateBtnDisabled"
        :isLoading="isDisabledSubmit"
        class="footer__control-submit"
        @click="$emit('click-submit')"
      >
        {{ $t('modal_task.create_task') }}
        <icon-check color="#ffffff" />
      </ui-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useNewTaskStore } from '@/stores/newTask';
import { storeToRefs } from 'pinia';
import { computed, ref, toRefs, watch } from 'vue';
import LangRecognitionSelector from '@/components/modals/Tasks/ModalCreateTask/LangRecognitionSelector.vue';
import IconCheck from '@/components/icon/IconCheck.vue';
import UiAudioRecorder from '@/components/ui/UiAudioRecorder.vue';
import { sockets } from '@/api/services';

type Props = {
  isDisabledSubmit: boolean;
  isOpenModal: boolean;
};
defineProps<Props>();
defineEmits(['click-submit']);

const newTask = useNewTaskStore();
const { parseDescription } = newTask;

const onStartRecord = async () => {
  sockets.speech.emitter
    .subscribe('AddPartialTranscript', (speech: string) => {
      newTask.audio.runtimeSpeech = speech;
    })
    .subscribe('AddTranscript', (speech: string) => {
      newTask.audio.runtimeSpeech = '';
      newTask.currentData.taskContent += speech;
    })
    .subscribe('onClose', () => (newTask.audio.runtimeSpeech = ''));
};

const onStopRecord = async () => {
  sockets.speech.emitter.reset();
  await parseDescription();
};

const isCreateBtnDisabled = computed(() => {
  const { taskPriority, taskContent, executor, name, deadline } =
    currentData.value;

  return (
    !(
      deadline &&
      taskPriority.value &&
      taskContent &&
      taskContent.length <= 10_000 &&
      executor?.id &&
      name &&
      name.length <= 75
    ) || isRecording.value
  );
});

const { currentData, audio } = storeToRefs(newTask);
const { isRecording, recordFile } = toRefs(audio.value);

watch(recordFile, (val) => (currentData.value.audioFile = val));
</script>

<style lang="scss" scoped>
.footer {
  @include flex-row;
  min-height: 11rem;

  &__lang {
    margin: auto;
    @include flex-column(1rem);
  }

  & > * {
    flex: 1;
  }

  &__record {
    border-left: 0.1rem solid #dde3ec;
    border-right: 0.1rem solid #dde3ec;
    position: relative;
    bottom: -1rem;
  }

  &__control {
    @include flex-column(2rem);
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    &-submit {
      @include flex-row(1rem);
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      @include f12;
    }
  }
}
</style>
