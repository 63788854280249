import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "notification__info" }
const _hoisted_2 = {
  key: 0,
  class: "modal__title"
}
const _hoisted_3 = { class: "modal__icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "notification__expired-text"
}
const _hoisted_7 = { class: "notification__message" }
const _hoisted_8 = { class: "notification__link" }
const _hoisted_9 = {
  key: 2,
  class: "notification__description"
}
const _hoisted_10 = { class: "notification__date" }
const _hoisted_11 = {
  key: 0,
  class: "notification__count"
}
const _hoisted_12 = {
  key: 1,
  class: "notification__count"
}
const _hoisted_13 = { class: "notification__date-date" }

import { computed, ref } from 'vue';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import IconMarker from '@/components/icon/IconMarker.vue';
import { format } from 'date-fns';
import { NotificationHistory } from '@/types/common';
import { TITLES_MAP } from '@/consts';
import IconBadge from '@/components/icon/IconBadge.vue';
import { useNotificationsStore } from '@/stores/notifications/notifications';
import { useRouter } from 'vue-router';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { storeToRefs } from 'pinia';
import ModalUserView from '@/components/modals/UserView/ModalUserView.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiNotificationsListItem',
  props: {
    item: {}
  },
  setup(__props: any) {

const router = useRouter();

const isUserProfileOpen = ref(false);

const notificationsStore = useNotificationsStore();
const subordinatesStore = useSubordinatesStore();
const { subordinatesView } = storeToRefs(subordinatesStore);

const usersStore = useSubordinatesStore();
const { fetch: usersFetch } = usersStore;

const props = __props;

const formattedDate = computed(() => {
  if (props.item?.notificationHistory.createdAt) {
    return format(
      new Date(props.item?.notificationHistory.createdAt),
      'dd.MM · hh:mm',
    );
  }

  return '';
});

const currentTypeNotification = computed(() => {
  const isUser = !!props.item?.notificationHistory.userIdInviteConfirm;
  const isMessage = props.item?.notificationHistory.notificationType.id === 2;
  const isTaskExpired =
    props.item?.notificationHistory.notificationType.id === 7;
  return {
    isUser,
    isMessage,
    isTaskExpired,
    isTask: !(isUser || isMessage || isTaskExpired),
  };
});

const userTypeNotificationData = computed(() => {
  if (!currentTypeNotification.value.isUser) return;
  return subordinatesView?.value?.content?.find(
    (s) => s?.id === props.item.notificationHistory.userIdInviteConfirm,
  );
});

const onCloseUserProfile = async () => {
  await notificationsStore.readNotifications(
    props.item?.notificationHistory.id,
    props.item?.notificationHistory.taskId,
  );

  isUserProfileOpen.value = false;
  await usersFetch();
};

const readNotification = async () => {
  if (!props.item?.notificationHistory.id) return;

  if (currentTypeNotification.value.isMessage) {
    await router.replace({ query: { showComments: 'true' } });
  }

  if (currentTypeNotification.value.isUser) {
    return (isUserProfileOpen.value = true);
  }

  await notificationsStore.readNotifications(
    props.item?.notificationHistory.id,
    props.item?.notificationHistory.taskId,
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      !currentTypeNotification.value.isUser ||
      (currentTypeNotification.value.isUser && userTypeNotificationData.value)
    )
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
      'notification__row',
      {
        'notification__row--new-user': currentTypeNotification.value.isUser,
      },
    ]),
          onClick: readNotification
        }, [
          (!_ctx.item?.notificationHistory.isRead)
            ? (_openBlock(), _createBlock(IconMarker, {
                key: 0,
                class: "notification__marker"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            (currentTypeNotification.value.isTask)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: 
              require(`@images/statuses/${
                _ctx.item?.notificationHistory?.taskStatus?.toLowerCase() ||
                'created'
              }.svg`)
            ,
                      class: "modal-view__icon"
                    }, null, 8, _hoisted_4)
                  ]),
                  (_ctx.item?.notificationHistory?.taskStatus)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(TITLES_MAP)[_ctx.item.notificationHistory.taskStatus]), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (currentTypeNotification.value.isTaskExpired)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('notifications.expired_text')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              (currentTypeNotification.value.isUser)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(UiAvatar, {
                      size: "3rem",
                      "avatar-link": userTypeNotificationData.value?.avatar?.uuidLink,
                      "fallback-name": userTypeNotificationData.value?.fullName
                    }, null, 8, ["avatar-link", "fallback-name"]),
                    _createTextVNode(" " + _toDisplayString(userTypeNotificationData.value?.fullName) + " ", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('notifications.joined')), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            (!currentTypeNotification.value.isUser)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.item.notificationHistory.message), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.item?.count && currentTypeNotification.value.isMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(UiColorfilledText, {
                    color: "#FCFCFC",
                    style: {"justify-content":"flex-end"},
                    type: "NOTIFICATION"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.item.count), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (currentTypeNotification.value.isTaskExpired)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(IconBadge, { color: "#EC7975" })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_13, _toDisplayString(formattedDate.value), 1)
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(ModalUserView, {
      "is-open": !!(userTypeNotificationData.value && isUserProfileOpen.value),
      onOnClose: onCloseUserProfile,
      user: userTypeNotificationData.value
    }, null, 8, ["is-open", "user"])
  ], 64))
}
}

})