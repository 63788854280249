import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "ui-filter" }
const _hoisted_2 = { class: "ui-filter__header" }
const _hoisted_3 = { class: "ui-filter-control" }
const _hoisted_4 = { class: "ui-filter-control__title" }
const _hoisted_5 = { class: "ui-filter-control__count" }
const _hoisted_6 = { class: "ui-filter-dropdown ui-filter-dropdown--scroll" }
const _hoisted_7 = { class: "text-ellipsis" }

import { computed, inject } from 'vue';
import { TableFilter } from '@/types/common';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTableFiltersUsersList',
  props: {
    icon: {},
    options: {},
    label: {},
    filterField: {},
    component: {}
  },
  setup(__props: any) {

const { t, te } = useI18n();

const getLabel = (label?: string) => (label && te(label) ? t(label) : label);

const props = __props;
const isAllUsers = computed({
  get() {
    return filtersState?.[props.filterField]?.length === props.options?.length;
  },
  set() {
    if (isAllUsers.value) {
      resetFilters();
    } else {
      setFilter &&
        setFilter(
          props.filterField,
          props.options.map((o) => o.value),
        );
    }
  },
});

const filtersState = inject<Record<string, unknown[]>>('filtersState');
const setFilter = inject<(...args: unknown[]) => void>('setFilter');

const resetFilters = () => {
  const filter = filtersState?.[props.filterField];
  setFilter && setFilter(props.filterField, filter instanceof Array ? [] : '');
};

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(getLabel(_ctx.label)), 1),
      _createElementVNode("div", {
        class: "ui-filter__clear",
        onClick: resetFilters
      }, _toDisplayString(_ctx.$t('tasks.filters.clear')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_checkbox, {
        modelValue: isAllUsers.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isAllUsers).value = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('all_users')), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.options?.length), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ui-filter-dropdown__row",
          key: option?.value || option?.label
        }, [
          _createVNode(_component_el_checkbox_group, {
            "model-value": _unref(filtersState)?.[_ctx.filterField],
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_unref(setFilter)?.(_ctx.filterField, $event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                "model-value": option.value,
                value: option.value
              }, {
                default: _withCtx(() => [
                  _createVNode(UiAvatar, {
                    "avatar-link": option?.avatarUrl,
                    size: "3rem",
                    "fallback-name": getLabel(option.label)
                  }, null, 8, ["avatar-link", "fallback-name"]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(getLabel(option.label) || option.value), 1)
                ]),
                _: 2
              }, 1032, ["model-value", "value"])
            ]),
            _: 2
          }, 1032, ["model-value"])
        ]))
      }), 128))
    ])
  ]))
}
}

})