import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@images/hide-pass.svg'


const _hoisted_1 = { class: "ui-input" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "ui-input__action"
}

import { computed, ref, toRefs, useAttrs } from 'vue';

type Props = {
  label?: string;
  icon?: 'search' | 'calendar' | 'contact' | 'send' | 'user-change' | 'smart';
  iconAlign?: 'right' | 'left';
  modelValue?: string;
  debounce?: number | null;
  debouncedInput?: (v: string, meta: unknown) => void;
  debounceMeta?: unknown;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UiInput',
  props: {
    label: { default: '' },
    icon: {},
    iconAlign: { default: 'left' },
    modelValue: { default: '' },
    debounce: { default: null },
    debouncedInput: {},
    debounceMeta: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const input = ref<null | HTMLInputElement>(null);
__expose({ input });

const props = __props;

const { icon, iconAlign } = toRefs(props);

const attrs = useAttrs();
const type = ref(attrs.type);

const leftAlign = computed(() =>
  icon?.value && iconAlign.value === 'left' ? '3.6rem' : '1.2rem',
);

let debounceHandler: number | undefined = undefined;

const handleInput = (e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  emits('update:modelValue', value);

  if (!props.debounce) return;
  clearTimeout(debounceHandler);
  const meta = props.debounceMeta;
  debounceHandler = setTimeout(
    () => props.debouncedInput && props.debouncedInput(value, meta),
    props.debounce,
  );
};

const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(type.value === 'textarea' ? 'textarea' : 'input'), _mergeProps(_ctx.$attrs, {
      type: type.value,
      value: _ctx.modelValue,
      class: ['ui-input__control', { 'not-empty': _ctx.modelValue?.length }],
      style: {
        'max-height': type.value === 'textarea' && 'unset',
        'padding-left': leftAlign.value,
        'padding-right': _ctx.$slots.action && '4rem',
      },
      onInput: handleInput,
      ref_key: "input",
      ref: input
    }), null, 16, ["type", "value", "class", "style"])),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "ui-label",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (input.value?.focus && input.value?.focus(...args))),
          style: _normalizeStyle({ left: leftAlign.value })
        }, _toDisplayString(_ctx.label), 5))
      : _createCommentVNode("", true),
    (_ctx.$attrs.type === 'password')
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "hide-pass",
          src: _imports_0,
          style: _normalizeStyle({ right: _unref(iconAlign) === 'right' && _unref(icon) ? '3.6rem' : '1.2rem' }),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (type.value = type.value === 'password' ? 'text' : 'password')),
          alt: ""
        }, null, 4))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_unref(icon))
          ? (_openBlock(), _createElementBlock("img", {
              key: _unref(icon),
              class: "ui-input__pictogram",
              style: _normalizeStyle({ [_unref(iconAlign)]: '1.2rem' }),
              src: require(`@images/pictogram/${_unref(icon)}.svg`),
              alt: ""
            }, null, 12, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.$slots.action)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "action")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})