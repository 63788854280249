import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { CURRENT_TIME_ZONE, TASK_TIME_FORMAT } from '@/consts/timeFormats';
import { Task } from '@/types/tasks';

export const getTimeByParts = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return [hours, minutes, seconds];
};

const stringDateToUTCDefaults: Required<StringDateToUTCOptions> = {
  datePattern: TASK_TIME_FORMAT,
  timeZone: 'UTC',
  initialDateUTC0: false,
};

type StringDateToUTCOptions = {
  datePattern?: string;
  timeZone?: string;
  initialDateUTC0?: boolean;
};
export const stringDateToUTC = (
  date?: string,
  options?: StringDateToUTCOptions,
) => {
  if (!date) return undefined;
  const { timeZone, datePattern, initialDateUTC0 } = Object.assign(
    { ...stringDateToUTCDefaults },
    options,
  );
  if (initialDateUTC0) date += 'Z';
  return format(utcToZonedTime(date, timeZone), datePattern);
};

export const isAllDayDate = (date?: string) =>
  date && date.endsWith('23:59:59.999');

export const formatDateToCurrentUTC = (d?: string) =>
  (isAllDayDate(d)
    ? d
    : stringDateToUTC(d, {
        initialDateUTC0: true,
        timeZone: CURRENT_TIME_ZONE,
      })) || '';

export const taskDatesMapToCurrentUTCFn = (e: Task) => ({
  ...e,
  deadline: formatDateToCurrentUTC(e.deadline),
  deadlineLast: formatDateToCurrentUTC(e.deadlineLast),
  ...(e.timeNotification && {
    timeNotification: formatDateToCurrentUTC(e.timeNotification),
  }),
});
