import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@images/integrations/google-calendar.svg'


const _hoisted_1 = { class: "google-settings" }
const _hoisted_2 = { class: "google-settings__account" }
const _hoisted_3 = { class: "disabled-text" }
const _hoisted_4 = { class: "google-settings__content" }
const _hoisted_5 = { class: "notification-modal__btns" }

import { GoogleAccount } from '@/types/integrations';
import { ref, watch } from 'vue';
import GoogleAccountCalendarList from '@/components/integrations/google/settings/GoogleAccountCalendarList.vue';
import GoogleAccountWorkspacesList from '@/components/integrations/google/settings/GoogleAccountWorkspacesList.vue';
import { useCalendarsState } from '@/hooks/integrations/useCalendarsState';
import UiButton from '@/components/control/UiButton.vue';
import IconClose from '@/components/icon/IconClose.vue';
import ModalConfirmGoogle from '@/components/modals/Integrations/google/ModalConfirmGoogle.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { concatName } from '@/utils/text';

type Props = { account?: GoogleAccount | null };

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalSetupAccountGoogle',
  props: {
    account: {}
  },
  emits: ['onApply', 'onClose'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const isConfirmOpen = ref(false);

const { setInitState, onChangeState, resetState, state } = useCalendarsState();
watch(
  () => [props.account?.id, props.account?.calendars],
  () => {
    props.account?.calendars
      ? setInitState(props.account.calendars)
      : resetState();
  },
);

const onApplyUpdates = async () => {
  const accountId = props.account?.id;
  if (!accountId) return;
  emits('onApply', { state: state.value, accountId });
};

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: !!_ctx.account,
    width: "100rem",
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(IconClose, {
          class: "google-settings__close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isConfirmOpen.value = true))
        }),
        _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Select additional settings for easy use of the integration", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(UiAvatar, {
            "fallback-name": _unref(concatName)(_ctx.account?.firstName, _ctx.account?.secondName)
          }, null, 8, ["fallback-name"]),
          _createElementVNode("span", null, _toDisplayString(`${_ctx.account?.firstName} ${_ctx.account?.secondName}`), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.account?.email), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, " Customize how your Google calendars and Voiset workspaces display with each other ", -1)),
          _createVNode(GoogleAccountCalendarList, {
            calendars: _unref(state),
            onOnStatusChange: _unref(onChangeState),
            onOnModeChange: _unref(onChangeState),
            class: "with-border"
          }, null, 8, ["calendars", "onOnStatusChange", "onOnModeChange"]),
          _createVNode(GoogleAccountWorkspacesList)
        ]),
        _createVNode(UiButton, {
          class: "google-settings__connect",
          onClick: onApplyUpdates
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Done ")
          ])),
          _: 1
        }),
        (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
          _createVNode(ModalConfirmGoogle, {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "is-open": isConfirmOpen.value,
            title: "Stop setting"
          }, {
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(UiButton, {
                  plain: "",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
                  isConfirmOpen.value = false;
                  _ctx.$emit('onClose');
                }, ["stop"]))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Stop ")
                  ])),
                  _: 1
                }),
                _createVNode(UiButton, {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (isConfirmOpen.value = false), ["stop"]))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Continue settings ")
                  ])),
                  _: 1
                })
              ])
            ]),
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "google-settings__remove-confirm" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }),
                _createElementVNode("span", null, " Your settings are not saved, but you can always go back and customize your account. ")
              ], -1))
            ]),
            _: 1
          }, 8, ["is-open"])
        ]))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})