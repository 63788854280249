import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-item" }
const _hoisted_2 = { class: "upload-item__not-image" }
const _hoisted_3 = {
  key: 1,
  class: "upload-item-content upload-item__not-image"
}
const _hoisted_4 = { class: "upload-item__extension" }
const _hoisted_5 = { class: "upload-item__controls" }

import { ref } from 'vue';
import { GetFile } from '@/types/common';
import IconTrash from '@/components/icon/IconTrash.vue';
import IconUpload from '@/components/icon/IconUpload.vue';
import IconView from '@/components/icon/IconView.vue';

import IconCopy from '@/components/icon/IconCopy.vue';
import IconCreate from '@/components/icon/IconCreate.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiFile',
  props: {
    file: {},
    linksSet: {},
    initialPreviewId: {},
    isReadonly: { type: Boolean }
  },
  emits: ['deleteImage', 'uploadImage'],
  setup(__props: any) {





const imageRef = ref<HTMLElement | null>(null);
const onViewClick = () => imageRef?.value?.querySelector('img')?.click();

return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "upload-item",
      ref_key: "imageRef",
      ref: imageRef
    }, [
      (_ctx.file.typeFile === 'PHOTO')
        ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            fit: "contain",
            class: "upload-item-content",
            src: _ctx.file.link,
            "preview-src-list": _ctx.linksSet,
            "initial-index": _ctx.initialPreviewId,
            "hide-on-click-modal": ""
          }, {
            error: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(IconCopy, { color: "#000" }),
                _createElementVNode("span", null, _toDisplayString(_ctx.file.name), 1)
              ])
            ]),
            _: 1
          }, 8, ["src", "preview-src-list", "initial-index"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(IconCreate, { color: "#778bee" }),
              _createElementVNode("span", null, _toDisplayString(_ctx.file.name.split('.').pop()), 1)
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.file.name), 1)
          ]))
    ], 512),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.isReadonly)
        ? (_openBlock(), _createBlock(IconTrash, {
            key: 0,
            class: "upload-item__controls-item upload-item_trash-icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deleteImage', _ctx.file.id)))
          }))
        : _createCommentVNode("", true),
      _createVNode(IconUpload, {
        class: "upload-item__controls-item",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('uploadImage', _ctx.file)))
      }),
      (_ctx.file.typeFile === 'PHOTO')
        ? (_openBlock(), _createBlock(IconView, {
            key: 1,
            class: "upload-item__controls-item",
            onFocusin: onViewClick
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})