import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "dropdown" }
const _hoisted_3 = ["onClick"]

import { PropType, ref } from 'vue';
import IconChevron from '@/components/icon/IconChevron.vue';

export interface IItemDropdown {
  value: string;
  title: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdown',
  props: {
  modelValue: {
    type: Object as PropType<IItemDropdown>,
    required: true,
  },
  items: {
    type: Array as PropType<IItemDropdown[]>,
    required: true,
  },
  priorityLinks: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const dropdownElem = ref<{ handleClose: () => void }>();



const emit = __emit;

const setValue = (item: IItemDropdown) => {
  emit('update:modelValue', item);
  if (!dropdownElem.value) return;
  dropdownElem.value.handleClose();
};

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    teleported: false,
    trigger: "click",
    type: "primary",
    placement: _ctx.$attrs.placement || 'bottom',
    ref_key: "dropdownElem",
    ref: dropdownElem,
    id: "test"
  }, {
    dropdown: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "dropdown__item",
            onClick: ($event: any) => (setValue(item)),
            key: item.value
          }, [
            _renderSlot(_ctx.$slots, "default", { item: item })
          ], 8, _hoisted_3))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(["el-dropdown-link", { 'el-dropdown-link__priority': __props.modelValue && __props.priorityLinks }])
      }, [
        (_ctx.$slots.customModelValue)
          ? _renderSlot(_ctx.$slots, "customModelValue", {
              key: 0,
              modelValue: __props.modelValue
            })
          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.modelValue.title), 1)),
        _createVNode(IconChevron, {
          direction: "bottom",
          class: _normalizeClass(["el-dropdown-link-icon", {
          'el-dropdown-link-icon__priority': __props.modelValue && __props.priorityLinks,
        }])
        }, null, 8, ["class"])
      ], 2)
    ]),
    _: 3
  }, 8, ["placement"]))
}
}

})