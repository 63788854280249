import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ui-time-preset" }
const _hoisted_2 = ["onClick"]

import { computed } from 'vue';
import { differenceInMinutes, endOfDay } from 'date-fns';

type Props = { range?: number; start?: Date; end?: Date };

export default /*@__PURE__*/_defineComponent({
  __name: 'TimePresets',
  props: {
    range: {},
    start: {},
    end: {}
  },
  emits: ["setPreset"],
  setup(__props: any) {

const props = __props;


const presets = [
  { label: '15m', minutes: 15 },
  { label: '30m', minutes: 30 },
  { label: '45m', minutes: 45 },
  { label: '1h', minutes: 60 },
  { label: '2h', minutes: 120 },
];

const range = computed(
  () =>
    !!props.end && !!props.start && differenceInMinutes(props.end, props.start),
);

const minutesTillDayEnd = computed(
  () =>
    !!props.start && differenceInMinutes(endOfDay(props.start), props.start),
);

const disabled = (min: number) =>
  minutesTillDayEnd.value && min > minutesTillDayEnd.value;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("span", null, "How long?", -1)),
    _createElementVNode("div", null, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(presets, (p) => {
        return _createElementVNode("div", {
          key: p.minutes,
          onClick: ($event: any) => (_ctx.$emit('setPreset', p.minutes)),
          class: _normalizeClass({ active: range.value === p.minutes, disabled: disabled(p.minutes) })
        }, _toDisplayString(p.label), 11, _hoisted_2)
      }), 64))
    ])
  ]))
}
}

})