import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "help-modal__header" }
const _hoisted_2 = { class: "help-modal__title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "help-modal__description" }
const _hoisted_5 = { class: "help-modal__content" }
const _hoisted_6 = { class: "help-modal__btns" }

import { ref, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import services from '@/api/services';
import { UserTypeFeedback } from '@/types/user';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import ModalNotification from '@/components/modals/ModalNotification.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalHelp',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['onClose', 'openFeedback'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const { userData } = storeToRefs(useUserStore());



const isLoading = ref(false);

const emit = __emit;

const form = ref({
  email: '',
  subject: '',
  description: '',
  errorEmail: false,
  errorSubject: false,
  errorDesc: false,
});

const checkValidationForm = () => {
  const { description, email, subject } = form.value;

  if ([description, email, subject].filter(Boolean).length !== 3) {
    if (!description) {
      form.value.errorDesc = true;
    }
    if (!email) {
      form.value.errorEmail = true;
    }
    if (!subject) {
      form.value.errorSubject = true;
    }
    return false;
  }

  return true;
};

const sendFeedback = async () => {
  if (!checkValidationForm()) return;

  isLoading.value = true;
  const { description, email, subject } = form.value;
  const body = {
    description,
    email,
    subject,
  };

  try {
    await services.users.setFeedback(body, UserTypeFeedback.SUPPORT);
    form.value.subject = '';
    form.value.description = '';
    ElNotification.success(t('email_sent'));
    isLoading.value = false;
    emit('onClose');
  } catch (e) {
    ElNotification.error(t('some_error'));
    isLoading.value = false;
  }
};

onMounted(() => {
  form.value.email = userData.value?.email || '';
});

watch(
  () => userData.value,
  () => {
    form.value.email = userData.value?.email || '';
  },
);

return (_ctx: any,_cache: any) => {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(ModalNotification, _mergeProps({
    class: "notification-modal help-modal",
    "model-value": _ctx.isOpen
  }, _ctx.$attrs, {
    onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('onClose'))),
    width: "50rem"
  }), {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('support_modals.contact_us.title')), 1),
        _createElementVNode("p", {
          class: "help-modal__title help-modal__sub-title",
          innerHTML: 
            _ctx.$t('support_modals.contact_us.sub_title.text', {
              helper: `<a
          href='https://voiset.info/help-center'
          target='_blank'
          class='help-modal__title-link'
        >
          ${_ctx.$t('support_modals.contact_us.sub_title.helper')} </a
        >`,
            })
          
        }, null, 8, _hoisted_3),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('support_modals.contact_us.description')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ui_input, {
          modelValue: form.value.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.email) = $event)),
          label: _ctx.$t('support_modals.contact_us.form.email'),
          class: _normalizeClass({ error: form.value.errorEmail }),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (form.value.errorEmail = false))
        }, null, 8, ["modelValue", "label", "class"]),
        _createVNode(_component_ui_input, {
          modelValue: form.value.subject,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.subject) = $event)),
          label: _ctx.$t('support_modals.contact_us.form.subject'),
          class: _normalizeClass({ error: form.value.errorSubject }),
          onInput: _cache[3] || (_cache[3] = ($event: any) => (form.value.errorSubject = false))
        }, null, 8, ["modelValue", "label", "class"]),
        _createVNode(_component_ui_input, {
          class: _normalizeClass(["help-modal__textarea", { error: form.value.errorDesc }]),
          type: "textarea",
          rows: "7",
          modelValue: form.value.description,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.description) = $event)),
          label: _ctx.$t('support_modals.contact_us.form.detail'),
          onInput: _cache[5] || (_cache[5] = ($event: any) => (form.value.errorDesc = false))
        }, null, 8, ["modelValue", "label", "class"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ui_button, {
          onClick: sendFeedback,
          isDisabled: isLoading.value,
          isLoading: isLoading.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)
          ]),
          _: 1
        }, 8, ["isDisabled", "isLoading"]),
        _createVNode(_component_ui_button, {
          class: "help-modal__link",
          plain: "",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('openFeedback')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('support_modals.contact_us.feedback')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 16, ["model-value"]))
}
}

})