import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-tariff__list-item"
}
const _hoisted_2 = ["innerHTML"]

import IconTick from '@/components/icon/IconTick.vue';
import { ITariffItemResponse } from '@/types/tariffs';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPlanFeature',
  props: {
    name: {},
    count: {},
    accent: { default: 'green' },
    prop: {},
    markerColor: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "af1f4520": (_ctx.markerColor)
}))

const { t, te } = useI18n();

const props = __props;

const isTranslateExisted = computed(() =>
  te(`card_tariff.services.advantages.${props.name}.${props.prop}`),
);

return (_ctx: any,_cache: any) => {
  return (isTranslateExisted.value)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createVNode(IconTick, { class: "card-tariff__list-item-icon" }),
        _createElementVNode("span", {
          innerHTML: 
        _unref(t)(`card_tariff.services.advantages.${_ctx.name}.${_ctx.prop}`, {
          ...(_ctx.count && { count: _ctx.count }),
          accent: _ctx.accent,
        })
      
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})