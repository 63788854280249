import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'IconFavorite',
  props: {
    isActive: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: "22",
    height: "20",
    viewBox: "0 0 22 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    class: _normalizeClass([_ctx.isActive && _ctx.$style.active])
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      id: "Star 1",
      d: "M11 1.94164L12.899 7.78622L13.0337 8.20081H13.4697H19.615L14.6433 11.813L14.2906 12.0692L14.4254 12.4838L16.3244 18.3284L11.3527 14.7162L11 14.46L10.6473 14.7162L5.67563 18.3284L7.57465 12.4838L7.70936 12.0692L7.35669 11.813L2.38499 8.20081H8.53035H8.96627L9.10098 7.78622L11 1.94164Z",
      stroke: "#CECEDE",
      "stroke-width": "1.2"
    }, null, -1)
  ]), 2))
}
}

})