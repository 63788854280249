import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { VueSelecto } from 'vue3-selecto';
import { useVModel } from '@vueuse/core';
import { useTouchDevice } from '@/hooks/useTouchDevice';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotesSelection',
  props: {
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const data = useVModel(props, 'modelValue', emit);

const onSelect = (e: any) => {
  e.added.forEach((el: HTMLElement) => {
    const n = Number(el.dataset['select']);
    const index = data.value.indexOf(n);
    if (index === -1) data.value.push(n);
  });
  e.removed.forEach((el: HTMLElement) => {
    const n = Number(el.dataset['select']);
    const index = data.value.indexOf(n);
    if (index !== -1) data.value.splice(index, 1);
  });
};

const { isTouchDevice } = useTouchDevice();

return (_ctx: any,_cache: any) => {
  return (!_unref(isTouchDevice))
    ? (_openBlock(), _createBlock(_unref(VueSelecto), {
        key: 0,
        selectableTargets: ['.note-item[data-select]'],
        selectByClick: false,
        selectFromInside: false,
        continueSelect: true,
        hitRate: 0,
        onSelect: onSelect
      }))
    : _createCommentVNode("", true)
}
}

})