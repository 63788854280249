import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "status__text"
}

type Props = {
  status: string;
  onlyPictogram?: boolean;
  pictogramSize?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPictoStatus',
  props: {
    status: {},
    onlyPictogram: { type: Boolean },
    pictogramSize: { default: '2rem' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "dfd599e6": (_ctx.pictogramSize)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "status",
    style: _normalizeStyle(_ctx.onlyPictogram && { justifyContent: 'center' })
  }, [
    _createElementVNode("img", {
      src: require(`@images/statuses/${_ctx.status.toLowerCase()}.svg`),
      class: "status__icon",
      alt: ""
    }, null, 8, _hoisted_1),
    (!_ctx.onlyPictogram)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(`statuses.${_ctx.status.toUpperCase()}`)), 1))
      : _createCommentVNode("", true)
  ], 4))
}
}

})