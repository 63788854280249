import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "25",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconCreate',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", null, [
      _createElementVNode("path", {
        d: "M14.9995 23.25H8.99951C3.56951 23.25 1.24951 20.93 1.24951 15.5V9.5C1.24951 4.07 3.56951 1.75 8.99951 1.75H13.9995C14.4095 1.75 14.7495 2.09 14.7495 2.5C14.7495 2.91 14.4095 3.25 13.9995 3.25H8.99951C4.38951 3.25 2.74951 4.89 2.74951 9.5V15.5C2.74951 20.11 4.38951 21.75 8.99951 21.75H14.9995C19.6095 21.75 21.2495 20.11 21.2495 15.5V10.5C21.2495 10.09 21.5895 9.75 21.9995 9.75C22.4095 9.75 22.7495 10.09 22.7495 10.5V15.5C22.7495 20.93 20.4295 23.25 14.9995 23.25Z",
        fill: __props.color
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M21.9995 11.25H17.9995C14.5795 11.25 13.2495 9.91999 13.2495 6.49999V2.49999C13.2495 2.19999 13.4295 1.91999 13.7095 1.80999C13.9895 1.68999 14.3095 1.75999 14.5295 1.96999L22.5295 9.96999C22.7395 10.18 22.8095 10.51 22.6895 10.79C22.5695 11.07 22.2995 11.25 21.9995 11.25ZM14.7495 4.30999V6.49999C14.7495 9.07999 15.4195 9.74999 17.9995 9.74999H20.1895L14.7495 4.30999Z",
        fill: __props.color
      }, null, 8, _hoisted_3)
    ])
  ]))
}
}

})