import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@images/integrations/google-calendar.svg'


const _hoisted_1 = {
  key: 0,
  class: "google-calendars__list with-scroll"
}

import UiSpinner from '@/components/ui/UiSpinner.vue';
import GoogleAccountSettingsCalendar from '@/components/integrations/google/settings/GoogleAccountSettingsCalendar.vue';
import { GoogleCalendar } from '@/types/integrations';

type Props = { calendars?: GoogleCalendar[]; pending?: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleAccountCalendarList',
  props: {
    calendars: {},
    pending: { type: Boolean }
  },
  emits: ['onStatusChange', 'onModeChange'],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "google-calendars__row" }, [
      _createElementVNode("span", null, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createTextVNode(" Google Calendars ")
      ]),
      _createElementVNode("span", { class: "disabled-text" }, "Default")
    ], -1)),
    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "google-calendars__desc" }, " Your Google Calendars to be displayed in Voiset ", -1)),
    _createVNode(_Transition, {
      name: "blur",
      appear: "",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.calendars?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendars, (c) => {
                return (_openBlock(), _createBlock(GoogleAccountSettingsCalendar, {
                  onOnStatusChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onStatusChange', $event))),
                  onOnModeChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onStatusChange', $event))),
                  calendar: c,
                  key: c.id
                }, null, 8, ["calendar"]))
              }), 128))
            ]))
          : (_ctx.pending)
            ? (_openBlock(), _createBlock(UiSpinner, { key: 1 }))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})