import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-tariff__features-label"
}

import { formatBytes } from '@/utils/convert';
import { secondsToMinutes } from 'date-fns';
import { ITariffItemResponse } from '@/types/tariffs';
import { computed } from 'vue';
import UiPlanFeature from '@/components/ui/UiPlan/UiPlanFeature.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPlanFeatures',
  props: {
    item: {},
    theme: {},
    noLabel: { type: Boolean },
    markersColor: {},
    fontSize: { default: '1.2rem' },
    gap: { default: '1.2rem' },
    size: { default: 'default' }
  },
  setup(__props: any) {

const props = __props;

const styles = computed(() => {
  if (props.size === 'large') {
    return {
      gap: '1.6rem',
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    };
  }
  return {
    gap: '1.2rem',
    fontSize: '1.2rem',
  };
});

const themeColors = computed(() => {
  const colors = {
    default: {
      textColor: '#1A2A39',
      accentColor: '#9BC95E',
      markerColor: props.markersColor || '#9BC95E',
      accent: 'green',
    },
    active: {
      textColor: '#1A2A39',
      accentColor: '#778BEE',
      markerColor: props.markersColor || '#FCFCFC',
      accent: 'purple',
    },
  };

  return colors[props.theme];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "label", {}, () => [
      (!_ctx.noLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t(`card_tariff.services.advantages.${_ctx.item.name}.label`)), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", {
      class: "card-tariff__list",
      style: _normalizeStyle(styles.value)
    }, [
      (_ctx.item.amountSeconds)
        ? (_openBlock(), _createBlock(UiPlanFeature, {
            key: 0,
            prop: "recognition",
            name: _ctx.item.name,
            count: _unref(secondsToMinutes)(Number(_ctx.item.amountSeconds)),
            accent: themeColors.value.accent,
            "marker-color": themeColors.value.markerColor
          }, null, 8, ["name", "count", "accent", "marker-color"]))
        : _createCommentVNode("", true),
      (_ctx.item.workSpace)
        ? (_openBlock(), _createBlock(UiPlanFeature, {
            key: 1,
            prop: "workspaces",
            name: _ctx.item.name,
            count: _ctx.item.workSpace,
            accent: themeColors.value.accent,
            "marker-color": themeColors.value.markerColor
          }, null, 8, ["name", "count", "accent", "marker-color"]))
        : _createCommentVNode("", true),
      (_ctx.item.amountStorageBytes)
        ? (_openBlock(), _createBlock(UiPlanFeature, {
            key: 2,
            prop: "storage",
            name: _ctx.item.name,
            count: _unref(formatBytes)(_ctx.item.amountStorageBytes ?? 0),
            accent: themeColors.value.accent,
            "marker-color": themeColors.value.markerColor
          }, null, 8, ["name", "count", "accent", "marker-color"]))
        : _createCommentVNode("", true),
      (_ctx.item.amountPersonal)
        ? (_openBlock(), _createBlock(UiPlanFeature, {
            key: 3,
            prop: "invitations",
            name: _ctx.item.name,
            count: _ctx.item.amountPersonal,
            accent: themeColors.value.accent,
            "marker-color": themeColors.value.markerColor
          }, null, 8, ["name", "count", "accent", "marker-color"]))
        : _createCommentVNode("", true),
      (_ctx.item.amountSeconds)
        ? (_openBlock(), _createBlock(UiPlanFeature, {
            key: 4,
            prop: "translate",
            name: _ctx.item.name,
            count: _unref(secondsToMinutes)(Number(_ctx.item.amountSeconds)),
            accent: themeColors.value.accent,
            "marker-color": themeColors.value.markerColor
          }, null, 8, ["name", "count", "accent", "marker-color"]))
        : _createCommentVNode("", true),
      _createVNode(UiPlanFeature, {
        prop: "web_app",
        name: _ctx.item.name,
        accent: themeColors.value.accent,
        "marker-color": themeColors.value.markerColor
      }, null, 8, ["name", "accent", "marker-color"]),
      _createVNode(UiPlanFeature, {
        prop: "support",
        name: _ctx.item.name,
        accent: themeColors.value.accent,
        "marker-color": themeColors.value.markerColor
      }, null, 8, ["name", "accent", "marker-color"]),
      _createVNode(UiPlanFeature, {
        prop: "mobile_app",
        name: _ctx.item.name,
        accent: themeColors.value.accent,
        "marker-color": themeColors.value.markerColor
      }, null, 8, ["name", "accent", "marker-color"]),
      _createVNode(UiPlanFeature, {
        prop: "unlimited_notes",
        name: _ctx.item.name,
        accent: themeColors.value.accent,
        "marker-color": themeColors.value.markerColor
      }, null, 8, ["name", "accent", "marker-color"]),
      _createVNode(UiPlanFeature, {
        prop: "ai_autoplanning",
        name: _ctx.item.name,
        accent: themeColors.value.accent,
        "marker-color": themeColors.value.markerColor
      }, null, 8, ["name", "accent", "marker-color"])
    ], 4)
  ], 64))
}
}

})