<template>
  <LayoutHeader v-if="$route.meta.header" />
  <div class="page">
    <slot />
    <ModalPlanBuy
      @close="isPlansModalVisible = false"
      :is-open="isPlansModalVisible"
    />
  </div>
</template>

<script setup lang="ts">
import LayoutHeader from '@/components/layout/default/LayoutHeader.vue';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';

const tariffStore = useTariffStore();
const { isPlansModalVisible } = storeToRefs(tariffStore);
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
}
</style>
