import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ui-reminder__list" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "ui-reminder__reference" }

import { computed, ref } from 'vue';
import { isSameMinute, subMinutes } from 'date-fns';
import format from 'date-fns/format';
import { useI18n } from 'vue-i18n';

type Props = {
  modelValue?: string | false;
  startTime?: Date;
  disabled?: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeReminder',
  props: {
    modelValue: { type: [String, Boolean] },
    startTime: {},
    disabled: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const { t } = useI18n();

const isShow = ref(false);

const reminderValues = computed(() => {
  const values = [];
  values.push({ value: false, label: t('time.no_remind') });
  if (!props.startTime) return values;
  for (let i = 15; i <= 60; i += 15) {
    const rawValue = subMinutes(props.startTime, i);
    const value = format(rawValue, 'yyyy-MM-dd HH:mm');
    let label = t('time.period', { min: i });
    if (i === 30) label += ` (${format(rawValue, 'HH:mm')})`;

    values.push({ value, label, rawValue });
  }
  return values;
});

const currentValue = computed(() => {
  if (!props.modelValue) return;
  console.log(props.modelValue);
  return reminderValues.value.find((v) =>
    isSameMinute(new Date(props.modelValue || 0), v.rawValue || 0),
  )?.label;
});

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-reminder', { disabled: _ctx.disabled }])
  }, [
    _createTextVNode(_toDisplayString(_ctx.$t('time.reminder')) + " ", 1),
    _createVNode(_component_el_popover, {
      placement: "bottom",
      width: "fit-content",
      trigger: "click",
      visible: isShow.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((isShow).value = $event))
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(currentValue.value || _ctx.$t('time.no_remind')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reminderValues.value, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.label,
              class: _normalizeClass({ selected: currentValue.value === item.label }),
              onClick: ($event: any) => {
            _ctx.$emit('update:modelValue', item.value);
            isShow.value = false;
          }
            }, _toDisplayString(item.label), 11, _hoisted_2))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 2))
}
}

})