import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  height: "25",
  viewBox: "0 0 24 25",
  width: "24",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke", "fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconCheck',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      stroke: __props.color,
      d: "M9.65815 15.329L10.011 15.6804L10.3639 15.329L17.5635 8.1577C17.7746 7.94743 18.1314 7.94743 18.3425 8.1577C18.5525 8.36689 18.5525 8.71892 18.3425 8.9281L10.4005 16.8388C10.2973 16.9417 10.1577 17 10.011 17C9.86434 17 9.72474 16.9417 9.62149 16.8388L5.65751 12.8904C5.4475 12.6812 5.4475 12.3292 5.65751 12.12C5.86861 11.9098 6.22544 11.9098 6.43654 12.12L9.65815 15.329Z",
      fill: __props.color
    }, null, 8, _hoisted_2)
  ]))
}
}

})