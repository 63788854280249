import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, inject, nextTick, provide, Ref, ref, watch } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import { templateRef } from '@vueuse/core';
import {
  autoPlacement,
  autoUpdate,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { scrollToSelector } from '@/utils/scroll';

const HIGHLIGHT_CLASS = 'coach-highlight';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachActive',
  setup(__props) {

const coachesStore = useCoachesStore();
const { activeCard, isCoachesVisible } = storeToRefs(coachesStore);

const card = templateRef<HTMLElement>('card');
const reference = ref<Element | null>(null);

const middleware = ref([
  offset(25),
  autoPlacement(),
  shift({ padding: 5, crossAxis: true }),
]);

const { floatingStyles, placement } = useFloating(reference, card, {
  middleware,
  whileElementsMounted: autoUpdate,
});

provide(
  'position',
  computed(() => placement.value),
);

watch(
  [() => activeCard.value, () => isCoachesVisible.value],
  async ([current, enabled], [prev]) => {
    await prev?.onAfter?.();
    if (prev?.attachTo) {
      const prevRef = document.querySelector(`#${prev.attachTo}`);
      prevRef?.classList.remove(HIGHLIGHT_CLASS);
    }

    if (!current) return;
    await current?.onBefore?.();
    const currentRef = document.querySelector(`#${current.attachTo}`);
    if (!enabled) return currentRef?.classList.remove(HIGHLIGHT_CLASS);

    await nextTick(() => scrollToSelector(`#${current.attachTo}`));
    currentRef?.classList.add(HIGHLIGHT_CLASS);
    reference.value = currentRef;
  },
  { immediate: true, deep: true },
);

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
watch(
  () => isMobileResolution?.value,
  () => {
    if (!activeCard.value?.attachTo) return;
    coachesStore.disableCoaches();
    document
      .querySelector(`#${activeCard.value.attachTo}`)
      ?.classList.remove(HIGHLIGHT_CLASS);
  },
);

return (_ctx: any,_cache: any) => {
  return (_unref(activeCard) && _unref(isCoachesVisible))
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(activeCard).component), {
        key: 0,
        style: _normalizeStyle(_unref(floatingStyles)),
        ref_key: "card",
        ref: card
      }, null, 8, ["style"]))
    : _createCommentVNode("", true)
}
}

})