import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nav-item__text"
}

import { defineAsyncComponent } from 'vue';
type Props = {
  to: string;
  iconName: string;
  label?: string;
  showLabel?: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'UiAsideItem',
  props: {
    to: {},
    iconName: {},
    label: { default: '' },
    showLabel: { type: Boolean }
  },
  emits: ['closeSidebar'],
  setup(__props: any) {

const props = __props;


const icon = defineAsyncComponent(
  () => import('@/components/icon/nav/Icon' + props.iconName),
);

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    custom: ""
  }, {
    default: _withCtx(({ isActive }) => [
      _createVNode(_component_router_link, _mergeProps({
        class: ["nav-item", { 'router-link-active': _ctx.$route.path.includes(_ctx.to) }],
        to: _ctx.to,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeSidebar')))
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _createVNode(_component_el_tooltip, {
            content: _ctx.label,
            disabled: _ctx.showLabel,
            effect: "customized",
            placement: "right"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(icon)), {
                class: "nav-item__icon",
                "is-active": isActive || _ctx.$route.path.includes(_ctx.to)
              }, null, 8, ["is-active"]))
            ]),
            _: 2
          }, 1032, ["content", "disabled"]),
          (_ctx.showLabel)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1040, ["to", "class"])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})