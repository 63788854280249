import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconAroundCheck',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M19.7879 10.1703C20.2168 11.9958 19.9908 13.914 19.1493 15.5899C18.3078 17.2657 16.9044 18.5927 15.184 19.3391C13.4637 20.0854 11.5359 20.2037 9.73717 19.6733C7.9385 19.1429 6.38331 17.9975 5.34324 16.4371C4.30316 14.8767 3.84429 13.0005 4.04677 11.1362C4.24925 9.2719 5.10022 7.53798 6.45104 6.23727C7.80187 4.93655 9.5667 4.15171 11.4373 4.01981C13.3079 3.88792 15.1654 4.41735 16.6854 5.51564",
      stroke: __props.color,
      "stroke-width": "1.4",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M11.6531 16C11.3929 16 11.1458 15.8884 10.9637 15.6931L7.28289 11.7447C6.9057 11.3401 6.9057 10.6704 7.28289 10.2658C7.66008 9.86118 8.28439 9.86118 8.66158 10.2658L11.6531 13.4747L18.3384 6.30345C18.7156 5.89885 19.3399 5.89885 19.7171 6.30345C20.0943 6.70806 20.0943 7.37775 19.7171 7.78235L12.3424 15.6931C12.1603 15.8884 11.9132 16 11.6531 16Z",
      fill: __props.color
    }, null, 8, _hoisted_3)
  ]))
}
}

})