import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }

import { type Component, computed } from 'vue';
import IconCrown from '@/components/icon/IconCrown.vue';
import IconStar from '@/components/icon/IconStar.vue';
import IconLightning from '@/components/icon/IconLightning.vue';
import { ITariffItemResponse } from '@/types/tariffs';

type Title = ITariffItemResponse['title'];


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPlanTitle',
  props: {
    type: { default: 'basic' },
    color: { default: '#9bc95e' },
    size: { default: 'default' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "0a0a389c": (_ctx.color)
}))

const props = __props;

const plans: {
  [key in Title]: {
    title: key;
    icon?: Component;
  };
} = {
  general: {
    title: 'general',
  },
  basic: {
    title: 'basic',
    icon: IconStar,
  },
  advanced: {
    title: 'advanced',
    icon: IconLightning,
  },
  premium: {
    title: 'premium',
    icon: IconCrown,
  },
};

const currentPlan = computed(() => {
  const currentType = props.type.toLowerCase() as Title;
  return plans[currentType] || plans['basic'];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-plan", [_ctx.size]])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(currentPlan.value.icon), {
      color: _ctx.color,
      class: "icon"
    }, null, 8, ["color"])),
    _createElementVNode("div", _hoisted_1, _toDisplayString(currentPlan.value.title), 1)
  ], 2))
}
}

})