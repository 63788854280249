import { taskFastFilters, taskPriorities, taskStatuses } from '@/consts';
import {
  filtersMapping,
  subordinatesFiltersMapping,
} from '@/utils/filtersMapping';
import UiTableFiltersPresetList from '@/components/table/filters/UiTableFiltersPresetList.vue';
import UiTableFiltersCheckboxList from '@/components/table/filters/UiTableFiltersCheckboxList.vue';
import UiTableFiltersUsersList from '@/components/table/filters/UiTableFiltersUsersList.vue';
import { ISubordinate } from '@/types/subordinate';

export const FAST_FILTERS = {
  options: taskFastFilters.map(filtersMapping),
  label: 'tasks.filters.presets',
  filterField: 'taskFastFilters',
  component: UiTableFiltersPresetList,
};

export const STATUS_FILTERS = {
  options: Object.keys(taskStatuses).map(filtersMapping),
  label: 'tasks.filters.status',
  filterField: 'statusList',
  component: UiTableFiltersCheckboxList,
};

export const PRIORITY_FILTERS = {
  options: Object.keys(taskPriorities).map(filtersMapping),
  label: 'tasks.filters.priority',
  filterField: 'priorityList',
  component: UiTableFiltersCheckboxList,
};

export const EXECUTOR_FILTERS = (subordinates?: ISubordinate[]) => ({
  options: subordinates?.map(subordinatesFiltersMapping) || [],
  label: 'tasks.filters.performer',
  filterField: 'executorIdList',
  component: UiTableFiltersUsersList,
});

export const CREATOR_FILTERS = (subordinates?: ISubordinate[]) => ({
  options: subordinates?.map(subordinatesFiltersMapping) || [],
  label: 'tasks.filters.creator',
  filterField: 'creatorIdList',
  component: UiTableFiltersUsersList,
});
