import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import UiModal from '@/components/ui/UiModal.vue';
import NoteTransformTaskBody from '@/components/notes/transform/task-card/NoteTransformTaskBody.vue';
import NoteTransformTaskFooter from '@/components/notes/transform/task-card/NoteTransformTaskFooter.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { onBeforeMount } from 'vue';
import { useNoteToTaskStore } from '@/stores/notes/useNoteToTask';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformTaskCard',
  setup(__props) {

const noteTransformStore = useNotesTransformStore();
const noteToTaskStore = useNoteToTaskStore();

onBeforeMount(() => {
  noteToTaskStore.start(noteTransformStore.rawTasks[0]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiModal, {
    "is-open": true,
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(noteTransformStore).end())),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "close-disabled": true,
    title: 'Create a new task',
    class: "note-task-card-modal"
  }, {
    body: _withCtx(() => [
      _createVNode(NoteTransformTaskBody)
    ]),
    footer: _withCtx(() => [
      _createVNode(NoteTransformTaskFooter)
    ]),
    _: 1
  }))
}
}

})