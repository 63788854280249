import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "support-sub__container" }
const _hoisted_2 = ["onClick"]

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SupportSubComponent',
  setup(__props) {

const { t } = useI18n();

const itemsList = computed(() => {
  return [
    {
      id: 1,
      title: t('userDropDownMenu.sub_text'),
      action: 'contact',
    },
    {
      id: 2,
      title: t('support_modals.contact_us.feedback'),
      action: 'feedback',
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemsList.value, (item) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "support-sub__item",
        key: item.id,
        onClick: ($event: any) => (_ctx.$emit('openModal', item.action))
      }, _toDisplayString(item.title), 9, _hoisted_2))
    }), 128))
  ]))
}
}

})