import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { Workspace } from '@/types/workspaces';
import IconMarker from '@/components/icon/IconMarker.vue';
import OneWaySyncSmall from '@/components/integrations/google/settings/heplers/OneWaySyncSmall.vue';
import TwoWaySyncSmall from '@/components/integrations/google/settings/heplers/TwoWaySyncSmall.vue';
import Fade from '@/components/animation/Fade.vue';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkspaceItem',
  props: {
    item: {},
    isActive: { type: Boolean },
    isDisabled: { type: Boolean },
    ignoreExternal: { type: Boolean }
  },
  emits: ['onGoogleCalendarModeChange'],
  setup(__props: any) {

const props = __props;

const externalItem = computed(() => props.item.externalCalendarItem);

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['workspace-item', { active: _ctx.isActive, disabled: _ctx.isDisabled }])
  }, [
    _createVNode(IconMarker, {
      color: _ctx.item.color
    }, null, 8, ["color"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.item.name), 1),
    _createVNode(Fade, null, {
      default: _withCtx(() => [
        (externalItem.value?.syncCalendarMode && !_ctx.ignoreExternal)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(
          externalItem.value?.syncCalendarMode === 'SINGLE'
            ? OneWaySyncSmall
            : TwoWaySyncSmall
        ), {
              key: externalItem.value?.syncCalendarMode,
              sync: externalItem.value?.syncCalendarMode,
              class: "workspace-item__sync",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
          _ctx.$emit('onGoogleCalendarModeChange', {
            syncCalendarMode:
              externalItem.value.syncCalendarMode === 'SINGLE' ? 'DUAL' : 'SINGLE',
            id: externalItem.value.id,
          })
        ), ["stop"]))
            }, null, 8, ["sync"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_el_radio_group, {
      "model-value": _ctx.isActive,
      disabled: _ctx.isDisabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio, { value: true })
      ]),
      _: 1
    }, 8, ["model-value", "disabled"])
  ], 2))
}
}

})