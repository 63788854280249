import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@images/integrations/google/voiset-logo.webp'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["disabled"]

import { computed, ref } from 'vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import IconChevron from '@/components/icon/IconChevron.vue';
import IconMarker from '@/components/icon/IconMarker.vue';
import UiDropdownExpandable from '@/components/ui/UiDropdown/UiDropdownExpandable.vue';
import WorkspaceItem from '@/components/workspaces/WorkspaceItem.vue';
import { useWorkspaceById } from '@/hooks/useWorkspaceById';
import { Workspace } from '@/types/workspaces';

interface Props {
  companyId?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderWorkspaceSelect',
  props: {
    companyId: {}
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isOpen = ref(false);

const workspacesStore = useWorkspaceStore();
const { list, ownWorkspacesList, externalList, guestWorkspacesList } =
  storeToRefs(workspacesStore);

const workspacesOptions = computed(() =>
  list.value.map((w) => ({ label: w.name, value: w.id, color: w.color })),
);

const currentWorkspace = computed(() =>
  workspacesOptions.value.find((o) => o.value === props.companyId),
);

const onSelect = (v: number) => {
  emit('select', v);
  isOpen.value = false;
};

const { externalGoogle } = useWorkspaceById(computed(() => props.companyId));

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    trigger: "click",
    placement: "bottom",
    width: "30.5rem",
    disabled: _unref(externalGoogle)?.isSingleSync,
    "popper-class": _ctx.$style.popper,
    visible: isOpen.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event))
  }, {
    reference: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.$style.wrapper)
      }, [
        _cache[2] || (_cache[2] = _createTextVNode(" Workspace ")),
        _createElementVNode("button", {
          class: _normalizeClass([
            _ctx.$style.label,
            {
              [_ctx.$style.active]: isOpen.value,
              [_ctx.$style.disabled]: _unref(externalGoogle)?.isSingleSync,
            },
          ]),
          disabled: _unref(externalGoogle)?.isSingleSync
        }, [
          _createVNode(IconMarker, {
            color: currentWorkspace.value?.color,
            size: "0.8rem"
          }, null, 8, ["color"]),
          _createElementVNode("span", null, _toDisplayString(currentWorkspace.value?.label), 1),
          _createVNode(IconChevron, {
            direction: isOpen.value ? 'top' : 'bottom',
            size: "1.6rem"
          }, null, 8, ["direction"])
        ], 10, _hoisted_3)
      ], 2)), [
        [
          _directive_skeleton,
          !currentWorkspace.value,
          void 0,
          { large: true }
        ]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.group)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.heading)
        }, "Your workspaces", 2),
        (_unref(ownWorkspacesList)?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.listLabel)
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1),
                _createTextVNode(" Voiset ")
              ]), 2),
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.$style.list, 'with-scroll'])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ownWorkspacesList), (owner) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: owner.id
                  }, [
                    (_openBlock(), _createBlock(WorkspaceItem, {
                      key: owner.id,
                      item: owner,
                      "is-active": _ctx.companyId === owner.id,
                      onClick: ($event: any) => (onSelect(owner.id))
                    }, null, 8, ["item", "is-active", "onClick"]))
                  ]))
                }), 128))
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_unref(externalList).GOOGLE)
          ? (_openBlock(), _createBlock(UiDropdownExpandable, {
              key: 1,
              static: ""
            }, {
              label: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.listLabel)
                }, [
                  (_unref(externalList).GOOGLE.config?.icon)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        alt: "",
                        src: _unref(externalList).GOOGLE.config.icon
                      }, null, 8, _hoisted_2))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_unref(externalList).GOOGLE.config?.label), 1)
                ], 2)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(externalList).GOOGLE.content, (group) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: group.account
                  }, [
                    _createVNode(UiDropdownExpandable, {
                      static: _unref(externalList).GOOGLE.content.length === 1,
                      "is-initial-open": 
              !!_ctx.companyId && group.list.map((w) => w.id).includes(_ctx.companyId)
            
                    }, _createSlots({
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: _normalizeStyle({
                overflow: 'hidden',
                marginLeft:
                  _unref(externalList).GOOGLE.content.length > 1 ? '1rem' : '',
              })
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass([_ctx.$style.list, 'with-scroll'])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.list, (item) => {
                              return (_openBlock(), _createBlock(WorkspaceItem, {
                                key: item.id,
                                item: item,
                                "is-active": _ctx.companyId === item.id,
                                "is-disabled": 
                    item.externalCalendarItem?.syncCalendarMode === 'SINGLE'
                  ,
                                "ignore-external": "",
                                onClick: ($event: any) => (
                    item.externalCalendarItem?.syncCalendarMode !== 'SINGLE' &&
                      onSelect(item.id)
                  )
                              }, null, 8, ["item", "is-active", "is-disabled", "onClick"]))
                            }), 128))
                          ], 2)
                        ], 4)
                      ]),
                      _: 2
                    }, [
                      (_unref(externalList).GOOGLE.content.length > 1)
                        ? {
                            name: "label",
                            fn: _withCtx(({ isOpen }) => [
                              _createElementVNode("div", {
                                class: _normalizeClass([_ctx.$style.listLabel, _ctx.$style.sub])
                              }, [
                                _createVNode(IconChevron, {
                                  direction: isOpen ? 'bottom' : 'right'
                                }, null, 8, ["direction"]),
                                _createElementVNode("span", null, _toDisplayString(group.account), 1)
                              ], 2)
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1032, ["static", "is-initial-open"])
                  ]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2),
      (_unref(guestWorkspacesList).length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.$style.group, _ctx.$style.guest])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.heading)
            }, _toDisplayString(_ctx.$t('workspaces.guest')), 3),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style.list, 'with-scroll'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(guestWorkspacesList), (item) => {
                return (_openBlock(), _createBlock(WorkspaceItem, {
                  key: item.id,
                  item: item,
                  "is-active": _ctx.companyId === item.id,
                  onClick: ($event: any) => (onSelect(item.id))
                }, null, 8, ["item", "is-active", "onClick"]))
              }), 128))
            ], 2)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabled", "popper-class", "visible"]))
}
}

})