import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "note-card-modal-footer" }
const _hoisted_2 = { class: "note-card-modal-footer__player" }

import UiAudioPlayer from '@/components/ui/UIAudioPlayer.vue';
import IconAI from '@/components/icon/IconAI.vue';
import { storeToRefs } from 'pinia';
import { useNoteStore } from '@/stores/notes/useNote';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteCardModalFooter',
  props: {
    readonly: { type: Boolean, default: false }
  },
  emits: ["schedule"],
  setup(__props: any) {





const { audioFile, isAudioLoading, isNoteLoading, isNoteValid, note } =
  storeToRefs(useNoteStore());

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (_unref(audioFile))
        ? (_openBlock(), _createBlock(UiAudioPlayer, {
            key: 0,
            audio: _unref(audioFile)
          }, null, 8, ["audio"]))
        : _createCommentVNode("", true)
    ])), [
      [_directive_skeleton, _unref(isNoteLoading) || _unref(isAudioLoading)]
    ]),
    _createVNode(_component_ui_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.readonly && _ctx.$emit('schedule'))),
      "is-disabled": _unref(isNoteLoading) || !_unref(isNoteValid) || _unref(note).status === 'CLOSE',
      class: "note-card-modal-footer__schedule",
      id: "note-schedule"
    }, {
      default: _withCtx(() => [
        _createVNode(IconAI),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('notes.schedule.one')), 1)
      ]),
      _: 1
    }, 8, ["is-disabled"])
  ]))
}
}

})