import { defineStore, storeToRefs } from 'pinia';

import { computed, ref } from 'vue';
import services from '@/api/services';

import { filesTypesMapping, ITaskStatusItem } from '@/consts';
import { ICreateTaskRequest, SetTaskTimeParams, Task } from '@/types/tasks';
import { GetFile } from '@/types/common';
import { useViewTaskCommentsStore } from '@/stores/viewTask/viewTaskComments';
import { useTasksStore } from '@/stores/tasks/tasks';
import { useRoute, useRouter } from 'vue-router';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { AxiosResponse, isAxiosError } from 'axios';
import { format, setHours, setMinutes } from 'date-fns';
import { getTimeByParts, isAllDayDate, stringDateToUTC } from '@/utils/time';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import {
  CURRENT_TIME_ZONE,
  TASK_DAY_FORMAT,
  TASK_TIME_FORMAT,
} from '@/consts/timeFormats';
import { useUserStore } from '@/stores/user';
import { createEventHook, useDebounceFn } from '@vueuse/core';
import { useChanged } from '@/hooks/useChanged';
import { formatTaskTimeToUTC } from '@/utils/task';
import { useWorkspaceById } from '@/hooks/useWorkspaceById';
import { TaskState, useTaskState } from '@/hooks/tasks/useTaskState';

interface ServicesList {
  task: any;
  share: any;
}

export const useViewTaskStore = defineStore('viewTask', () => {
  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n();
  const { formatWithLocale } = useCurrentLanguage();

  const files = ref<GetFile[]>([]);
  const audioFile = ref<string | null>(null);

  const { state, setState, resetState, openModal } = useTaskState();

  const secureTaskId = ref('');

  const updateEvent = createEventHook<{
    task: Task;
    prevTask: Task;
    companyId: number;
  }>();

  const servicesList: ServicesList = {
    task: {
      base: services.tasks,
      files: services.files,
    },
    share: {
      base: services.share,
      files: services.share,
    },
  };

  const currentService = ref<keyof ServicesList>('task');

  const commentsStore = useViewTaskCommentsStore();
  const taskStore = useTasksStore();
  const userStore = useUserStore();
  const { userData } = storeToRefs(userStore);

  const initialData = (): Partial<Task> => ({
    id: 0,
    executor: {
      id: 0,
      lastName: '',
      firstName: '',
      avatar: null,
    },
    deadline: '',
    name: '',
    attachedFileIds: [],
    taskContent: '',
    taskPriority: 'NORMAL',
    taskStatus: '' as ITaskStatusItem,
    isMarked: false,
  });

  const activeTask = ref<Partial<Task>>(initialData());
  const originalActiveTask = ref<Partial<Task> | undefined>();

  const datePickerData = computed(() => ({
    modelValue: activeTask.value.deadline || '',
    endTime: activeTask.value.deadlineLast,
    reminder: activeTask.value.timeNotification,
    executor: activeTask.value.executor,
    companyId: activeTask.value.companyId,
    dateView: getDateView.value,
  }));

  const { externalGoogle } = useWorkspaceById(
    computed(() => activeTask.value.companyId),
  );

  const isReadonly = computed(() => !!externalGoogle.value?.isSingleSync);

  const {
    isChanged: hasChanges,
    init: initChanged,
    reset: resetChanged,
    mark: markChanged,
  } = useChanged(activeTask);

  const formattedActiveTask = (): ICreateTaskRequest => {
    const {
      attachedFileIds: files,
      deadline,
      name,
      executor,
      taskContent,
      taskPriority,
      audioFileId,
      timeNotification,
      deadlineLast,
      taskStatus,
      companyId,
      hardDeadline,
      isMarked,
    } = activeTask.value;
    return {
      companyId: companyId || userData.value?.currentCompany || 0,
      executorId: executor?.id || null,
      filesIds: [...(files?.map((file) => file.id) || [])],
      audioFileId: audioFileId?.id || 0,
      deadline:
        (isAllDayDate(deadline) ? deadline : stringDateToUTC(deadline)) || '',
      name: name || '',
      taskContent: taskContent || '',
      taskPriority: taskPriority || 'NORMAL',
      deadlineLast: stringDateToUTC(deadlineLast) || null,
      notifyTime: stringDateToUTC(timeNotification) || null,
      hardDeadline: hardDeadline || false,
      taskStatus,
      isMarked,
    };
  };

  const getDateView = computed(() => {
    if (activeTask.value.deadline) {
      return formatWithLocale(
        new Date(activeTask.value.deadline),
        'dd MMMM yyyy',
      );
    }

    return '';
  });

  const updateActiveTask = async () => {
    await updateTask(formattedActiveTask(), activeTask.value.id);
  };
  const debouncedUpdateActiveTask = useDebounceFn(updateActiveTask, 3000);

  const setActiveTaskFields = <
    Field extends keyof Task,
    Value extends Task[Field],
  >(
    field: Field,
    value?: Value,
  ) => {
    activeTask.value[field] = value;
    currentService.value === 'share' && debouncedUpdateActiveTask();
  };

  function setDate(date: Date) {
    activeTask.value.deadline = format(new Date(date), TASK_DAY_FORMAT);

    activeTask.value.deadlineLast = undefined;
  }

  async function setTime({
    startTime,
    endTime,
    reminderTime,
  }: SetTaskTimeParams) {
    if (!activeTask.value.deadline) return;
    const currentDate = new Date(activeTask.value.deadline);

    if (startTime) {
      const [startHour, startMinutes] = getTimeByParts(startTime);

      activeTask.value.deadline = format(
        setMinutes(setHours(currentDate, startHour), startMinutes),
        TASK_TIME_FORMAT,
      );
    } else setDate(currentDate);

    if (endTime) {
      const [endHour, endMinutes] = getTimeByParts(endTime);

      activeTask.value.deadlineLast = format(
        setMinutes(setHours(currentDate, endHour), endMinutes),
        TASK_TIME_FORMAT,
      );
    } else activeTask.value.deadlineLast = undefined;

    if (reminderTime) {
      const [reminderHour, reminderMinutes] = getTimeByParts(reminderTime);
      activeTask.value.timeNotification = format(
        setMinutes(setHours(currentDate, reminderHour), reminderMinutes),
        TASK_TIME_FORMAT,
      );
    } else activeTask.value.timeNotification = undefined;

    await updateActiveTask();
  }

  const createMediaFile = async (uploadedFiles: File[]) => {
    markChanged();
    if (!uploadedFiles.length) return;

    const groupedFiles = uploadedFiles.reduce(
      (groupedFiles: Record<string, File[]>, file) => {
        const type =
          filesTypesMapping[
            file.type.split('/')[0] as keyof typeof filesTypesMapping
          ] || 'ATTACHMENT';
        if (!groupedFiles[type]) groupedFiles[type] = [];
        groupedFiles[type].push(file);
        return groupedFiles;
      },
      {},
    );

    for await (const group of Object.keys(groupedFiles)) {
      const formData = new FormData();
      formData.append('fileType', group);
      if (secureTaskId.value)
        formData.append('secureTaskId', secureTaskId.value);

      groupedFiles[group].forEach((file) => {
        formData.append('files', file);
      });

      try {
        setState({ isFileLoading: true });
        const { data: files } = await servicesList[
          currentService.value
        ].files.add(formData);
        activeTask.value?.attachedFileIds?.push(...files);

        await getFiles(activeTask.value, secureTaskId.value);
        await updateActiveTask();
      } catch (e) {
        setState({ isFileLoading: false });
        ElNotification.error({
          message: t('some_error'),
          offset: 80,
        });
        console.error(e);
      }
    }
  };

  const updateTask = async (
    task: ICreateTaskRequest,
    taskId?: number,
  ): Promise<Task | void> => {
    if (!taskId) return;
    try {
      const { data } = await servicesList[currentService.value].base.updateTask(
        task,
        currentService.value === 'task' ? taskId : secureTaskId.value,
      );
      return data;
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
    }
  };

  const toggleLoadingStatus = (val: boolean) => {
    setState({ isFileLoading: val, isTaskLoading: val, isAudioLoading: val });
  };

  const getAudioFiles = async (id: number, secureTaskId?: string) => {
    const { data } = await servicesList[currentService.value].files.getFileById(
      id,
      secureTaskId,
    );
    audioFile.value = URL.createObjectURL(data);
  };

  const getMediaFiles = async (
    id: number,
    secureTaskId?: string,
    typeFile?: string,
    name?: string,
  ) => {
    const { data } = await servicesList[currentService.value].files.getFileById(
      id,
      secureTaskId,
    );
    const link = URL.createObjectURL(data);
    files.value.push({
      id,
      link,
      typeFile: typeFile || '',
      name: name || '',
    });
  };

  const getFiles = async (data: Partial<Task>, secureTaskId?: string) => {
    const requests: Promise<void>[] = [];

    if (
      data.audioFileId &&
      Object.keys(data.audioFileId).length &&
      audioFile.value === null
    ) {
      setState({ isAudioLoading: true });
      requests.push(getAudioFiles(data.audioFileId.id, secureTaskId));
    }

    if (data.attachedFileIds?.length) {
      setState({ isFileLoading: true });
      data.attachedFileIds
        .filter((item) => !files.value.find((f) => f.id === item.id))
        .forEach((item) => {
          requests.push(
            getMediaFiles(
              item.id,
              secureTaskId,
              item.fileType,
              item.originalFileName || item.fileName,
            ),
          );
        });
    }
    await Promise.all(requests);
    setState({ isFileLoading: false, isAudioLoading: false });
  };

  const getTaskById = async (
    id: number | string | null,
    taskCompanyId?: number,
  ) => {
    if (!id) return;
    toggleLoadingStatus(true);
    openModal();
    const { data } = (await servicesList[currentService.value].base.getTaskById(
      String(id),
    )) as AxiosResponse<Task>;

    const isAllDayTask = isAllDayDate(data.deadline);

    const companyId =
      taskCompanyId ||
      (state.value.isShared
        ? data.companyId
        : userData.value?.currentCompany) ||
      0;
    data.deadline = isAllDayTask
      ? data.deadline
      : stringDateToUTC(data.deadline, {
          initialDateUTC0: true,
          timeZone: CURRENT_TIME_ZONE,
        }) || '';

    data.deadlineLast = stringDateToUTC(data.deadlineLast, {
      initialDateUTC0: true,
      timeZone: CURRENT_TIME_ZONE,
    });
    data.timeNotification = stringDateToUTC(data.timeNotification, {
      initialDateUTC0: true,
      timeZone: CURRENT_TIME_ZONE,
    });
    originalActiveTask.value = { ...activeTask.value, ...data, companyId };
    activeTask.value = { ...activeTask.value, ...data, companyId };
    setState({ isTaskLoading: false });
    if (route.query.showComments) {
      openComments();
      setTimeout(async () => {
        await getComments();
        await router.replace({ query: {} });
      }, 500);
    }
    await getFiles(data, id as string);
    initChanged();
  };

  const getShareTask = async (id: string) => {
    currentService.value = 'share';
    secureTaskId.value = id;
    setState({ isShared: true });
    try {
      await getTaskById(id);
    } catch (e: unknown) {
      console.error(e);

      if (!isAxiosError(e)) return;
      const options: Partial<TaskState> = { isTaskLoading: true };
      if (e.request.status === 404) options.invalidSharedLink = true;
      else options.hasSharedAccess = false;
      setState(options);
    }
  };

  const createComment = async (text: string) => {
    if (activeTask.value.id) {
      await commentsStore.createComment(
        currentService.value === 'task'
          ? activeTask.value.id
          : secureTaskId.value,
        text,
      );
    }
  };

  const openComments = () => setState({ isShowComments: true });
  const closeComments = () => setState({ isShowComments: false });

  const getComments = async (page?: number) => {
    if (activeTask.value.id) {
      await commentsStore.getComments(
        currentService.value === 'task'
          ? activeTask.value.id
          : secureTaskId.value,
        page,
        currentService.value,
      );
    }
  };

  const clearComments = () => {
    closeComments();
    commentsStore.resetComments();
  };

  const updateTaskStatus = async (status: string) => {
    if (activeTask.value.id) {
      const { data } = await servicesList[
        currentService.value
      ].base.updateTaskStatus(
        currentService.value === 'task'
          ? activeTask.value.id
          : secureTaskId.value,
        status,
      );
      activeTask.value = data;
      taskStore.updateTask(data);
    }
  };

  const deleteFile = async (id: number | string) => {
    const index = files.value.findIndex((item) => item.id === id);

    await services.files.deleteFileById(id as number);

    if (index > -1) {
      files.value.splice(index, 1);
    }

    const activeIndex = activeTask.value.attachedFileIds?.findIndex(
      (item) => item.id === id,
    );
    if (activeIndex !== undefined && activeIndex > -1) {
      activeTask.value?.attachedFileIds?.splice(activeIndex, 1);
    }
  };

  const saveTask = async () => {
    const task = await updateTask(formattedActiveTask(), activeTask.value.id);
    if (task) {
      formatTaskTimeToUTC(task);
      task.companyId = activeTask.value.companyId || 0;
      updateEvent.trigger({
        task,
        prevTask: originalActiveTask.value as Task,
        companyId: activeTask.value.companyId || 0,
      });
    }
    await taskStore.getTodoCount();
    ElNotification.success(t('tasks.task_update'));
    if (currentService.value === 'task') await taskStore.fetch();
  };

  const clear = async () => {
    audioFile.value = null;
    resetState();

    if (hasChanges.value) await saveTask();

    originalActiveTask.value = undefined;
    currentService.value = 'task';
    secureTaskId.value = '';
    files.value = [];
    resetChanged();
    activeTask.value = initialData();
    clearComments();
  };

  return {
    getTaskById,
    clear,
    isReadonly,
    activeTask,
    files,
    audioFile,
    hasChanges,
    getDateView,
    setDate,
    setTime,
    updateTask,
    openComments,
    closeComments,
    createComment,
    clearComments,
    getComments,
    updateTaskStatus,
    getShareTask,
    deleteFile,
    createMediaFile,
    setActiveTaskFields,
    state,
    setState,
    resetState,
    datePickerData,
    openModal,
    secureTaskId,
    onUpdateTask: updateEvent.on,
  };
});
