import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "subscription__info"
}
const _hoisted_2 = { class: "subscription__info-price" }

import { computed } from 'vue';
import { useCurrencyConvert } from '@/hooks/useCurrencyConvert';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionInfo',
  setup(__props) {

const billingStore = useTariffStore();

const { tariffsView } = storeToRefs(billingStore);

const { convertPriceWithLanguagePartials } = useCurrencyConvert();

const convertPrice = computed(() => {
  const price = convertPriceWithLanguagePartials(
    tariffsView.value.content.price || 0,
    tariffsView.value.content.currency || 'USD',
  );
  const integer = price.find((item) => item.type === 'integer')?.value;
  const decimal = price.find((item) => item.type === 'decimal')?.value;
  const fraction = price.find((item) => item.type === 'fraction')?.value;

  return {
    price: `${integer}${decimal}${fraction}`,
    currency: price.find((item) => item.type === 'currency')?.value || '',
  };
});

return (_ctx: any,_cache: any) => {
  return (convertPrice.value.price)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(convertPrice.value.price) + _toDisplayString(convertPrice.value.currency) + " /" + _toDisplayString(_unref(tariffsView).content.typeRefresh || ''), 1),
        _createElementVNode("span", {
          class: _normalizeClass([
        'subscription__info-renewal',
        _unref(tariffsView)?.autoRenewal ? 'green-text' : 'danger-text',
      ])
        }, _toDisplayString(_unref(tariffsView)?.autoRenewal ? 'automatic renewal' : 'plan cancelled'), 3)
      ]))
    : _createCommentVNode("", true)
}
}

})