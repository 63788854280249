<template>
  <el-dialog
    :model-value="state.isOpenModal"
    :class="[
      `modal-view modal`,
      state.isShared && 'modal__short',
      state.isShowComments && 'modal__full',
    ]"
    :modal-class="['el-overlay', !isClosable && 'short'].join(' ')"
    :close-on-click-modal="isClosable"
    :close-on-press-escape="isClosable"
    :modal="isClosable"
    @close="viewTask.clear"
  >
    <template #header>
      <ModalHeaderViewTask />
    </template>
    <template #default>
      <ModalBodyViewTask />
    </template>
    <template #footer>
      <ModalFooterViewTask :is-readonly="isReadonly" />
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import ModalBodyViewTask from '@/components/modals/Tasks/ModalViewTask/ModalBodyViewTask.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import ModalFooterViewTask from './ModalFooterViewTask.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { CoachName } from '@/types/coaches';
import ModalHeaderViewTask from './header/ModalHeaderViewTask.vue';
import { useUserStore } from '@/stores/user';

const viewTask = useViewTaskStore();

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);
const cashedCoach = ref<CoachName | null>(null);

const { state, isReadonly } = storeToRefs(viewTask);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const isClosable = computed(() => !!userData.value || !state.value.isShared);
watch(
  () => state.value.isOpenModal,
  (value) => {
    if (value) {
      cashedCoach.value = activeCoach.value.name;
      setCoach(state.value.isShared ? 'sharedTask' : 'viewTask');
    } else {
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);
</script>

<style lang="scss" scoped>
.modal-view {
  &__form {
    &-group {
      margin-bottom: 2.4rem;
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 0 3rem;
    }

    &-col {
      width: 50%;
    }

    &-input {
      cursor: pointer;
    }

    &-autocomplete {
      position: relative;
    }
  }

  &__picker {
    width: 100%;

    &-input {
      width: 100%;
    }
  }

  &__area {
    height: 20rem;
  }

  &__priority {
    background-color: #fcfcfc;
    display: flex;
    align-items: center;
    padding: 0.7rem 1.2rem;
    max-width: 27.5rem;
    border-radius: 0.8rem;

    &-title {
      margin-right: 1.2rem;
      @include f14;
    }
  }

  &__footer {
    display: flex;
    gap: 0 3.5rem;

    &-col {
      width: calc(50% - 3.5rem);
      flex-grow: 1;
    }
  }

  &__submit {
    width: 100%;
  }
}

.modal {
  height: max-content;

  &__minimize {
    width: 2.4rem;
    height: 2.4rem;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 3.2rem;

    &-line {
      display: inline-block;
      border-radius: 0.8rem;
      width: 1.75rem;
      height: 0.2rem;
      background: #1a2a39;
    }
  }

  &__body {
    overflow-y: scroll;
    flex-grow: 1;
    padding: 3rem 3rem;
  }
}
</style>
