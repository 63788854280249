import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/create-actions/task.svg'
import _imports_1 from '@images/create-actions/note.svg'


const _hoisted_1 = { class: "actions" }

import { useNewTaskStore } from '@/stores/newTask';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutAsideCreateActions',
  emits: ['closeSidebar'],
  setup(__props) {

const newTask = useNewTaskStore();
const createNoteStore = useCreateNoteStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "actions__item",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {
        _unref(newTask).isModalOpen = true;
        _ctx.$emit('closeSidebar');
      })
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1),
      _createTextVNode(" Task ")
    ])),
    _createElementVNode("button", {
      class: "actions__item",
      onClick: _cache[1] || (_cache[1] = ($event: any) => {
        _unref(createNoteStore).start();
        _ctx.$emit('closeSidebar');
      })
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('notes.one')), 1)
    ])
  ]))
}
}

})