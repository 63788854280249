import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import IconMarker from '@/components/icon/IconMarker.vue';
import { computed } from 'vue';
import { Workspace } from '@/types/workspaces';

type Props = { workspace: Workspace };

export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleAccountSettingsWorkspace',
  props: {
    workspace: {}
  },
  setup(__props: any) {

const props = __props;

const syncState = computed(() =>
  props.workspace.id
    ? { value: true, color: 'var(--color-success)', text: 'Sync on' }
    : { value: false, color: '#1A2A39', text: 'Sync off' },
);

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", {
    class: "calendar-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSyncChange', !syncState.value.value)))
  }, [
    _createVNode(IconMarker, {
      color: _ctx.workspace.color
    }, null, 8, ["color"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.workspace.name), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_el_switch, {
        class: "green",
        modelValue: syncState.value.value
      }, null, 8, ["modelValue"]),
      _createElementVNode("span", {
        style: _normalizeStyle({ color: syncState.value.color })
      }, _toDisplayString(syncState.value.text), 5)
    ])
  ]))
}
}

})