import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "user-dropdown"
}
const _hoisted_2 = { class: "user-dropdown-header with-border" }
const _hoisted_3 = { class: "user-dropdown-top" }
const _hoisted_4 = { class: "user-dropdown-name" }
const _hoisted_5 = { class: "user-dropdown-plan" }
const _hoisted_6 = { class: "user-dropdown-main with-border" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "user-dropdown-sub-item"
}
const _hoisted_9 = { class: "user-dropdown-footer" }
const _hoisted_10 = ["onClick"]

import { type Component, computed, markRaw, ref } from 'vue';
import UserChangeLang from '@/components/user/UserChangeLang.vue';
import IconChevron from '@/components/icon/IconChevron.vue';

import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import SubscriptionInfo from '@/components/user/SubscriptionInfo.vue';
import SupportSubComponent from '@/components/user/SupportSubComponent.vue';
import MarketLinks from '@/components/market-links/MarketLinks.vue';
import UserPolitics from '@/components/user/UserPolitics.vue';

enum MenuTypes {
  COMPONENT = 'component',
  LINK = 'link',
  SLOT = 'slot',
}

interface MenuItem {
  id: number;
  title: string;
  type: MenuTypes;
  component?: Component;
  to?: string;
  subItem?: Component;
  func?: () => void;
  disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDropDownMenu',
  emits: ['onClose', 'openFeedback', 'openContact'],
  setup(__props, { emit: __emit }) {

const userStore = useUserStore();
const billingStore = useTariffStore();

const { tariffs } = storeToRefs(billingStore);

const emit = __emit;

const { t } = useI18n();

const router = useRouter();

const selectedComponent = ref<Component | null>(null);

const menuList = computed<{ [key: string]: Array<MenuItem> }>(() => {
  return {
    main: [
      // {
      //   id: 1,
      //   title: t('userDropDownMenu.lang'),
      //   type: MenuTypes.COMPONENT,
      //   component: UserChangeLang,
      // },
      {
        id: 2,
        title: t('userDropDownMenu.upgrade_plan'),
        type: MenuTypes.LINK,
        to: '/settings/profile/billing',
        subItem: markRaw(SubscriptionInfo),
      },
      {
        id: 3,
        title: t('userDropDownMenu.settings'),
        type: MenuTypes.LINK,
        to: '/settings',
      },
      {
        id: 4,
        title: t('userDropDownMenu.support'),
        type: MenuTypes.LINK,
        subItem: markRaw(SupportSubComponent),
        disabled: true,
      },
    ],
    footer: [
      {
        id: 1,
        title: '',
        type: MenuTypes.SLOT,
        subItem: markRaw(MarketLinks),
      },
      {
        id: 1,
        title: '',
        type: MenuTypes.SLOT,
        subItem: markRaw(UserPolitics),
      },
      {
        id: 2,
        title: t('userDropDownMenu.log_out'),
        type: MenuTypes.LINK,
        to: '/logout',
      },
    ],
  };
});

const fullName = computed(() => {
  return `${userStore?.userData?.firstName} ${userStore.userData?.lastName}`;
});

const handleClick = (item: MenuItem) => {
  switch (item.type) {
    case MenuTypes.COMPONENT:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedComponent.value = item.component!;
      break;
    case MenuTypes.LINK:
      router.push(item.to as string);
      emit('onClose');
      break;
  }
};

const hideComponent = () => {
  selectedComponent.value = null;
};

const openModal = (modal: string) => {
  switch (modal) {
    case 'contact':
      emit('openContact');
      break;
    case 'feedback':
      emit('openFeedback');
      break;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in",
    appear: ""
  }, {
    default: _withCtx(() => [
      (selectedComponent.value)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(selectedComponent.value), {
            key: 0,
            onOnClose: hideComponent,
            onOnMenuClose: _cache[0] || (_cache[0] = ($event: any) => {
        emit('onClose');
        hideComponent();
      })
          }, null, 32))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(UiAvatar, {
                "avatar-link": _unref(userStore).userData?.avatar?.uuidLink,
                "fallback-name": fullName.value
              }, null, 8, ["avatar-link", "fallback-name"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(fullName.value), 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(tariffs)?.tariff?.title), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value.main, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(['user-dropdown-item', { disabled: item.disabled }]),
                    onClick: ($event: any) => (handleClick(item))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(item.title), 1),
                    (item.type === MenuTypes.COMPONENT)
                      ? (_openBlock(), _createBlock(IconChevron, {
                          key: 0,
                          direction: "right"
                        }))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_7),
                  (item.subItem)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.subItem), { onOpenModal: openModal }, null, 32))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value.footer, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass([
            'user-dropdown-item',
            { 'user-dropdown-slot': item.type === MenuTypes.SLOT },
          ]),
                  key: item.id,
                  onClick: ($event: any) => (handleClick(item))
                }, [
                  _createElementVNode("span", null, _toDisplayString(item.title), 1),
                  (item.type === MenuTypes.COMPONENT)
                    ? (_openBlock(), _createBlock(IconChevron, {
                        key: 0,
                        direction: "right"
                      }))
                    : _createCommentVNode("", true),
                  (item.type === MenuTypes.SLOT)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.subItem), { key: 1 }))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_10))
              }), 128))
            ])
          ]))
    ]),
    _: 1
  }))
}
}

})