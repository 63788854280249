import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import services from '@/api/services';
import { NotificationHistory } from '@/types/common';
import { Pageable } from '@/types/pagination';

import { useDebounceFn } from '@vueuse/core';

import { useViewTaskStore } from '@/stores/viewTask/viewTask';

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications: Ref<Pageable<NotificationHistory> | null> = ref(null);
  const notificationsList = ref<Array<NotificationHistory>>([]);
  const page = ref(1);
  const notificationTypeIds = ref<Array<number>>([]);
  const onlyUnread = ref(true);
  const isLoadingNotifications = ref(false);
  const searchNotification = ref('');
  const countIsNotRead = ref(0);
  const isWidgetShow = ref(false);

  const viewTask = useViewTaskStore();

  const debouncedFetch = useDebounceFn(
    async () => {
      resetNotifications();
      await getNotifications();
    },
    500,
    { maxWait: 5000 },
  );

  const getNotifications = async () => {
    isLoadingNotifications.value = true;
    const { data } = await services.notifications.notificationHistory({
      notificationTypeIds: notificationTypeIds.value,
      filter: searchNotification.value,
      onlyUnread: onlyUnread.value,
      pageNum: page.value,
      pageSize: 10,
    });

    notifications.value = data;
    setNotificationList(data.content);
    isLoadingNotifications.value = false;
  };

  const getNextPageNotifications = async () => {
    if (notifications.value && page.value < notifications?.value?.totalPages) {
      page.value += 1;
      await getNotifications();
    }
  };

  const setNotificationList = (items: Array<NotificationHistory>) => {
    notificationsList.value.push(...items);
  };

  const resetNotifications = () => {
    notificationsList.value = [];
    page.value = 1;
  };

  const readNotifications = async (notifId: number, taskId: number | null) => {
    await Promise.all([
      readNotification(notifId),
      viewTask.getTaskById(taskId),
    ]);
  };

  const readNotification = async (id: number) => {
    await services.notifications.readNotification(id);
    await getCountIsNotRead();
    const notificationsIndex = notificationsList.value.findIndex(
      (item) => item.notificationHistory.id === id,
    );

    if (notificationsIndex > -1) {
      notificationsList.value.splice(notificationsIndex, 1);
      if (
        notifications?.value?.totalElements &&
        notifications?.value?.totalElements > 0
      ) {
        notifications.value.totalElements -= 1;
      }
    }
  };

  const getCountIsNotRead = async () => {
    const { data } = await services.notifications.countIsNotRead();
    countIsNotRead.value = data;
  };

  const readAllNotifications = async () => {
    if (countIsNotRead.value > 0) {
      await services.notifications.readAllNotifications();
      notificationsList.value = [];
      page.value = 1;
      await getNotifications();
      await getCountIsNotRead();
    }
  };

  const deleteAllNotifications = async () => {
    await services.notifications.deleteAllNotifications();
    notificationsList.value = [];
    page.value = 1;
    await getNotifications();
    await getCountIsNotRead();
  };

  watch(
    () => [
      searchNotification.value,
      notificationTypeIds.value,
      onlyUnread.value,
    ],
    debouncedFetch,
  );

  return {
    notifications,
    getNotifications,
    notificationsList,
    getNextPageNotifications,
    readNotifications,
    onlyUnread,
    isLoadingNotifications,
    searchNotification,
    notificationTypeIds,
    countIsNotRead,
    getCountIsNotRead,
    readAllNotifications,
    deleteAllNotifications,
    isWidgetShow,
    resetNotifications,
  };
});
