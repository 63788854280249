import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "note-transform-step-card__header" }
const _hoisted_2 = {
  key: 0,
  class: "note-transform-step-card__footer"
}

import IconClose from '@/components/icon/IconClose.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformStepCard',
  props: {
    full: { type: Boolean, default: false },
    stretch: { type: Boolean, default: false }
  },
  setup(__props: any) {



const notesTransformStore = useNotesTransformStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["note-transform-step-card__wrapper", { full: _ctx.full, stretch: _ctx.stretch }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["note-transform-step-card", { full: _ctx.full }])
    }, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(notesTransformStore).cancel && _unref(notesTransformStore).cancel(...args))),
        class: "note-transform-step-card__close"
      }, [
        _createVNode(IconClose)
      ]),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})