import { ComputedRef, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CalendarRangeOptions } from '@/types/calendar';
import { AxiosResponse } from 'axios';
import {
  DayTaskCountsType,
  TaskDTO,
  TaskHighlight,
  TaskHighlightRequestParams,
} from '@/types/tasks';
import { createHighLight, getHighLightColor } from '@/utils/calendar';

export const useCalendarWorkload = (
  getMonthTasks: (
    payload: TaskHighlightRequestParams,
  ) => Promise<AxiosResponse<Record<string, DayTaskCountsType>> | undefined>,
  payload: ComputedRef<{ taskDTO?: Partial<TaskDTO>; companyId?: number }>,
) => {
  const { t } = useI18n();

  const attributes = ref<TaskHighlight[]>([]);
  const pending = ref(false);

  const updateCalendar = async (range?: CalendarRangeOptions[]) => {
    if (!range) {
      const dates = JSON.parse(localStorage.calendarRange || false);
      const month = new Date(dates.start)?.getMonth() + 1;
      const year = new Date(dates.start)?.getFullYear();
      range = [
        {
          month: month || new Date().getMonth() + 1,
          year: year || new Date().getFullYear(),
        },
      ];
    }

    try {
      pending.value = true;
      const data = (
        await getMonthTasks(
          Object.assign({
            ...payload.value,
            month: range[0].month,
            year: range[0].year,
          }),
        )
      )?.data;
      if (!data) return;
      setCalendarAttributes(data);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const setCalendarAttributes = (data: Record<string, DayTaskCountsType>) => {
    attributes.value = [];
    Object.keys(data).forEach((day) => {
      const { listOfTask, isVisible } = data[day];
      const count = Object.values(listOfTask).reduce(
        (counter: number, countByPriority: number) => counter + countByPriority,
        0,
      );

      if (count > 0) {
        const highLight = createHighLight(
          getHighLightColor(count, isVisible),
          day,
          `${t(
            `calendar.${!isVisible ? 'closed-tasks-' : ''}popover_label`,
          )} - ${count}`,
        );
        attributes.value.push(highLight);
      }
    });
  };

  return { attributes, updateCalendar, pending };
};
