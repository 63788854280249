import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import NoteTransformStepCard from '@/components/notes/transform/steps/NoteTransformStepCard.vue';
import { Vue3Lottie } from 'vue3-lottie';
import DndAnimation1 from '@/animation/dnd-1.json';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { onBeforeMount } from 'vue';
import { useNotesByWorkspacesStore } from '@/stores/notes/useNotesByWorkspaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformStepPrepare',
  setup(__props) {

const notesTransformStore = useNotesTransformStore();
const notesByWorkspacesStore = useNotesByWorkspacesStore();

onBeforeMount(async () => {
  await notesByWorkspacesStore.start(notesTransformStore.initialNotes);
  setTimeout(() => {
    if (notesTransformStore.currentStep === 'prepare') {
      notesTransformStore.goToNextStep();
    }
  }, 1000);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(NoteTransformStepCard, null, {
    header: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('notes.transform.prepare')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(Vue3Lottie), {
        width: 710,
        height: 275,
        "animation-data": _unref(DndAnimation1)
      }, null, 8, ["animation-data"])
    ]),
    _: 1
  }))
}
}

})