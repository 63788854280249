import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ModalNotification from '@/components/modals/ModalNotification.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalConfirmGoogle',
  props: {
    isOpen: { type: Boolean },
    message: {},
    title: {}
  },
  emits: ['close'],
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalNotification, _mergeProps({ ...props, ..._ctx.$attrs }, {
    class: "notification-modal",
    onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    width: "45rem"
  }), {
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}
}

})