import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modal-view-footer__content" }
const _hoisted_2 = { class: "modal-view-footer__comments" }
const _hoisted_3 = ["src"]

import UiAudioPlayer from '@/components/ui/UIAudioPlayer.vue';
import ModalFooterControl from '@/components/modals/Tasks/ModalViewTask/ModalStatusControl.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalFooterViewTask',
  props: {
    isReadonly: { type: Boolean, default: false }
  },
  setup(__props: any) {



const viewTask = useViewTaskStore();

const commentText = ref('');

const commentInput = ref();

const sendComment = async (e: any) => {
  if (isMessageSend.value) return;

  if (commentText.value) {
    isMessageSend.value = true;

    if (e) e.target?.blur();

    await viewTask.createComment(commentText.value);
    commentText.value = '';
    await viewTask.getComments(1);

    const element = document.getElementById('comments_list');

    if (element) {
      element.scrollBy({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    }

    isMessageSend.value = false;
  }
};

const isBlockSendComment = computed(() => {
  return activeTask.value.taskStatus === 'CLOSED';
});

const isMessageSend = ref(false);

const { audioFile, activeTask, state } = storeToRefs(viewTask);

return (_ctx: any,_cache: any) => {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ divided: _unref(state).isShowComments }, "modal-view-footer"])
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (_unref(audioFile))
        ? (_openBlock(), _createBlock(UiAudioPlayer, {
            key: 0,
            audio: _unref(audioFile)
          }, null, 8, ["audio"]))
        : _createCommentVNode("", true),
      _createVNode(ModalFooterControl, { "is-readonly": _ctx.isReadonly }, null, 8, ["is-readonly"])
    ])), [
      [_directive_skeleton, _unref(state).isAudioLoading]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ui_input, {
        ref_key: "commentInput",
        ref: commentInput,
        modelValue: commentText.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((commentText).value = $event)),
        placeholder: _ctx.$t('view_modal_task.send_comments'),
        readonly: isBlockSendComment.value || isMessageSend.value,
        class: "modal-view-footer__input",
        onKeydown: _withKeys(_withModifiers(sendComment, ["exact","stop","prevent"]), ["enter"])
      }, {
        action: _withCtx(() => [
          (!isMessageSend.value)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass([{ 'modal-view-footer__send-disabled': isBlockSendComment.value }, "modal-view-footer__send"]),
                src: require(`@images/pictogram/send.svg`),
                alt: "send",
                onClick: sendComment
              }, null, 10, _hoisted_3))
            : (_openBlock(), _createBlock(IconSpinner, {
                key: 1,
                "circle-color": "gray",
                class: "modal-view-footer__loading",
                color: "gray"
              }))
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder", "readonly", "onKeydown"])
    ])
  ], 2))
}
}

})