export const POPPER_MODIFIERS_DEFAULT = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['left', 'right'],
      },
    },
  ],
};

export const POPPER_MODIFIERS_VERTICAL = {
  placement: 'bottom',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom', 'top'],
      },
    },
  ],
};

export const POPPER_MODIFIERS_HORIZONTAL = {
  placement: 'right-start',
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['right-start', 'left-start'],
      },
    },
  ],
};
