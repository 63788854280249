<script setup lang="ts">
import UiButton from '@/components/control/UiButton.vue';
import { useSharedTasksStore } from '@/stores/sharingHistory/sharedTasks';
import { ref } from 'vue';
import { isAxiosError } from 'axios';

type Props = { isOpen?: boolean; id: string };
const props = defineProps<Props>();

const sharedTasks = useSharedTasksStore();

const isRequestSent = ref(false);
const isRequestAlreadySent = ref(false);

const onRequest = async () => {
  const response = await sharedTasks.requestAccess(props.id);
  console.log(response);

  if (isAxiosError(response) && response.response?.status === 400)
    return (isRequestAlreadySent.value = true);
  isRequestSent.value = true;
};
</script>

<template>
  <el-dialog
    :model-value="isOpen"
    @close="$emit('close')"
    v-bind="$attrs"
    modal-class="modal-notification-medium"
  >
    <template #header>
      <p>🙁</p>
      <p>Access to the task is closed or expired.</p>
    </template>
    Request access and work with the task using the link.
    <template #footer>
      <ui-button @click="onRequest">Request access</ui-button>
    </template>
  </el-dialog>
  <el-dialog
    :model-value="isRequestSent"
    @close="
      isRequestSent = false;
      $emit('close');
    "
    v-bind="$attrs"
    modal-class="modal-notification-medium"
  >
    <template #header>The request was sent!</template>
    <img src="@images/shared-tasks/success.webp" width="255" alt="" />
  </el-dialog>
  <el-dialog
    :model-value="isRequestAlreadySent"
    @close="
      isRequestAlreadySent = false;
      $emit('close');
    "
    v-bind="$attrs"
    modal-class="modal-notification-medium"
  >
    <img src="@images/shared-tasks/clocks.svg" alt="" />
    <h2 style>The request has already been sent</h2>
    <span>Contact the task owner or check status later.</span>
  </el-dialog>
</template>

<style scoped lang="scss"></style>
