import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notification-modal__btns" }

import { useI18n } from 'vue-i18n';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { ElNotification } from 'element-plus';
import UiButton from '@/components/control/UiButton.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { toRefs } from 'vue';
import { ISubordinate } from '@/types/subordinate';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDeleteUser',
  props: {
    isOpen: { type: Boolean },
    user: {}
  },
  emits: ['on-close', 'on-delete'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const { removeSubordinateFromCompany } = useSubordinatesStore();

const props = __props;

const emits = __emit;

const { user } = toRefs(props);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const onDeleteUser = async () => {
  try {
    if (!userData?.value?.currentCompany || !user.value?.id) return;
    await removeSubordinateFromCompany({
      userId: user.value.id,
      companyId: userData.value.currentCompany,
    });
    ElNotification.success({
      message: t('popups.user_view.delete_user-success'),
      offset: 80,
    });
  } catch (e) {
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    emits('on-delete');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalNotification, {
    title: `${_ctx.$t('popups.user_view.delete_user')}?`,
    class: "notification-modal",
    "is-open": _ctx.isOpen,
    message: _ctx.$t('popups.user_view.delete_user_message'),
    width: "30rem",
    onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(UiButton, {
          plain: "",
          onClick: onDeleteUser
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('popups.user_view.delete_btn')), 1)
          ]),
          _: 1
        }),
        _createVNode(UiButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('popups.user_view.cancel_btn')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title", "is-open", "message"]))
}
}

})