import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "autocomplete"
}
const _hoisted_2 = { class: "autocomplete__drop" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "autocomplete__no-matches"
}

import { computed } from 'vue';
import { SubordinateViewType } from '@/utils/subordinates';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAutocomplete',
  props: {
    items: {},
    modelValue: {},
    isOpen: { type: Boolean }
  },
  emits: ['update:modelValue', 'selectItem'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    return emit('update:modelValue', value);
  },
});

const onSelectItem = (item: (typeof props.items)[number]) => {
  emit('selectItem', item);
};

return (_ctx: any,_cache: any) => {
  const _component_ui_input = _resolveComponent("ui-input")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (props.isOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", { class: "autocomplete__header" }),
            _createVNode(_component_ui_input, {
              label: _ctx.$t('search'),
              class: "autocomplete__control",
              modelValue: model.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
              icon: "search"
            }, null, 8, ["label", "modelValue"]),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "autocomplete__drop-item",
                  key: item.id,
                  onClick: ($event: any) => (onSelectItem(item))
                }, [
                  _renderSlot(_ctx.$slots, "default", { item: item })
                ], 8, _hoisted_3))
              }), 128)),
              (!_ctx.items.length && model.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('no_matches')), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})