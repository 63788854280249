import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "note-task-card-modal-footer" }
const _hoisted_2 = { class: "note-task-card-modal-footer__player" }

import UiAudioPlayer from '@/components/ui/UIAudioPlayer.vue';
import { storeToRefs } from 'pinia';
import { useNoteToTaskStore } from '@/stores/notes/useNoteToTask';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformTaskFooter',
  emits: ["schedule"],
  setup(__props) {



const noteToTaskStore = useNoteToTaskStore();
const { audioFile, isAudioLoading, isCreating, isStateValid } =
  storeToRefs(noteToTaskStore);

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      (_unref(audioFile))
        ? (_openBlock(), _createBlock(UiAudioPlayer, {
            key: 0,
            audio: _unref(audioFile)
          }, null, 8, ["audio"]))
        : _createCommentVNode("", true)
    ])), [
      [_directive_skeleton, _unref(isAudioLoading)]
    ]),
    _createVNode(_component_ui_button, {
      onClick: _unref(noteToTaskStore).end,
      "is-disabled": !_unref(isStateValid),
      "is-loading": _unref(isCreating),
      class: "note-task-card-modal-footer__schedule"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Create ")
      ])),
      _: 1
    }, 8, ["onClick", "is-disabled", "is-loading"])
  ]))
}
}

})