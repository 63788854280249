import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/logo.svg'


const _hoisted_1 = { class: "layout-header" }

import UiDropdownLang from '@/components/ui/UiDropdown/UiDropdownLang.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutHeader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      class: "layout-header__logo",
      alt: ""
    }, null, -1)),
    _createVNode(UiDropdownLang)
  ]))
}
}

})