import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "22",
  height: "18",
  viewBox: "0 0 22 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconCloud',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M11 13.4999V6.74992M11 6.74992L14 9.74992M11 6.74992L8.00001 9.74992M5.75001 16.4999C4.67944 16.5011 3.64353 16.1205 2.82831 15.4266C2.01309 14.7327 1.47196 13.7709 1.30212 12.7138C1.13227 11.6568 1.34484 10.5739 1.90163 9.65951C2.45842 8.74513 3.32297 8.05921 4.34001 7.72492C4.07871 6.38609 4.34877 4.99812 5.09299 3.85494C5.83722 2.71175 6.99718 1.90312 8.32723 1.60027C9.65727 1.29743 11.053 1.52415 12.2188 2.23244C13.3846 2.94072 14.2289 4.07494 14.573 5.39492C15.105 5.2219 15.6748 5.20108 16.218 5.33482C16.7612 5.46856 17.2562 5.75153 17.6471 6.15176C18.038 6.552 18.3092 7.05356 18.43 7.59979C18.5509 8.14602 18.5166 8.71516 18.331 9.24292C19.1497 9.55562 19.833 10.145 20.2626 10.9089C20.6921 11.6727 20.8407 12.5628 20.6826 13.4248C20.5245 14.2868 20.0697 15.0662 19.3971 15.6279C18.7244 16.1896 17.8763 16.4981 17 16.4999H5.75001Z",
      stroke: __props.color,
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ]))
}
}

})