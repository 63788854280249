import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "player__container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "player__time" }

import { ref, nextTick } from 'vue';
import { useAVWaveform } from 'vue-audio-visual';
import { formatSeconds } from '@/utils/formatSeconds';
import IconPlay from '@/components/icon/IconPlay.vue';
import IconPause from '@/components/icon/IconPause.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UIAudioPlayer',
  props: {
    audio: {}
  },
  setup(__props: any) {

const props = __props;

const isPlaying = ref(false);

const player = ref<HTMLAudioElement>();
const canvas = ref(null);
const timeValue = ref('0');

const handleEvent = () => {
  if (isPlaying.value) {
    pause();
  } else {
    play();
  }
};

const play = () => {
  timeValue.value = formatSeconds(player.value?.currentTime);
  player.value?.play();
  isPlaying.value = true;
};

const pause = () => {
  player.value?.pause();
  isPlaying.value = false;
};

useAVWaveform(player, canvas, {
  src: props.audio,
  canvHeight: 40,
  canvWidth: 250,
  playtime: false,
  noplayedLineColor: 'grey',
});

nextTick(() => {
  player.value?.load();
  player.value?.addEventListener('ended', () => {
    pause();
    player.value?.load();
    timeValue.value = formatSeconds(player.value?.duration);
  });

  player.value?.addEventListener('timeupdate', () => {
    timeValue.value = formatSeconds(player.value?.currentTime);
  });

  player.value?.addEventListener('canplay', () => {
    //Dirty hack for Chrome later check another
    // const _player = new Audio(props.audio);
    const _player = document.createElement('audio');
    const sourceElement = document.createElement('source');

    _player.append(sourceElement);

    sourceElement.src = props.audio;
    sourceElement.type = 'audio/mp3';
    _player.addEventListener(
      'durationchange',
      function (e) {
        if (this.duration != Infinity) {
          const duration = this.duration;
          _player.remove();
          timeValue.value = formatSeconds(duration);
        }
      },
      false,
    );
    _player.load();
    _player.currentTime = 24 * 60 * 60;
    _player.volume = 0;
    _player.play();
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: handleEvent,
      class: "player__button"
    }, [
      (!isPlaying.value)
        ? (_openBlock(), _createBlock(IconPlay, {
            key: 0,
            class: "player__icon"
          }))
        : (_openBlock(), _createBlock(IconPause, {
            key: 1,
            class: "player__icon"
          }))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("audio", {
        ref_key: "player",
        ref: player,
        class: "hidden"
      }, [
        _createElementVNode("source", {
          src: _ctx.audio,
          type: "audio/mp3"
        }, null, 8, _hoisted_2)
      ], 512),
      _createElementVNode("canvas", {
        ref_key: "canvas",
        ref: canvas,
        style: {"width":"100%"}
      }, null, 512)
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(timeValue.value), 1)
  ]))
}
}

})