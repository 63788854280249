import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "note-transform-calendar-note" }
const _hoisted_2 = { class: "note-transform-calendar-note__title" }
const _hoisted_3 = {
  key: 0,
  class: "note-transform-calendar-note__description"
}

import IconClose from '@/components/icon/IconClose.vue';
import { Note } from '@/types/notes';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformCalendarNote',
  props: {
    data: {},
    color: {}
  },
  emits: ["close"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "note-transform-calendar-note__wrapper",
    style: _normalizeStyle({ '--color': _ctx.color || 'gray' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', _ctx.data.id)))
      }, [
        _createVNode(IconClose)
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.data.title), 1),
      (_ctx.data.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.data.description), 1))
        : _createCommentVNode("", true)
    ])
  ], 4))
}
}

})