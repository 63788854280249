import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/logo.svg'
import _imports_1 from '@images/app-markets/apple-btn-small.svg'
import _imports_2 from '@images/app-markets/google-btn-small-mono.svg'
import _imports_3 from '@images/wave-bg.svg'
import _imports_4 from '@images/logo-icon.svg'


import ModalViewTask from '@/components/modals/Tasks/ModalViewTask/ModalViewTask.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import CoachActive from '@/components/coaches/CoachActive.vue';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { useUserStore } from '@/stores/user';
import { useRoute, useRouter } from 'vue-router';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import ModalHasNoAccess from '@/components/modals/Tasks/ModalSharedTask/ModalHasNoAccess.vue';
import ModalInvalidLink from '@/components/modals/Tasks/ModalSharedTask/ModalInvalidLink.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'unsigned',
  setup(__props) {

const viewTask = useViewTaskStore();
const { state } = storeToRefs(viewTask);

const coachesStore = useCoachesStore();
const { disableCoaches } = coachesStore;

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const route = useRoute();
const router = useRouter();

onMounted(() => {
  viewTask.setState({ isShared: true });

  if (userData.value) {
    disableCoaches();
    return router.push({
      name: 'Home',
      query: { uuid: route.params.uuid },
    });
  }
  if (route.params.uuid) viewTask.getShareTask(route.params.uuid as string);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createStaticVNode("<header class=\"layout-header\" data-v-73f7b6b6><img src=\"" + _imports_0 + "\" class=\"layout-header__logo\" alt=\"\" data-v-73f7b6b6><div class=\"layout-header__right layout-header__right--mobile\" data-v-73f7b6b6><p data-v-73f7b6b6>Try Voiset for free</p><a href=\"https://apps.apple.com/us/app/voiset/id6446641085\" data-v-73f7b6b6><img src=\"" + _imports_1 + "\" alt=\"Voiset app for Apple devices\" data-v-73f7b6b6></a><a href=\"https://play.google.com/store/apps/details?id=com.unionsmarttechnology.voiset\" data-v-73f7b6b6><img src=\"" + _imports_2 + "\" alt=\"Voiset app for Android devices\" data-v-73f7b6b6></a></div></header>", 1)),
    (_unref(state).isTaskLoading)
      ? (_openBlock(), _createBlock(UiSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (!_unref(state).isTaskLoading)
          ? (_openBlock(), _createBlock(CoachActive, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(ModalViewTask),
    _createVNode(ModalHasNoAccess, {
      isOpen: !_unref(state).hasSharedAccess,
      id: _unref(viewTask).secureTaskId,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    }, null, 8, ["isOpen", "id"]),
    _createVNode(ModalInvalidLink, {
      isOpen: _unref(state).invalidSharedLink,
      "close-on-click-modal": false,
      "close-on-press-escape": false
    }, null, 8, ["isOpen"]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "page" }, [
      _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }),
      _createElementVNode("footer", { class: "layout-footer" }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _imports_4,
            alt: "Voiset logo"
          }),
          _createElementVNode("p", null, "Powered by Voiset")
        ])
      ])
    ], -1))
  ], 64))
}
}

})