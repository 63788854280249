import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/abstract.svg'


import AiAnimation from '@/animation/ai-generation.json';
import { Vue3Lottie } from 'vue3-lottie';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformGenerateAnimation',
  props: {
    height: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "note-transform-generate-animation",
    style: _normalizeStyle({ height: _ctx.height })
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createVNode(_unref(Vue3Lottie), { "animation-data": _unref(AiAnimation) }, null, 8, ["animation-data"])
  ], 4))
}
}

})