import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@images/dots.svg'


const _hoisted_1 = {
  key: 0,
  class: "note-item-controls__back"
}
const _hoisted_2 = {
  key: 1,
  class: "note-item-controls__done"
}
const _hoisted_3 = {
  key: 2,
  class: "note-item-controls__divider"
}
const _hoisted_4 = {
  key: 3,
  class: "note-item-controls__schedule"
}

import IconDone from '@/components/icon/IconDone.vue';
import IconAI from '@/components/icon/IconAI.vue';
import IconBack from '@/components/icon/IconBack.vue';

export type NoteActionType = 'back' | 'done' | 'schedule';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteItemControls',
  props: {
    isCompleted: { type: Boolean }
  },
  emits: ["action"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    "popper-class": "note-item-controls",
    class: "note-item-controls",
    offset: 6,
    trigger: "click",
    placement: "bottom-end",
    width: "230"
  }, {
    reference: _withCtx(() => [
      _renderSlot(_ctx.$slots, "trigger", {}, () => [
        _cache[3] || (_cache[3] = _createElementVNode("button", {
          class: _normalizeClass(['note-item-controls__trigger'])
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ], -1))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("ul", null, [
        (_ctx.isCompleted)
          ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', 'back')))
              }, [
                _createVNode(IconBack),
                _renderSlot(_ctx.$slots, "back", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t('notes.action.to_uncompleted')), 1)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action', 'done')))
              }, [
                _createVNode(IconDone),
                _renderSlot(_ctx.$slots, "done", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t('notes.action.to_completed')), 1)
                ])
              ])
            ])),
        (!_ctx.isCompleted)
          ? (_openBlock(), _createElementBlock("li", _hoisted_3))
          : _createCommentVNode("", true),
        (!_ctx.isCompleted)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('action', 'schedule')))
              }, [
                _createVNode(IconAI),
                _renderSlot(_ctx.$slots, "schedule", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t('notes.schedule.one')), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}
}

})