import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-dropdown" }

import { onMounted, ref } from 'vue';

type Props = { isInitialOpen?: boolean; static?: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownExpandable',
  props: {
    isInitialOpen: { type: Boolean, default: false },
    static: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const isOpen = ref(false);

onMounted(() => (isOpen.value = props.isInitialOpen));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "ui-dropdown__label",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.static && (isOpen.value = !isOpen.value)))
    }, [
      _renderSlot(_ctx.$slots, "label", { isOpen: isOpen.value })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['ui-dropdown__container', { open: _ctx.static || isOpen.value }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
}

})