import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@images/success.svg'


const _hoisted_1 = {
  key: 0,
  class: "change-plan-modal__title"
}
const _hoisted_2 = {
  key: 0,
  class: "change-plan-modal__notification"
}
const _hoisted_3 = {
  key: 1,
  class: "change-plan-modal__notification"
}
const _hoisted_4 = {
  key: 2,
  class: "change-plan-modal__content"
}
const _hoisted_5 = { class: "change-plan-modal__btns" }

import UiButton from '@/components/control/UiButton.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import UiPlanTitle from '@/components/ui/UiPlan/UiPlanTitle.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useStripe } from '@/hooks/useStripe';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { sleep } from '@/utils/common';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalPlanChange',
  props: {
    isOpen: { type: Boolean },
    paymentId: {}
  },
  emits: ['close', 'close-with-success', 'close-with-fail'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const subscribePending = ref(false);
const isSubscribeSuccess = ref(false);

const { t } = useI18n();
const billingStore = useTariffStore();
const { tariffsView } = storeToRefs(billingStore);

const { onSubscribe } = useStripe();

const onChangePlan = async () => {
  if (!props.paymentId) return;
  try {
    subscribePending.value = true;
    isSubscribeSuccess.value = !!(await onSubscribe(props.paymentId)).data;
    await sleep(1000);
    await billingStore.fetchUserTariff();
  } catch (e) {
    ElNotification.error({
      message: t('some_error'),
      offset: 80,
    });
    emits('close-with-fail');
    console.error(e);
  } finally {
    subscribePending.value = false;
  }
};

const onClose = async () => {
  setTimeout(() => (isSubscribeSuccess.value = false), 1000);
  emits(isSubscribeSuccess.value ? 'close-with-success' : 'close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalNotification, {
    width: "45rem",
    "is-open": _ctx.isOpen,
    message: _ctx.$t('billings.change_subscription.message'),
    onOnClose: onClose,
    "no-btns": isSubscribeSuccess.value
  }, {
    header: _withCtx(() => [
      (!isSubscribeSuccess.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.$t('billings.change_subscription.title')) + " ", 1),
            _createVNode(UiPlanTitle, {
              size: "medium",
              type: _unref(tariffsView).content.name,
              color: "#778BEE"
            }, null, 8, ["type"])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "blur",
        appear: "",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (subscribePending.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(UiSpinner, { style: {"height":"6rem"} }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('billings.change_subscription.message-processed')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('billings.change_subscription.message-precessed-note')), 1)
              ]))
            : (!subscribePending.value && isSubscribeSuccess.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    alt: "",
                    style: {"height":"6rem"}
                  }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('billings.change_subscription.message-success')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('billings.change_subscription.message-success-note')), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('billings.change_subscription.message')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('billings.change_subscription.message-note')), 1)
                ]))
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(UiButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          plain: "",
          "is-disabled": subscribePending.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('billings.change_subscription.back')), 1)
          ]),
          _: 1
        }, 8, ["is-disabled"]),
        _createVNode(UiButton, {
          "is-disabled": subscribePending.value,
          onClick: onChangePlan
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('billings.change_subscription.change')), 1)
          ]),
          _: 1
        }, 8, ["is-disabled"])
      ])
    ]),
    _: 1
  }, 8, ["is-open", "message", "no-btns"]))
}
}

})