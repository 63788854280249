<script lang="ts" setup>
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import HeaderActions from './HeaderActions.vue';
import HeaderInfo from './HeaderInfo.vue';
import { storeToRefs } from 'pinia';
import HeaderWorkspaceSelect from './HeaderWorkspaceSelect.vue';

const viewTask = useViewTaskStore();
const { activeTask, isReadonly, state } = storeToRefs(viewTask);
</script>

<template>
  <div v-if="state.isShared">Shared task</div>
  <HeaderInfo
    v-else
    :is-loading="state.isTaskLoading"
    :is-readonly="isReadonly"
    :number="activeTask.itemNumber"
    :status="activeTask.taskStatus"
  >
    <HeaderWorkspaceSelect
      :company-id="activeTask.companyId"
      @select="activeTask.companyId = $event"
    />
  </HeaderInfo>
  <HeaderActions />
</template>
