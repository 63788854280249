import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, Transition as _Transition, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "workspaces-edit__header" }
const _hoisted_2 = { class: "workspaces-edit__input-actions" }
const _hoisted_3 = { class: "workspaces-edit__footer" }

import { computed, ref, watch } from 'vue';
import UiButton from '@/components/control/UiButton.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { Workspace } from '@/types/workspaces';
import IconMarker from '@/components/icon/IconMarker.vue';
import UiInputUnderscore from '@/components/control/UiInputUnderscore.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import ModalDeleteWorkspace from '@/components/modals/Workspaces/ModalDeleteWorkspace.vue';

type Props = {
  isOpen?: boolean;
  list: Workspace[];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalEditWorkspaces',
  props: {
    isOpen: { type: Boolean },
    list: {}
  },
  emits: ['close'],
  setup(__props: any) {

const props = __props;


const { t } = useI18n();

const workspacesStore = useWorkspaceStore();
const { fetchList, deleteCompany, modify } = workspacesStore;

const workspaces = ref<InstanceType<typeof UiInputUnderscore>[]>([]);
const isDeleteVisible = ref(false);
const pending = ref(false);
const editList = ref<(Workspace & { dirty: boolean; readonly: boolean })[]>([]);
const currentId = ref<number | null>(null);

const isSaveDisabled = computed(
  () =>
    pending.value ||
    !editList.value.some((w) => w.dirty) ||
    editList.value.some((w) => w.name.length > 30),
);

const onStartEdit = (i: number) => {
  editList.value[i].readonly = false;
};

const onDeleteWorkspace = async () => {
  if (!currentId.value) return;
  try {
    isDeleteVisible.value = false;
    pending.value = true;
    await deleteCompany(currentId.value);
    await fetchList();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

const onSaveChanges = async () => {
  try {
    const dirtyEditList = editList.value.filter((w) => w.dirty);
    await Promise.allSettled(dirtyEditList.map((w) => modify(w)));
    await fetchList();
    ElNotification.success({
      message: t('workspaces.edit.saved_msg'),
      offset: 80,
    });
  } catch (e) {
    ElNotification.error({ message: t('some_error'), offset: 80 });
    console.error(e);
  } finally {
    pending.value = false;
  }
};

const onNameUpdate = (name: string, i: number) => {
  editList.value[i].name = name;
  editList.value[i].dirty = name !== props.list[i].name;
};

const setEditList = () =>
  (editList.value = props.list.map((w) => ({
    ...w,
    dirty: false,
    readonly: false,
  })));

watch(() => props.list, setEditList);

watch(
  () => props.isOpen,
  (isOpen) => isOpen && setEditList(),
);

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      "model-value": _ctx.isOpen,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      width: "45rem"
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('workspaces.edit.title')), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(UiButton, {
            "is-loading": pending.value,
            "is-disabled": isSaveDisabled.value,
            onClick: onSaveChanges
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('workspaces.edit.save')), 1)
            ]),
            _: 1
          }, 8, ["is-loading", "is-disabled"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_Transition, {
          name: "blur",
          appear: "",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['workspaces-edit', { pending: pending.value }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(editList.value, (workspace, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "workspaces-edit__row",
                  key: i
                }, [
                  _createVNode(IconMarker, {
                    color: workspace.color
                  }, null, 8, ["color"]),
                  _createVNode(UiInputUnderscore, {
                    placeholder: _ctx.$t('workspaces.edit.placeholder'),
                    onClick: ($event: any) => (onStartEdit(i)),
                    onBlur: ($event: any) => (
              editList.value[i].name.length <= 50 && (editList.value[i].readonly = true)
            ),
                    class: _normalizeClass([
              'workspaces-edit__input',
              { error: editList.value[i].name.length > 50 },
            ]),
                    "model-value": editList.value[i].name,
                    "onUpdate:modelValue": ($event: any) => (onNameUpdate($event, i)),
                    readonly: workspace.readonly,
                    small: "",
                    "readonly-no-underscore": "",
                    pictogram: false,
                    ref_for: true,
                    ref_key: "workspaces",
                    ref: workspaces
                  }, _createSlots({ _: 2 }, [
                    (!workspace.externalCalendarItem)
                      ? {
                          name: "action",
                          fn: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(IconTrash, {
                                color: "#EC7975",
                                onClick: _withModifiers(($event: any) => {
                    currentId.value = workspace.id;
                    isDeleteVisible.value = true;
                  }, ["stop"])
                              }, null, 8, ["onClick"])
                            ])
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["placeholder", "onClick", "onBlur", "class", "model-value", "onUpdate:modelValue", "readonly"])
                ]))
              }), 128))
            ], 2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(ModalDeleteWorkspace, {
      "is-open": isDeleteVisible.value,
      title: _ctx.$t('workspaces.delete.title'),
      message: _ctx.$t('workspaces.delete.message'),
      onClose: _cache[1] || (_cache[1] = ($event: any) => {
      isDeleteVisible.value = false;
      currentId.value = null;
    }),
      onOnConfirm: onDeleteWorkspace
    }, null, 8, ["is-open", "title", "message"])
  ], 64))
}
}

})