<template>
  <transition-group name="blur" appear>
    <div class="shared-access__layout" v-if="isOpen" key="1" />
    <div class="shared-access" v-if="isOpen" key="2">
      <div class="shared-access__title">
        <h3 class="purple-text">Request to work with the task via the link</h3>
        <span>
          User {{ requestingUserName }} requests access to work with the "<span
            class="purple-text"
          >
            {{ current.taskInfo.name }}
          </span>
          " task.
        </span>
      </div>
      <div class="shared-access__content">
        <h3>{{ current.taskInfo.name }}</h3>
        <span>{{ current.taskInfo.taskContent }}</span>
        <div class="shared-access__details">
          <div>
            <span>Status</span>
            <UiPictoStatus :status="current.taskInfo.taskStatus" />
          </div>
          <div>
            <span>Date & Time</span>
            {{ date }}
          </div>
          <div>
            <span>Priority</span>
            <UiColorfilledText :type="current.taskInfo.taskPriority" badge>
              {{ $t(`task_priority.${current.taskInfo.taskPriority}`) }}
            </UiColorfilledText>
          </div>
          <div>
            <span>Creator</span>
            <div>
              {{ creatorName }}
              <UiAvatar
                size="3rem"
                :avatarLink="current.creator.avatar.uuidLink"
                :fallbackName="creatorName"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="shared-access__btns">
        <ui-button
          plain
          @click="onSetRequestStatus(false)"
          :is-disabled="pending"
        >
          {{ $t('workspaces.accept.reject') }}
        </ui-button>
        <ui-button @click="onSetRequestStatus(true)" :is-disabled="pending">
          {{ $t('workspaces.accept.accept') }}
        </ui-button>
      </div>
    </div>
  </transition-group>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { concatName } from '@/utils/text';
import { storeToRefs } from 'pinia';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useSharedTasksStore } from '@/stores/sharingHistory/sharedTasks';
import UiPictoStatus from '@/components/text/UiPictoStatus.vue';
import { formatDateToCurrentUTC, isAllDayDate } from '@/utils/time';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { format, isValid } from 'date-fns';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';

const { t } = useI18n();
const sharedTasks = useSharedTasksStore();
const { changeVisibility } = sharedTasks;
const { requests, sharedTasksView } = storeToRefs(sharedTasks);

const { formatWithLocale } = useCurrentLanguage();

const pending = ref(false);
const isOpen = computed(() => requests.value.length > 0);
const current = computed(() => requests.value[requests.value.length - 1]);

const date = computed(() => {
  if (!current.value) return '';
  const utcDateRaw = formatDateToCurrentUTC(current.value.taskInfo.deadline);
  const utcDate = new Date(utcDateRaw);
  let dayView = formatWithLocale(utcDate, 'dd MMMM yyyy');
  if (!isAllDayDate(utcDateRaw)) {
    dayView += ` | ${format(utcDate, 'HH:mm')}`;
    const end = new Date(
      formatDateToCurrentUTC(current.value.taskInfo.deadlineLast),
    );
    if (isValid(end)) dayView += ` - ${format(end, 'HH:mm')}`;
  }
  return dayView;
});

const requestingUserName = computed(
  () =>
    concatName(
      current.value?.userInfo.firstName,
      current.value?.userInfo.lastName,
    ) || 'guest',
);

const creatorName = computed(() =>
  concatName(current.value?.creator.firstName, current.value?.creator.lastName),
);

const switchToNextRequest = () => requests.value.pop();
const onSetRequestStatus = async (isAccepted: boolean) => {
  try {
    pending.value = true;
    await changeVisibility({
      linkId: current.value.shareId,
      availableToOpen: isAccepted,
    });
    switchToNextRequest();
    if (!requests.value.length && sharedTasksView.value?.content.length)
      await sharedTasks.fetch();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};
</script>

<style scoped lang="scss">
.shared-access {
  padding: 2rem;
  width: 42rem;
  background: #fcfcfc;
  border-radius: 1.2rem;
  box-shadow: 1rem 1rem 4rem 0 rgba(66, 66, 66, 0.25);
  position: fixed;
  right: 5rem;
  top: 10rem;
  z-index: 1000;

  &__layout {
    position: fixed;
    inset: 0;
    z-index: 1000;
  }

  &__title {
    text-align: left;
    @include f14;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #dde3ec;

    & > h3 {
      @include f16;
    }
  }

  &__content {
    margin-top: 1rem;
    @include flex-column(1rem);

    & > h3 {
      @include f16;
      text-align: left;
    }

    & > span {
      @include f14;
      line-height: 1.8;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__details {
    @include flex-column(1rem);

    & > div {
      @include f14;
      @include flex-row(1rem);
      justify-content: space-between;
      align-items: center;

      & > div {
        @include flex-row(1rem);
        align-items: center;
      }

      & > span {
        color: var(--color-disabled);
      }
    }
  }

  &__btns {
    margin-top: 2rem;
    @include flex-row(1rem);

    & > * {
      width: 100%;
    }
  }
}
</style>
