import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "notes-transform-step-calendar__workspace" }
const _hoisted_2 = { class: "notes-transform-step-calendar__workspace-name" }
const _hoisted_3 = { class: "notes-transform-step-calendar" }
const _hoisted_4 = { class: "notes-transform-step-calendar__controls" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

import NoteTransformStepCard from '@/components/notes/transform/steps/NoteTransformStepCard.vue';
import IconAI2 from '@/components/icon/IconAI2.vue';
import NoteTransformCalendar from '@/components/notes/transform/calendar/NoteTransformCalendar.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { storeToRefs } from 'pinia';
import { useNotesByCalendar } from '@/stores/notes/useNotesByCalendar';
import { onBeforeMount, onUnmounted, ref } from 'vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformStepCalendar',
  setup(__props) {

const notesTransformStore = useNotesTransformStore();
const { initialNotes, planners, mode } = storeToRefs(notesTransformStore);
const notesByCalendarStore = useNotesByCalendar();
const { isLastWorkspace, currentGroup, isAllLoading } =
  storeToRefs(notesByCalendarStore);

onBeforeMount(() => {
  notesByCalendarStore.start(planners.value, initialNotes.value);
});

onUnmounted(() => {
  notesByCalendarStore.end();
});

const isEnding = ref(false);

const handleEnd = async () => {
  if (isLastWorkspace.value) {
    isEnding.value = true;
    notesTransformStore.planners = await notesByCalendarStore.end();
    isEnding.value = false;
    notesTransformStore.goToNextStep();
  } else {
    notesByCalendarStore.nextWorkspace();
  }
};

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(NoteTransformStepCard, { stretch: "" }, {
    header: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(`notes.transform.calendar.title.${_unref(mode)}`)), 1),
      _createElementVNode("p", _hoisted_1, [
        _cache[0] || (_cache[0] = _createTextVNode(" Workspace: ")),
        _createElementVNode("span", {
          class: "notes-transform-step-calendar__workspace-badge",
          style: _normalizeStyle({
            'background-color': _unref(currentGroup)?.workspace?.color || 'gray',
          })
        }, null, 4),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(currentGroup)?.workspace.name), 1)
      ]),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t(`notes.transform.calendar.description.${_unref(mode)}`)), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ui_button, {
          onClick: handleEnd,
          "is-disabled": _unref(isAllLoading) || isEnding.value
        }, {
          default: _withCtx(() => [
            (_unref(isLastWorkspace))
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createVNode(IconAI2),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('notes.schedule.many')), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('notes.transform.calendar.next')), 1))
          ]),
          _: 1
        }, 8, ["is-disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (!_unref(isAllLoading) && !isEnding.value)
              ? (_openBlock(), _createBlock(NoteTransformCalendar, { key: 0 }))
              : (_openBlock(), _createBlock(UiSpinner, { key: 1 }))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})