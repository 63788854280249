<template>
  <div class="player" id="note-record">
    <template v-if="audio.recordFileRuntime">
      <div class="player__timer">{{ playerTime }}</div>
      <button class="player__record-btn" @click="isPlaying ? pause() : play()">
        <img src="@images/record/record-template.webp" alt="" />
        <IconPause class="player__control" v-if="isPlaying" />
        <IconPlay class="player__control" v-else />
      </button>
    </template>
    <template v-else>
      <div class="player__timer">{{ currentRecordTime }}</div>
      <el-tooltip
        :content="recordTooltip"
        effect="customized"
        placement="top-start"
      >
        <button
          :disabled="isLoadingAudio"
          class="player__record-btn"
          @click="startRecord"
        >
          <transition name="blur" appear mode="out-in">
            <img
              src="@images/record/record-active.webp"
              class="player__record-active"
              alt=""
              v-if="audio.isRecording"
            />
            <img src="@images/record/record-template.webp" alt="" v-else />
          </transition>
          <div class="player__loading" v-if="isLoadingAudio">
            <icon-spinner circleColor="#ffffff" color="#ffffff" />
          </div>
          <icon-microphone v-else />
        </button>
      </el-tooltip>
    </template>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, toRef, watch } from 'vue';
import IconPlay from '@/components/icon/IconPlay.vue';
import IconPause from '@/components/icon/IconPause.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';
import IconMicrophone from '@/components/icon/IconMicrophone.vue';
import { useI18n } from 'vue-i18n';
import { sleep } from '@/utils/common';
import services, { sockets } from '@/api/services';
import { VoiceKeyResponse } from '@/types/voice';
import { watchDebounced } from '@vueuse/core';
import { useUserStore } from '@/stores/user';
import { useAudioPlayer } from '@/hooks/useAudioPlayer';
import { formatSeconds } from '@/utils/formatSeconds';

type Props = {
  audio: {
    recordFileRuntime: string | null;
    recordTime: number | null;
    isRecording: boolean;
    recordError: boolean;
    runtimeSpeech: string;
    blobData?: Blob;
    stopRecording: () => Promise<void>;
    startRecording: () => Promise<void>;
  };
};

const props = defineProps<Props>();
const emits = defineEmits(['onStopRecord', 'onStartRecord']);

const audio = toRef(props, 'audio');

const { t } = useI18n();

const { initAudioPlayer, play, pause, isPlaying, playerTime } =
  useAudioPlayer();

const userStore = useUserStore();

const isLoadingAudio = ref(false);
const voiceKey = ref<VoiceKeyResponse>();

const currentRecordTime = computed(() => {
  const seconds = Number(audio.value.recordTime || 0);
  return formatSeconds(seconds);
});

const onStopRecord = async () => {
  isLoadingAudio.value = true;
  await sleep(1500);
  isLoadingAudio.value = false;

  audio.value.stopRecording?.();
  sockets.speech.close();
  voiceKey.value?.id &&
    (await services.voice.finishRecord(
      voiceKey.value.id,
      Math.round((audio.value.recordTime || 0) * 1000),
    ));

  voiceKey.value = (
    await services.voice.getVoiceApiKey(userStore.userData?.locale.id)
  ).data;

  emits('onStopRecord');
};

watchDebounced(
  () => [audio.value.runtimeSpeech, audio.value.isRecording],
  () => audio.value.isRecording && onStopRecord(),
  { debounce: 5000 },
);

const startRecord = async () => {
  if (audio.value.isRecording) return await onStopRecord();
  emits('onStartRecord');

  try {
    isLoadingAudio.value = true;
    voiceKey.value &&
      (await sockets.speech.connect({
        token: voiceKey.value.voiceToken.uuid,
      }));
    await audio.value.startRecording?.();
  } catch (e) {
    console.error(e);
    // if (e.response?.status === 406) audio.value.recordError = true;
  } finally {
    isLoadingAudio.value = false;
  }
};

watch(
  () => audio.value.blobData,
  (val) => sockets.speech.socketInstance.send(val),
);
watch(
  () => audio.value.recordFileRuntime,
  () => initAudioPlayer(audio.value.recordFileRuntime),
);

const recordTooltip = computed(() =>
  audio.value.recordError
    ? t('create_modal_task.record_error')
    : t('create_modal_task.record'),
);

onMounted(
  async () =>
    (voiceKey.value = (
      await services.voice.getVoiceApiKey(userStore.userData?.locale.id)
    ).data),
);
</script>
<style lang="scss" scoped>
.player {
  @include flex-column;
  align-items: center;
  height: inherit;

  &__record {
    &-btn {
      position: relative;

      & > svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 3.4rem;
        height: 3.4rem;

        &.player__control {
          top: 48%;
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &-active {
      animation: spin 7s ease-in-out infinite;
    }
  }

  &__timer {
    @include f14;
    height: 0.5rem;
  }

  &__loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & > svg {
      animation: spin 1s linear infinite;
      width: 3.4rem;
      height: 3.4rem;
    }
  }
}
</style>
