import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-politics" }


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPolitics',
  props: {
    purpleText: { type: Boolean },
    fullNames: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "https://www.voiset.io/privacy-policy",
      class: _normalizeClass({ 'purple-text': _ctx.purpleText }),
      target: "_blank"
    }, _toDisplayString(_ctx.$t(`auth.privacy_policy${_ctx.fullNames ? '-full' : ''}`)), 3),
    _cache[0] || (_cache[0] = _createTextVNode(" " + _toDisplayString('  •  ') + " ")),
    _createElementVNode("a", {
      href: "https://www.voiset.io/term-of-use",
      class: _normalizeClass({ 'purple-text': _ctx.purpleText }),
      target: "_blank"
    }, _toDisplayString(_ctx.$t(`auth.terms${_ctx.fullNames ? '-full' : ''}`)), 3)
  ]))
}
}

})