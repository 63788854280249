import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-tariff__popular" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { computed } from 'vue';
import { TARIFF_POPULAR_TAB_THEME } from '@/consts/themes';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPlanPopular',
  props: {
    theme: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "48805a79": (colors.value.background),
  "42bdc738": (colors.value.color)
}))

const props = __props;
const colors = computed(() => TARIFF_POPULAR_TAB_THEME[props.theme]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require(`@images/tariff-card/heart-${colors.value.img}.svg`),
      alt: ""
    }, null, 8, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(_ctx.$t('card_tariff.popular')) + " ", 1),
    _createElementVNode("img", {
      src: require(`@images/tariff-card/like-${colors.value.img}.svg`),
      alt: ""
    }, null, 8, _hoisted_3)
  ]))
}
}

})