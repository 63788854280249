import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "lang-recognition" }
const _hoisted_2 = { class: "lang-recognition__header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "lang-recognition__reference"
}
const _hoisted_5 = { class: "lang-recognition__current" }
const _hoisted_6 = { class: "lang-recognition__desc" }

import { computed, onMounted, ref, watch } from 'vue';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'LangRecognitionSelector',
  setup(__props) {

const { t } = useI18n();

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const { voiceRecognitionList, finishLoad } = storeToRefs(settingsStore);

const searchLang = ref('');
const list = ref<HTMLDivElement>();
const pending = ref(false);
const isListVisible = ref(false);

watch(
  () => searchLang.value,
  () => list.value?.scrollTo({ behavior: 'smooth', top: 0 }),
);

const availableList = computed(() =>
  [...voiceRecognitionList.value].sort((a, b) =>
    a.name.toLowerCase().includes(searchLang.value) &&
    !b.name.toLowerCase().includes(searchLang.value)
      ? -1
      : 0,
  ),
);

const selectVoiceRecognition = (lang: string) => {
  pending.value = true;
  try {
    isListVisible.value = false;
    settingsStore.selectVoiceRecognition = lang;
    settingsStore.saveVoiceRecognition();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

onMounted(async () => {
  await settingsStore.getLocaleVoiceData();
  settingsStore.finishLoad = true;
});

return (_ctx: any,_cache: any) => {
  const _component_ui_input = _resolveComponent("ui-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    visible: isListVisible.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((isListVisible).value = $event)),
    width: "fit-content",
    trigger: "click"
  }, {
    reference: _withCtx(() => [
      _createVNode(_Transition, {
        name: "blur",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (pending.value)
            ? (_openBlock(), _createBlock(UiSpinner, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(userStore).userData?.locale.name), 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('create_modal_task.select_lange')), 1)
              ]))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('create_modal_task.change_lang')), 1),
        _createVNode(_component_ui_input, {
          modelValue: searchLang.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchLang).value = $event)),
          label: _ctx.$t('search'),
          icon: "search"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_Transition, {
          name: "fade",
          appear: "",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (!_unref(finishLoad))
              ? (_openBlock(), _createBlock(UiSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "lang-recognition__list",
                  ref_key: "list",
                  ref: list
                }, [
                  _createVNode(_TransitionGroup, { name: "fade-group" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableList.value, (lang) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: lang.id,
                          class: _normalizeClass([
                'lang-recognition__list-item',
                { active: _unref(userStore).userData.locale.name === lang.name },
              ]),
                          onClick: ($event: any) => (selectVoiceRecognition(lang.name))
                        }, [
                          _createTextVNode(_toDisplayString(lang.name) + " ", 1),
                          _createVNode(_component_el_radio, {
                            value: true,
                            "model-value": _unref(userStore).userData.locale.name === lang.name
                          }, null, 8, ["model-value"])
                        ], 10, _hoisted_3))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 512))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})