import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import TimePicker from '@/components/time/TimePicker.vue';
import { isBefore } from 'date-fns';

type Props = { startTime?: Date; endTime?: Date; disabled?: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeSelectRange',
  props: {
    startTime: {},
    endTime: {},
    disabled: { type: Boolean }
  },
  emits: ['update:startTime', 'update:endTime'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const onUpdateStartTime = (time?: Date) => {
  if (!time || !props.endTime) return;
  if (isBefore(props.endTime, time)) emits('update:endTime', time);
  emits('update:startTime', time);
};

const onUpdateEndTime = (time?: Date) => {
  if (!time || !props.startTime) return;
  if (isBefore(time, props.startTime)) emits('update:startTime', time);
  emits('update:endTime', time);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['time-range', { disabled: _ctx.disabled }])
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.$t('time.time')), 1),
    _createVNode(TimePicker, {
      placeholder: _ctx.$t('time.start'),
      modelValue: _ctx.startTime,
      "onUpdate:modelValue": onUpdateStartTime
    }, null, 8, ["placeholder", "modelValue"]),
    _cache[0] || (_cache[0] = _createTextVNode(" - ")),
    _createVNode(TimePicker, {
      placeholder: _ctx.$t('time.end'),
      modelValue: _ctx.endTime,
      "onUpdate:modelValue": onUpdateEndTime,
      start: _ctx.startTime
    }, null, 8, ["placeholder", "modelValue", "start"])
  ], 2))
}
}

})