import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke", "stroke-width"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconBadge',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
  strokeWidth: {
    type: Number,
    required: false,
    default: 4,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      cx: "7",
      cy: "7",
      r: "5",
      stroke: __props.color,
      "stroke-width": __props.strokeWidth
    }, null, 8, _hoisted_2)
  ]))
}
}

})