<template>
  <header class="layout-header">
    <img src="@images/logo.svg" class="layout-header__logo" alt="" />
    <div class="layout-header__right layout-header__right--mobile">
      <p>Try Voiset for free</p>
      <a href="https://apps.apple.com/us/app/voiset/id6446641085">
        <img
          src="@images/app-markets/apple-btn-small.svg"
          alt="Voiset app for Apple devices"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.unionsmarttechnology.voiset"
      >
        <img
          src="@images/app-markets/google-btn-small-mono.svg"
          alt="Voiset app for Android devices"
        />
      </a>
    </div>
  </header>
  <UiSpinner v-if="state.isTaskLoading" />
  <transition name="fade" appear>
    <CoachActive v-if="!state.isTaskLoading" />
  </transition>
  <ModalViewTask />
  <ModalHasNoAccess
    :isOpen="!state.hasSharedAccess"
    :id="viewTask.secureTaskId"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  />
  <ModalInvalidLink
    :isOpen="state.invalidSharedLink"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  />
  <div class="page">
    <img src="@images/wave-bg.svg" alt="" />
    <footer class="layout-footer">
      <div>
        <img src="@images/logo-icon.svg" alt="Voiset logo" />
        <p>Powered by Voiset</p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import ModalViewTask from '@/components/modals/Tasks/ModalViewTask/ModalViewTask.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import CoachActive from '@/components/coaches/CoachActive.vue';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { useUserStore } from '@/stores/user';
import { useRoute, useRouter } from 'vue-router';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import ModalHasNoAccess from '@/components/modals/Tasks/ModalSharedTask/ModalHasNoAccess.vue';
import ModalInvalidLink from '@/components/modals/Tasks/ModalSharedTask/ModalInvalidLink.vue';

const viewTask = useViewTaskStore();
const { state } = storeToRefs(viewTask);

const coachesStore = useCoachesStore();
const { disableCoaches } = coachesStore;

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const route = useRoute();
const router = useRouter();

onMounted(() => {
  viewTask.setState({ isShared: true });

  if (userData.value) {
    disableCoaches();
    return router.push({
      name: 'Home',
      query: { uuid: route.params.uuid },
    });
  }
  if (route.params.uuid) viewTask.getShareTask(route.params.uuid as string);
});
</script>

<style scoped lang="scss">
.page {
  position: relative;
  height: 100vh;
  overflow: hidden;

  & > img {
    user-select: none;
    width: 100%;
    height: auto;
  }
}
.layout-footer {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);

  & > div {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    @include f14();
  }
}
.layout-header {
  position: fixed;
  top: 0;
  z-index: 2999;
  width: 100%;
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 4rem;

  &__logo {
    @media (max-width: 640px) {
      width: 8.5rem;
    }
  }

  &__right {
    @include flex-row(2rem);
    align-items: center;

    & > p {
      @include f14;
    }

    @media (max-width: 640px) {
      display: none;
    }

    &--mobile {
      display: none;
      @media (max-width: 640px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (max-width: 640px) {
    padding: 1.6rem;
  }
}
</style>
