<script setup lang="ts"></script>

<template>
  <div class="layout-mobile">
    <div class="layout-mobile__content">
      <h1 class="layout-mobile__title">
        To easily use Voiset on your device, download the mobile app from the
        marketplace
      </h1>
      <div class="layout-mobile__btns">
        <a href="https://apps.apple.com/us/app/voiset/id6446641085">
          <img src="@images/app-markets/apple-btn.webp" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.unionsmarttechnology.voiset"
        >
          <img src="@images/app-markets/google-btn.webp" alt="" />
        </a>
      </div>
      <img src="@images/phones.webp" class="layout-mobile__img" alt="" />
    </div>
    <img src="@images/bg.webp" class="layout-mobile__bg" alt="" />
  </div>
</template>

<style scoped lang="scss">
.layout-mobile {
  display: none;
  position: relative;
  overflow: hidden;
  height: 100vh;

  @include break-point(850px) {
    display: block;
  }

  &__bg {
    position: absolute;
    top: 0;
    object-fit: fill;
    width: 100%;
    height: inherit;
    z-index: -1;
  }

  &__content {
    width: 100%;
    @include flex-column;
    align-items: center;
    margin-top: 15rem;
    padding: 0 5rem;
    height: 100%;

    @include break-point(640px) {
      margin-top: 10rem;
    }
  }

  &__title {
    font-family: 'Unbounded', sans-serif;
    @include f32;
    text-align: center;
    width: inherit;
    max-width: 58.2rem;

    @include break-point(640px) {
      @include f20;
    }
  }

  &__btns {
    @include flex-row(2.4rem);
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    flex: 1;

    & > a {
      > img {
        max-width: 22rem;
        width: 100%;
        box-shadow: 0 1rem 1.6rem 0 rgba(97, 97, 97, 0.06);
        transition: all 0.15s ease-in-out;

        &:hover {
          box-shadow: none;
        }
      }
    }

    @include break-point(640px) {
      @include flex-column(2.4rem);
    }
  }

  &__img {
    width: 100%;
    margin-top: 2rem;
  }
}
</style>
