import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"
import _imports_0 from '@images/logo-icon.svg'


const _hoisted_1 = { class: "google-calendars__row" }
const _hoisted_2 = {
  key: 0,
  class: "google-calendars__list with-scroll"
}

import UiSpinner from '@/components/ui/UiSpinner.vue';
import GoogleAccountSettingsWorkspace from '@/components/integrations/google/settings/GoogleAccountSettingsWorkspace.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { inject, ref, Ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleAccountWorkspacesList',
  setup(__props) {

const workspacesStore = useWorkspaceStore();
const { internalList } = storeToRefs(workspacesStore);

const startWorkspace = inject<Ref<string>>('startWorkspace', ref(''));

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("span", null, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }),
        _createTextVNode(" Voiset workspaces ")
      ], -1)),
      _createVNode(_component_el_switch, { class: "green" })
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "google-calendars__desc" }, " Your Voiset workspaces to be displayed in Google Calendar ", -1)),
    _createVNode(_Transition, {
      name: "blur",
      appear: "",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_unref(internalList).content.list.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_unref(startWorkspace))
                ? (_openBlock(), _createBlock(GoogleAccountSettingsWorkspace, {
                    key: 0,
                    workspace: { ..._unref(internalList).content.list[0], name: _unref(startWorkspace) }
                  }, null, 8, ["workspace"]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(internalList).content.list, (w) => {
                    return (_openBlock(), _createBlock(GoogleAccountSettingsWorkspace, {
                      workspace: w,
                      key: w.id
                    }, null, 8, ["workspace"]))
                  }), 128))
            ]))
          : (_openBlock(), _createBlock(UiSpinner, { key: 1 }))
      ]),
      _: 1
    })
  ]))
}
}

})