import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import UiAutocomplete from '@/components/ui/UiAutocomplete/UiAutocomplete.vue';
import UiAutocompleteUser from '@/components/ui/UiAutocomplete/UiAutocompleteUser.vue';
import { computed } from 'vue';
import { SubordinateViewType } from '@/utils/subordinates';
import { useUserStore } from '@/stores/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAutocompleteUsers',
  props: {
    items: {},
    isOpen: { type: Boolean },
    search: {}
  },
  emits: ['update:modelValue', 'update:search', 'selectItem'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const userStore = useUserStore();
const currentUser = computed(() =>
  props.items.find((user) => user.id === userStore.userData?.id),
);

const filteredList = computed(() => {
  return props.items.filter((item) => {
    if (item.id === userStore.userData?.id) return false;
    if (modelSearch.value) {
      return item.fullName
        .toLocaleLowerCase()
        .includes(modelSearch.value.toLocaleLowerCase());
    }
    return item;
  });
});

const modelSearch = computed<string>({
  get() {
    return props.search;
  },
  set(value) {
    emit('update:search', value);
  },
});

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiAutocomplete, {
    class: "autocomplete-users",
    modelValue: modelSearch.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modelSearch).value = $event)),
    items: filteredList.value,
    "is-open": _ctx.isOpen,
    onSelectItem: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('selectItem', $event)))
  }, {
    header: _withCtx(() => [
      _createVNode(UiAutocompleteUser, {
        item: currentUser.value,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectItem', currentUser.value))),
        style: {"margin-bottom":"1rem"}
      }, null, 8, ["item"])
    ]),
    default: _withCtx(({ item }: { item: SubordinateViewType }) => [
      _createVNode(UiAutocompleteUser, { item: item }, null, 8, ["item"])
    ]),
    _: 1
  }, 8, ["modelValue", "items", "is-open"]))
}
}

})