<script setup lang="ts">
import { priorityOptions } from '@/consts';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import UiUploaderFiles from '@/components/ui/UiUploaderFiles.vue';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';
import { useNoteToTaskStore } from '@/stores/notes/useNoteToTask';
import { useSubordinatesList } from '@/hooks/useSubordinatesList';
import { computed, onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { SubordinateViewType } from '@/utils/subordinates';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';

const { subordinates, fetchSubordinates, getSubordinate, searchFilter } =
  useSubordinatesList();

const noteToTaskStore = useNoteToTaskStore();
const { state, dateView, taskPriority, files } = storeToRefs(noteToTaskStore);

const currentExecutor = computed(() =>
  state.value.executorId ? getSubordinate.value(state.value.executorId) : null,
);

const isOpenAutoComplete = ref(false);

const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

const selectExecutor = (user: SubordinateViewType) => {
  state.value.executorId = user.id;
  isOpenAutoComplete.value = false;
};

onBeforeMount(() => {
  fetchSubordinates();
});
</script>

<template>
  <form class="modal-create__form" @submit.prevent="noteToTaskStore.end()">
    <div class="modal-create__form-row">
      <div
        class="modal-create__form-col modal-create__form-autocomplete"
        v-click-outside="hideAutocomplete"
      >
        <ui-input
          :label="$t('modal_task.to')"
          class="modal-create__form-input"
          :model-value="currentExecutor?.fullName"
          :icon="currentExecutor ? 'user-change' : 'contact'"
          icon-align="right"
          readonly
          @click="isOpenAutoComplete = true"
        />
        <ui-autocomplete-users
          v-if="subordinates?.length"
          :items="subordinates"
          :is-open="isOpenAutoComplete"
          v-model:search="searchFilter"
          @select-item="selectExecutor"
        />
      </div>
      <div class="modal-create__form-col">
        <popup-task-date-picker
          :model-value="state.deadline"
          :endTime="state.deadlineLast || undefined"
          :reminder="state.notifyTime || undefined"
          :date-view="dateView"
          :executor="currentExecutor"
          :disabled="!state.executorId"
          @update:time="noteToTaskStore.setTime($event)"
          @update:modelValue="noteToTaskStore.setDate($event)"
        />
      </div>
    </div>
    <div class="modal-create__form-row">
      <ui-input
        :class="['modal-create__wide-input', { error: state.name.length > 75 }]"
        v-model="state.name"
        :label="$t('modal_task.task_name_placeholder')"
      />
      <ui-dropdown-priority
        class="priority-dropdown modal-create__dropdown"
        v-model="taskPriority"
        :items="priorityOptions"
      />
    </div>
    <ui-input
      :highlighted="!state.taskContent.length"
      :class="[
        'modal-create__area',
        { error: state.taskContent.length > 10_000 },
      ]"
      @update:model-value="state.taskContent = $event"
      :model-value="state.taskContent"
      type="textarea"
      :placeholder="$t('modal_task.description_placeholder')"
      rows="8"
      style="resize: vertical"
      ref="contentInput"
    />
    <ui-uploader-files v-model="files" />
    <input type="submit" hidden />
  </form>
</template>

<style scoped lang="scss">
.modal-create {
  &__form {
    @include flex-column(2rem);
    height: 100%;

    &-row {
      @include flex-row(2rem);
      align-items: center;
    }

    &-col {
      width: 50%;
    }
    &-input {
      cursor: pointer;
    }
    &-autocomplete {
      position: relative;
    }
  }

  &__dropdown {
    max-width: 14rem;
  }

  &__wide-input {
    width: 100%;
  }

  &__reset {
    @include flex-row;
    padding: 1rem;
    @include gray-bg-hover;
    border-radius: 50%;
    cursor: pointer;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    & > svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
