<template>
  <ui-drop-area ref="dropAreaRef">
    <template #default="{ isActiveDropArea }">
      <div class="view-task-files" id="shared-files">
        <div
          :class="[
            'view-task-files__content',
            { 'view-task-files__content--active': isActiveDropArea },
          ]"
          @drop.prevent="handleDrop"
          @dragenter.prevent
          @dragover.prevent
          @dragleave.prevent
        >
          <input
            ref="inputFileRef"
            type="file"
            multiple
            :disabled="isReadonly"
            class="view-task-files__control"
            @change="onChange"
          />
          <h5 class="view-task-files__title">{{ $t('files') }}</h5>
          <div class="view-task-files__list">
            <ui-file
              v-for="(file, i) in files || []"
              :key="file.id"
              :file="file"
              :links-set="linksSet"
              :initial-preview-id="i"
              :is-readonly="isReadonly || viewTask.state.isShared"
              @delete-image="
                deleteImage($event);
                hasChanges = true;
              "
              @upload-image="uploadImage($event.link, $event?.name)"
            />
          </div>
        </div>
      </div>
    </template>
  </ui-drop-area>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import UiFile from '@/components/ui/UiFile.vue';
import { computed, ref } from 'vue';
import UiDropArea from '@/components/ui/UiDropArea.vue';
import { useImageFunctions } from '@/hooks/useImageFunctions';

defineProps<{
  isReadonly?: boolean;
}>();

const dropAreaRef = ref<InstanceType<typeof UiDropArea> | null>(null);

const viewTask = useViewTaskStore();
const { deleteFile, createMediaFile } = viewTask;
const { files, hasChanges } = storeToRefs(viewTask);

const { uploadImage, deleteImage } = useImageFunctions(deleteFile);

const linksSet = computed(() =>
  files.value
    .filter(({ typeFile }) => typeFile === 'PHOTO')
    .map(({ link }) => link),
);

const onChange = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    createMediaFile(Array.from(files));
  }
};

const handleDrop = function (e: DragEvent) {
  const dt = e.dataTransfer;
  if (!dt?.files) return;
  createMediaFile(Array.from(dt.files));

  if (dropAreaRef.value) {
    dropAreaRef.value.setInactiveDropArea();
  }
};
</script>
<style lang="scss" scoped>
.view-task-files {
  width: 100%;
  min-width: 27.5rem;

  &__content {
    background-color: #fcfcfc;
    border: 0.1rem dashed #b8b8d2;
    border-radius: 0.8rem;
    padding-top: 2rem;
    cursor: pointer;
    position: relative;
    min-height: 15rem;

    &--active {
      border-color: var(--color-primary);
    }
  }

  &__control {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__title {
    text-align: center;
    @include f14;
    line-height: 1.1;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    border-radius: 0.8rem;
    row-gap: 4.6rem;
    column-gap: 1.5rem;
    padding: 1rem 1rem 4rem;
  }
}
</style>
