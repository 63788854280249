import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import { computed, ref, watch } from 'vue';
import ModalCreateWorkspaceInvitations from '@/components/modals/Workspaces/Create/ModalCreateWorkspaceInvitations.vue';
import ModalCreateWorkspaceTitle from '@/components/modals/Workspaces/Create/ModalCreateWorkspaceTitle.vue';
import ModalSwitchWorkspace from '@/components/modals/Workspaces/ModalSwitchWorkspace.vue';
import { Workspace } from '@/types/workspaces';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { useNewTaskStore } from '@/stores/newTask';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

type Props = {
  isOpen?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCreateWorkspace',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {


const emits = __emit;
const createdWorkspace = ref<Workspace>();

const invitationsStore = useInvitationsStore();

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const newTask = useNewTaskStore();
const { getSubordinates } = newTask;
const { subordinates, isSubordinatesFetching } = storeToRefs(newTask);

const step = ref(1);

const subordinatesView = computed(
  () => subordinates.value?.filter((s) => s.id !== userData.value?.id) || [],
);

const onClose = async () => {
  await invitationsStore.fetchInvitations();
  setTimeout(() => (step.value = 1), 1000);
  emits('close');
};

watch(
  () => step.value,
  () => step.value === 2 && !isSubordinatesFetching.value && getSubordinates(),
);

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.isOpen,
    onClose: onClose,
    width: "46rem"
  }, {
    default: _withCtx(() => [
      (step.value === 1)
        ? (_openBlock(), _createBlock(ModalCreateWorkspaceTitle, {
            key: 0,
            onCreate: _cache[0] || (_cache[0] = ($event: any) => (createdWorkspace.value = $event)),
            onSetStep: _cache[1] || (_cache[1] = ($event: any) => (step.value = $event))
          }))
        : (step.value === 2)
          ? (_openBlock(), _createBlock(ModalCreateWorkspaceInvitations, {
              key: 1,
              workspace: createdWorkspace.value,
              users: subordinatesView.value,
              onNextStep: _cache[2] || (_cache[2] = ($event: any) => (step.value++))
            }, null, 8, ["workspace", "users"]))
          : (step.value === 3)
            ? (_openBlock(), _createBlock(ModalSwitchWorkspace, {
                key: 2,
                workspace: createdWorkspace.value,
                onClose: onClose
              }, null, 8, ["workspace"]))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})