import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-todo__counter" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "widget-todo__label" }

import { computed, onMounted } from 'vue';
import { useTasksStore } from '@/stores/tasks/tasks';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/stores/workspaces';
import { whenever } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetTodo',
  setup(__props) {

const router = useRouter();
const tasks = useTasksStore();
const { todoTaskCounter } = storeToRefs(tasks);

const workspacesStore = useWorkspaceStore();
const { currentWorkspace } = storeToRefs(workspacesStore);

const { setFilter } = tasks;
const { filters } = storeToRefs(tasks);

whenever(() => currentWorkspace.value?.id, tasks.getTodoCount);

const onTodoClick = () => {
  setFilter('taskFastFilters', 'TO_DO');
  router.push({
    path: '/calendar',
  });
};

const active = computed(
  () => filters.value.taskDTO.taskFastFilters === 'TO_DO',
);

onMounted(async () => await tasks.getTodoCount());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['widget-todo', { active: active.value }]),
    onClick: onTodoClick
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require('@images/mini-clock.svg'),
        alt: ""
      }, null, 8, _hoisted_2),
      _createElementVNode("span", null, _toDisplayString(_unref(todoTaskCounter) || 0), 1)
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('todo')), 1)
  ], 2))
}
}

})