import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@images/cross.svg'


const _hoisted_1 = {
  key: 0,
  class: "ui__price-cross",
  src: _imports_0,
  alt: ""
}
const _hoisted_2 = { class: "ui__price-currency" }
const _hoisted_3 = {
  key: 1,
  class: "ui__price-period"
}

import { computed } from 'vue';
import { useCurrencyConvert } from '@/hooks/useCurrencyConvert';

interface Props {
  price: number;
  currency: string;
  period?: string | null;
  type?: 'small' | 'default';
  color?: string | boolean;
  periodColor?: string;
  crossed?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPrice',
  props: {
    price: {},
    currency: {},
    period: {},
    type: { default: 'default' },
    color: { type: [String, Boolean], default: '#1A2A39' },
    periodColor: { default: '#676e7b' },
    crossed: { type: Boolean }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "23413a24": (sizes.value.price),
  "019cd5da": (_ctx.color),
  "44330846": (sizes.value.period),
  "16dfeb59": (_ctx.periodColor),
  "0c00f556": (sizes.value.currency)
}))

const { convertPriceWithLanguagePartials } = useCurrencyConvert();

const props = __props;

const sizes = computed(() => {
  if (props.type === 'default') {
    return {
      price: '1.6rem',
      period: '1.2rem',
      currency: '1.2rem',
    };
  }
  return {
    price: '1.2rem',
    period: '1.2rem',
    currency: '1.2rem',
  };
});

const convertPrice = computed(() => {
  const price = convertPriceWithLanguagePartials(props.price, props.currency);
  const integer = price.find((item) => item.type === 'integer')?.value;
  const decimal = price.find((item) => item.type === 'decimal')?.value;
  const fraction = price.find((item) => item.type === 'fraction')?.value;

  return {
    price: `${integer}${decimal}${fraction}`,
    currency: price.find((item) => item.type === 'currency')?.value || '',
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(['ui__price', { crossed: _ctx.crossed }])
  }, [
    (_ctx.crossed)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(convertPrice.value.currency), 1),
    _createElementVNode("span", null, _toDisplayString(convertPrice.value.price), 1),
    (_ctx.period)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " /" + _toDisplayString(_ctx.period), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})