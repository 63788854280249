import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "notes-transform-step-workspace" }
const _hoisted_2 = { class: "notes-transform-step-workspace__controls" }

import NoteTransformStepCard from '@/components/notes/transform/steps/NoteTransformStepCard.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import NotesListWorkspace from '@/components/notes/list/NotesListWorkspace.vue';
import { storeToRefs } from 'pinia';
import { useNotesByWorkspacesStore } from '@/stores/notes/useNotesByWorkspaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformStepWorkspace',
  setup(__props) {

const notesTransformStore = useNotesTransformStore();
const notesByWorkspaces = useNotesByWorkspacesStore();
const { mode } = storeToRefs(notesTransformStore);
const { groups } = storeToRefs(notesByWorkspaces);

const handleEnd = () => {
  notesTransformStore.planners = notesByWorkspaces.end();
  notesTransformStore.goToNextStep();
};

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(NoteTransformStepCard, { stretch: "" }, {
    header: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(`notes.transform.workspace.title.${_unref(mode)}`)), 1),
      _createElementVNode("small", null, _toDisplayString(_ctx.$t(`notes.transform.workspace.description.${_unref(mode)}`)), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ui_button, { onClick: handleEnd }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('notes.action.next')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groups), (group) => {
          return (_openBlock(), _createBlock(NotesListWorkspace, {
            key: group.workspace.id,
            workspace: group.workspace,
            mode: _unref(mode),
            modelValue: group.notes,
            "onUpdate:modelValue": ($event: any) => ((group.notes) = $event)
          }, null, 8, ["workspace", "mode", "modelValue", "onUpdate:modelValue"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
}

})