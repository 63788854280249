import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]

import { createAvatarLink } from '@/utils/url';
import { computed, ref } from 'vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';
import { stringToColor } from '@/utils/common';
import { whenever } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAvatar',
  props: {
    avatarLink: {},
    size: {},
    fallbackName: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "6e8d6292": (_ctx.size)
}))

const props = __props;

const isImageLoaded = ref(false);

const image = ref<HTMLImageElement | null>(null);
whenever(
  () => image.value,
  () => {
    if (!image.value) return;
    image.value.onload = () => (isImageLoaded.value = true);
    image.value.onerror = () => (isImageLoaded.value = true);
  },
);

whenever(
  () => props.avatarLink,
  () => (isImageLoaded.value = false),
);

const computedAvatarLink = computed(() => createAvatarLink(props.avatarLink));
const computedFallbackName = computed(() => {
  if (!props.fallbackName) return;
  const [firstName, lastName] = props.fallbackName.split(' ');
  const label = `${firstName?.[0] || ''}${lastName?.[0] || ''}`
    .trim()
    .toUpperCase();
  const color = stringToColor(props.fallbackName);

  return { label, color };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "ui-avatar",
    style: _normalizeStyle(_ctx.size && { width: _ctx.size, height: _ctx.size })
  }, [
    (_ctx.fallbackName && !_ctx.avatarLink)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ui-avatar__fallback-label",
          style: _normalizeStyle({ background: computedFallbackName.value?.color })
        }, [
          _createElementVNode("span", null, _toDisplayString(computedFallbackName.value.label), 1)
        ], 4))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          ref_key: "image",
          ref: image,
          src: 
        computedAvatarLink.value ||
        (isImageLoaded.value ? require(`@images/default-avatar.svg`) : '')
      ,
          alt: "avatar",
          class: _normalizeClass(["ui-avatar__image", { 'ui-avatar__image-loading': !isImageLoaded.value }])
        }, null, 10, _hoisted_1)),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.avatarLink && !isImageLoaded.value)
          ? (_openBlock(), _createBlock(IconSpinner, {
              key: 0,
              "circle-color": "#ffffff",
              color: "#ffffff",
              class: "ui-avatar__loading"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 4))
}
}

})