<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <circle cx="4" cy="4" r="3.5" fill="#C8F48D" stroke="currentColor" />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
