import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "workspaces-edit__header" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "notification-modal__btns" }

import { ref } from 'vue';
import UiButton from '@/components/control/UiButton.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { Workspace } from '@/types/workspaces';
import IconMarker from '@/components/icon/IconMarker.vue';
import ModalDeleteWorkspace from '@/components/modals/Workspaces/ModalDeleteWorkspace.vue';
import IconLogout from '@/components/icon/nav/IconLogout.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

type Props = {
  isOpen?: boolean;
  list: Workspace[];
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalEditGuestWorkspaces',
  props: {
    isOpen: { type: Boolean },
    list: {}
  },
  emits: ['close'],
  setup(__props: any) {




const { t } = useI18n();

const workspacesStore = useWorkspaceStore();
const { fetchList, removeUser } = workspacesStore;

const user = useUserStore();
const { userData } = storeToRefs(user);

const isExitVisible = ref(false);
const pending = ref(false);
const currentId = ref<number | null>(null);

const onExitWorkspace = async () => {
  if (!currentId.value) return;
  try {
    isExitVisible.value = false;
    pending.value = true;

    if (!userData.value?.id) return;
    await removeUser({
      userId: userData.value?.id,
      companyId: currentId.value,
    });
    await fetchList();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      "model-value": _ctx.isOpen,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      width: "45rem"
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('workspaces.edit.guest-title')), 1)
      ]),
      footer: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", null, null, -1)
      ])),
      default: _withCtx(() => [
        _createVNode(_Transition, {
          name: "blur",
          appear: "",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['workspaces-edit', { pending: pending.value }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (workspace) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "workspaces-edit__row",
                  key: workspace.id
                }, [
                  _createVNode(IconMarker, {
                    color: workspace.color
                  }, null, 8, ["color"]),
                  _createTextVNode(" " + _toDisplayString(workspace.name) + " ", 1),
                  _createElementVNode("div", {
                    class: "workspaces-edit__exit",
                    onClick: ($event: any) => {
              currentId.value = workspace.id;
              isExitVisible.value = true;
            }
                  }, [
                    _cache[2] || (_cache[2] = _createTextVNode(" Exit ")),
                    _createVNode(IconLogout)
                  ], 8, _hoisted_2)
                ]))
              }), 128))
            ], 2)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(ModalDeleteWorkspace, {
      "is-open": isExitVisible.value,
      title: _ctx.$t('workspaces.exit.title'),
      message: _ctx.$t('workspaces.exit.message')
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(UiButton, {
            plain: "",
            onClick: onExitWorkspace
          }, {
            default: _withCtx(() => [
              _createVNode(IconLogout, { style: {"max-height":"1.6rem"} }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('workspaces.exit.confirm')), 1)
            ]),
            _: 1
          }),
          _createVNode(UiButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => {
            isExitVisible.value = false;
            currentId.value = null;
          })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('workspaces.exit.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["is-open", "title", "message"])
  ], 64))
}
}

})