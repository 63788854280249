import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "autocomplete-users__user" }
const _hoisted_2 = { class: "autocomplete-users__user-content" }
const _hoisted_3 = { class: "autocomplete-users__user-name" }
const _hoisted_4 = { class: "autocomplete-users__user-position" }

import UiAvatar from '@/components/ui/UiAvatar.vue';
import { SubordinateViewType } from '@/utils/subordinates';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAutocompleteUser',
  props: {
    item: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UiAvatar, {
      size: "3rem",
      "avatar-link": _ctx.item?.avatar?.uuidLink,
      "fallback-name": _ctx.item?.fullName
    }, null, 8, ["avatar-link", "fallback-name"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.item?.fullName || _ctx.item?.email), 1),
      _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.item?.positionName), 1)
    ])
  ]))
}
}

})