import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal-body-view__container" }
const _hoisted_2 = { class: "modal-body-view__group modal-body-view__row" }
const _hoisted_3 = { style: {"position":"relative"} }
const _hoisted_4 = { class: "modal-body-view__col" }
const _hoisted_5 = { class: "modal-body-view__group modal-body-view__row" }
const _hoisted_6 = {
  class: "modal-body-view__group",
  id: "view-share"
}
const _hoisted_7 = { class: "modal-body-view__comments" }

import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import ModalViewTaskFiles from '@/components/modals/Tasks/ModalViewTask/ModalViewTaskFiles.vue';
import ModalViewCommentsList from '@/components/modals/Tasks/ModalViewTask/ModalViewCommentsList.vue';
import UiInput from '@/components/control/UiInput.vue';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { SubordinateViewType } from '@/utils/subordinates';
import { concatName } from '@/utils/text';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import { priorityOptions } from '@/consts';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalBodyViewTask',
  setup(__props) {

const runtimeValue = ref('');

const viewTask = useViewTaskStore();
const { isReadonly, activeTask, hasChanges, datePickerData, state } =
  storeToRefs(viewTask);

const { setActiveTaskFields, setDate, setTime } = viewTask;

const newTask = useNewTaskStore();
const { getSubordinates } = newTask;
const { subordinates, isSubordinatesFetching } = storeToRefs(newTask);

const executor = computed(() =>
  concatName(
    activeTask.value.executor?.firstName,
    activeTask.value.executor?.lastName,
  ),
);
const isOpenAutoComplete = ref(false);
const searchUsers = ref('');

const selectUser = (user: SubordinateViewType) => {
  if (user.id === activeTask.value.executor?.id)
    return (isOpenAutoComplete.value = false);
  setActiveTaskFields('executor', {
    ...user,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
  });
  isOpenAutoComplete.value = false;
};

const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

watch(
  () => activeTask.value.id,
  () => {
    runtimeValue.value = activeTask.value.taskContent || '';
  },
);

onMounted(
  () =>
    !state.value.isShared && !isSubordinatesFetching.value && getSubordinates(),
);

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-body-view", { divided: _unref(state).isShowComments }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(UiInput, {
            label: _ctx.$t('modal_task.to'),
            icon: "user-change",
            "icon-align": "right",
            "model-value": executor.value,
            readonly: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpenAutoComplete.value = true))
          }, null, 8, ["label", "model-value"]),
          (_unref(subordinates) && !_unref(state).isShared && !_unref(isReadonly))
            ? (_openBlock(), _createBlock(UiAutocompleteUsers, {
                key: 0,
                items: _unref(subordinates),
                "is-open": isOpenAutoComplete.value,
                search: searchUsers.value,
                "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((searchUsers).value = $event)),
                onSelectItem: selectUser
              }, null, 8, ["items", "is-open", "search"]))
            : _createCommentVNode("", true)
        ])), [
          [_directive_click_outside, hideAutocomplete],
          [
            _directive_skeleton,
            _unref(state).isTaskLoading,
            void 0,
            { large: true }
          ]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(PopupTaskDatePicker, _mergeProps(_unref(datePickerData), {
            "is-share": _unref(state).isShared,
            "is-readonly": _unref(isReadonly),
            "onUpdate:time": _cache[2] || (_cache[2] = ($event: any) => {
              _unref(setTime)($event);
              hasChanges.value = true;
            }),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => {
              _unref(setDate)($event);
              hasChanges.value = true;
            })
          }), null, 16, ["is-share", "is-readonly"])
        ])), [
          [
            _directive_skeleton,
            _unref(state).isTaskLoading,
            void 0,
            { large: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createVNode(UiInput, {
          "model-value": _unref(activeTask).name,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_unref(setActiveTaskFields)('name', $event))),
          maxLength: "75",
          label: _ctx.$t('modal_task.task_name'),
          readonly: _unref(isReadonly)
        }, null, 8, ["model-value", "label", "readonly"]), [
          [
            _directive_skeleton,
            _unref(state).isTaskLoading,
            void 0,
            { large: true }
          ]
        ]),
        _withDirectives(_createVNode(UiDropdownPriority, {
          class: "priority-dropdown",
          style: {"max-width":"14rem"},
          disabled: _unref(isReadonly),
          "model-value": {
            title: _unref(activeTask).taskPriority,
            value: _unref(activeTask).taskPriority,
          },
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (
            _unref(setActiveTaskFields)('taskPriority', $event.value)
          )),
          items: _unref(priorityOptions)
        }, null, 8, ["disabled", "model-value", "items"]), [
          [
            _directive_skeleton,
            _unref(state).isTaskLoading,
            void 0,
            { large: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createVNode(UiInput, {
          class: "modal-body-view__description",
          "model-value": _unref(activeTask).taskContent,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_unref(setActiveTaskFields)('taskContent', $event))),
          type: "textarea",
          readonly: _unref(isReadonly),
          maxLength: "10000",
          style: {"resize":"vertical"},
          rows: "8"
        }, null, 8, ["model-value", "readonly"]), [
          [
            _directive_skeleton,
            _unref(state).isTaskLoading,
            void 0,
            { large: true }
          ]
        ])
      ]),
      _withDirectives(_createVNode(ModalViewTaskFiles, {
        "is-readonly": _unref(isReadonly),
        class: "modal-body-view__group modal-body-view__upload"
      }, null, 8, ["is-readonly"]), [
        [_directive_skeleton, _unref(state).isFileLoading]
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(ModalViewCommentsList)
    ])
  ], 2))
}
}

})