import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IconShare',
  props: {
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.99169 6.24002C10.5332 6.49857 11.0048 6.8833 11.3668 7.36189C11.7288 7.84048 11.9706 8.39895 12.072 8.9904C12.1735 9.58185 12.1314 10.189 11.9495 10.7608C11.7676 11.3327 11.4512 11.8525 11.0267 12.2767L7.27669 16.0267C6.57342 16.73 5.61959 17.125 4.62502 17.125C3.63046 17.125 2.67662 16.73 1.97336 16.0267C1.27009 15.3234 0.875 14.3696 0.875 13.375C0.875 12.3805 1.27009 11.4266 1.97336 10.7234L3.43752 9.25919M14.5625 8.74086L16.0267 7.27669C16.73 6.57342 17.125 5.61959 17.125 4.62502C17.125 3.63046 16.73 2.67662 16.0267 1.97336C15.3234 1.27009 14.3696 0.875 13.375 0.875C12.3805 0.875 11.4266 1.27009 10.7234 1.97336L6.97336 5.72336C6.54889 6.14752 6.23241 6.66736 6.05051 7.23921C5.86861 7.81105 5.82659 8.4182 5.928 9.00964C6.0294 9.60109 6.27126 10.1596 6.63327 10.6382C6.99527 11.1167 7.46683 11.5015 8.00836 11.76",
      stroke: "currentColor",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
}

})