import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/integrations/google/voiset-logo.webp'
import _imports_1 from '@images/integrations/google/two-way-arrows.svg'
import _imports_2 from '@images/integrations/google/google-logo.svg'


import { GoogleCalendar } from '@/types/integrations';

type Props = { sync: GoogleCalendar['syncCalendarMode'] };

export default /*@__PURE__*/_defineComponent({
  __name: 'TwoWaySync',
  props: {
    sync: {}
  },
  emits: ['onModeChange'],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onModeChange', { syncCalendarMode: 'DUAL' })))
  }, [
    _cache[1] || (_cache[1] = _createTextVNode(" Two-way sync ")),
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      class: "logo",
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _cache[3] || (_cache[3] = _createTextVNode(" Voiset ")),
    _cache[4] || (_cache[4] = _createElementVNode("img", {
      src: _imports_1,
      alt: ""
    }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("img", {
      class: "logo",
      src: _imports_2,
      alt: ""
    }, null, -1)),
    _cache[6] || (_cache[6] = _createTextVNode(" Google ")),
    _createVNode(_component_el_radio_group, { modelValue: _ctx.sync }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio, { value: "DUAL" })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})