import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-select", "data-select-group"]
const _hoisted_2 = {
  key: 0,
  class: "note-item__number"
}
const _hoisted_3 = { class: "note-item__card" }
const _hoisted_4 = { class: "note-item__state" }
const _hoisted_5 = { class: "note-item__info" }

import { Note } from '@/types/notes';
import NoteItemControls, {
  NoteActionType,
} from '@/components/notes/item/NoteItemControls.vue';
import { useNoteStore } from '@/stores/notes/useNote';
import IconClose from '@/components/icon/IconClose.vue';
import { shallowRef } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteItem',
  props: {
    data: {},
    uncheckable: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    selectGroup: {},
    hideControls: { type: Boolean, default: false },
    hideNumber: { type: Boolean, default: false },
    closable: { type: Boolean, default: false },
    disableOpen: { type: Boolean, default: false },
    isStatusChanging: { type: Boolean, default: false }
  },
  emits: ["check", "action", "close"],
  setup(__props: any) {



const props = __props;

const noteStore = useNoteStore();

const touchStart = shallowRef<Date | null>(null);

const handleTap = () => {
  !props.disableOpen && noteStore.start(props.data.id);
};

const handleTouchstart = () => {
  touchStart.value = new Date();
};

const handleTouchend = () => {
  if (!touchStart.value) return;
  const duration =
    new Date().getMilliseconds() - touchStart.value.getMilliseconds();
  if (duration < 125 && duration > 0) {
    handleTap();
  }
  touchStart.value = null;
};

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["note-item", {
      'is-completed': _ctx.data.status === 'CLOSE',
      'is-moving': _ctx.isStatusChanging,
      selected: _ctx.isSelected,
    }]),
    "data-select": _ctx.data.id,
    "data-select-group": _ctx.selectGroup,
    onContextmenu: _cache[32] || (_cache[32] = _withModifiers(() => {}, ["prevent"])),
    onClick: _cache[33] || (_cache[33] = ($event: any) => (!_ctx.disableOpen && _unref(noteStore).start(_ctx.data.id))),
    onTouchstart: handleTouchstart,
    onTouchend: handleTouchend
  }, [
    (!_ctx.hideNumber)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "№" + _toDisplayString(_ctx.data.priorityNumber), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.closable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "note-item__close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close', _ctx.data.priorityNumber)), ["stop"])),
            onDragstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
            onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
            onDragenter: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
            onDragleave: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
            onDragend: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
            onTouchstart: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
            onTouchmove: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"])),
            onTouchend: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"])),
            "on:touchOverNode": _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(IconClose)
          ], 32))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "note-item__status",
          onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"])),
          onDragstart: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"])),
          onDragover: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"])),
          onDragenter: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"])),
          onDragleave: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["stop"])),
          onDragend: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["stop"])),
          onTouchstart: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["stop"])),
          onTouchmove: _cache[18] || (_cache[18] = _withModifiers(() => {}, ["stop"])),
          onTouchend: _cache[19] || (_cache[19] = _withModifiers(() => {}, ["stop"])),
          "on:touchOverNode": _cache[20] || (_cache[20] = _withModifiers(() => {}, ["stop"]))
        }, [
          (!_ctx.uncheckable)
            ? (_openBlock(), _createBlock(_component_el_checkbox, {
                key: 0,
                "model-value": _ctx.data.status === 'CLOSE',
                class: "is-primary",
                onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('check', _ctx.data.id)))
              }, null, 8, ["model-value"]))
            : _createCommentVNode("", true)
        ], 32),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.data.title), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.data.description), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "note-item__control",
        onClick: _cache[22] || (_cache[22] = _withModifiers(() => {}, ["stop"])),
        onDragstart: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["stop"])),
        onDragover: _cache[24] || (_cache[24] = _withModifiers(() => {}, ["stop"])),
        onDragenter: _cache[25] || (_cache[25] = _withModifiers(() => {}, ["stop"])),
        onDragleave: _cache[26] || (_cache[26] = _withModifiers(() => {}, ["stop"])),
        onDragend: _cache[27] || (_cache[27] = _withModifiers(() => {}, ["stop"])),
        onTouchstart: _cache[28] || (_cache[28] = _withModifiers(() => {}, ["stop"])),
        onTouchmove: _cache[29] || (_cache[29] = _withModifiers(() => {}, ["stop"])),
        onTouchend: _cache[30] || (_cache[30] = _withModifiers(() => {}, ["stop"])),
        "on:touchOverNode": _cache[31] || (_cache[31] = _withModifiers(() => {}, ["stop"]))
      }, [
        (!_ctx.hideControls)
          ? (_openBlock(), _createBlock(NoteItemControls, {
              key: 0,
              "is-completed": _ctx.data.status === 'CLOSE',
              onAction: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$emit('action', $event)))
            }, null, 8, ["is-completed"]))
          : _createCommentVNode("", true)
      ], 32)
    ])
  ], 42, _hoisted_1))
}
}

})