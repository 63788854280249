import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "spinner" }

import IconSpinner from '@/components/icon/IconSpinner.vue';
interface Props {
  color?: 'white' | 'default';
  layout?: boolean;
  size?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSpinner',
  props: {
    color: { default: 'default' },
    layout: { type: Boolean, default: false },
    size: { default: '6rem' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "1b502574": (_ctx.size)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(IconSpinner, { class: "spinner__icon" })
  ]))
}
}

})