import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { reactive } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropArea',
  setup(__props, { expose: __expose }) {

const data = reactive({
  isActiveDropArea: false,
});

let enterTarget: EventTarget;

const onDragLeaveHandler = (e: Event) => {
  if (enterTarget === e.target) {
    data.isActiveDropArea = false;
  }
};
const onDragEnterHandler = (e: Event) => {
  if (e.target) {
    enterTarget = e.target;
  }
  data.isActiveDropArea = true;
};

const setInactiveDropArea = () => {
  data.isActiveDropArea = false;
};

const setActiveDropArea = () => {
  data.isActiveDropArea = true;
};

__expose({
  setInactiveDropArea,
  setActiveDropArea,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "block-drop-area",
    onDragenter: _withModifiers(onDragEnterHandler, ["prevent"]),
    onDragover: _withModifiers(onDragEnterHandler, ["prevent"]),
    onDragleave: _withModifiers(onDragLeaveHandler, ["prevent"])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      isActiveDropArea: data.isActiveDropArea,
      setInactiveDropArea: setInactiveDropArea,
      setActiveDropArea: setActiveDropArea
    })
  ], 32))
}
}

})