<template>
  <div
    class="layout-app"
    :style="{ background: $route.meta.background as string }"
  >
    <LayoutHeader />
    <div class="page">
      <LayoutAside :class="['page__aside', { 'has-aside': hasAside }]" />
      <ModalCreateTask
        :is-open="newTask.isModalOpen"
        @on-close="
          newTask.isModalOpen = false;
          newTask.resetTask();
        "
      />
      <ModalViewTask />
      <ModalAcceptWorkspace />
      <ModalAcceptSharedTask />
      <ModalOnboarding />
      <ModalPlanBuy
        @close="isPlansModalVisible = false"
        :is-open="isPlansModalVisible"
      />
      <ModalPlanBuy
        :title="$t('card_tariff.no_workspaces_label')"
        @close="isNoMoreWorkspacesModalVisible = false"
        :is-open="isNoMoreWorkspacesModalVisible"
      />
      <ModalCreateWorkspace
        :is-open="isCreateOpen"
        @close="isCreateOpen = false"
      />
      <CreateNoteModal v-model="createNoteStore.isStarted" />
      <NoteCardModal v-model="noteStore.isStarted" />
      <CoachActive />
      <Teleport to="body">
        <NoteTransformSteps />
      </Teleport>
      <main :class="['page__content', { 'has-aside': hasAside, padded }]">
        <slot />
      </main>
    </div>
  </div>
  <Mobile class="mobile" />
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref, Ref } from 'vue';
import LayoutHeader from '@/components/layout/app/LayoutHeader.vue';
import LayoutAside from '@/components/layout/app/LayoutAside.vue';
import ModalCreateTask from '@/components/modals/Tasks/ModalCreateTask/ModalCreateTask.vue';
import ModalViewTask from '@/components/modals/Tasks/ModalViewTask/ModalViewTask.vue';
import ModalAcceptWorkspace from '@/components/modals/Workspaces/ModalAcceptWorkspace.vue';
import { useNewTaskStore } from '@/stores/newTask';
import { useSettingsStore } from '@/stores/settings/settings';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import ModalOnboarding from '@/components/modals/ModalOnboarding.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import CreateNoteModal from '@/components/notes/create/CreateNoteModal.vue';
import NoteCardModal from '@/components/notes/card/NoteCardModal.vue';
import { useNoteStore } from '@/stores/notes/useNote';
import NoteTransformSteps from '@/components/notes/transform/NoteTransformSteps.vue';
import { useRoute } from 'vue-router';
import { useWorkspaceStore } from '@/stores/workspaces';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { useSharedTasksStore } from '@/stores/sharingHistory/sharedTasks';
import ModalCreateWorkspace from '@/components/modals/Workspaces/Create/ModalCreateWorkspace.vue';
import CoachActive from '@/components/coaches/CoachActive.vue';
import Mobile from '@/components/layout/mobile.vue';
import ModalAcceptSharedTask from '@/components/modals/Tasks/ModalSharedTask/ModalAcceptSharedTask.vue';

const route = useRoute();

const newTask = useNewTaskStore();
const settingsStore = useSettingsStore();
const invitationsStore = useInvitationsStore();
const createNoteStore = useCreateNoteStore();
const noteStore = useNoteStore();
const sharedTasks = useSharedTasksStore();
const workspacesStore = useWorkspaceStore();
const { fetchList } = workspacesStore;
const { list, isCreateOpen, isNoMoreWorkspacesModalVisible } =
  storeToRefs(workspacesStore);
const googleAccounts = useGoogleAccounts();

const tariffStore = useTariffStore();
const { isPlansModalVisible } = storeToRefs(tariffStore);

const windowWidth = inject<Ref<number>>('windowWidth', ref(0));

const hasAside = computed(() => windowWidth.value < 1100 || route.meta.noAside);
const padded = computed(() => route.meta.padded);

onMounted(() => {
  Promise.allSettled([
    sharedTasks.fetchRequests(),
    invitationsStore.fetchInternalInvitations(),
    settingsStore.getLocaleUiList(),
    !list.value.length && fetchList(),
    googleAccounts.fetchAccountsWithCalendars(),
  ]);
});
</script>

<style lang="scss" scoped>
.layout-app {
  height: inherit;
  @include flex-column;

  @include break-point(850px) {
    display: none;
  }
}

.mobile {
  @include break-point(850px) {
    display: block;
  }
}

.page {
  height: inherit;

  &__content {
    @include flex-column;
    position: relative;
    height: inherit;
    padding: 9rem 2.4rem 0 12.8rem;
    transition: all 0.3s ease;

    &.has-aside {
      padding-left: 2.4rem;
    }

    &.padded {
      padding: 5.8rem 0 0 0;
    }
  }

  &__aside {
    &.has-aside {
      display: none;
    }
  }
}
</style>
