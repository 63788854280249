import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import UiModal from '@/components/ui/UiModal.vue';
import { useVModel } from '@vueuse/core';
import { useNoteStore } from '@/stores/notes/useNote';
import { storeToRefs } from 'pinia';
import NoteCardModalFooter from '@/components/notes/card/NoteCardModalFooter.vue';
import NoteItemControls, {
  NoteActionType,
} from '@/components/notes/item/NoteItemControls.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import NoteCardBody from '@/components/notes/card/NoteCardBody.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { ref, watch } from 'vue';
import { CoachName } from '@/types/coaches';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteCardModal',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isOpen = useVModel(props, 'modelValue', emit);

const noteStore = useNoteStore();
const { isStarted, note, isNoteLoading, initialNote } = storeToRefs(noteStore);

const notesTransformStore = useNotesTransformStore();

const handleSchedule = () => {
  if (!initialNote.value) return;
  isStarted.value = false;
  notesTransformStore.start([initialNote.value]);
};

const handleAction = (type: NoteActionType) => {
  if (type === 'done' || type === 'back') {
    note.value.status = type === 'done' ? 'CLOSE' : 'ACTIVE';
  } else if (type === 'schedule') {
    handleSchedule();
  }
};

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);

const cashedCoach = ref<CoachName | null>(null);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      cashedCoach.value = activeCoach.value.name;
      setCoach('scheduleNote');
    } else {
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiModal, {
    "is-open": _unref(isOpen),
    onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_unref(noteStore).end())),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    "close-disabled": true,
    title: _ctx.$t('notes.one'),
    class: "note-card-modal"
  }, {
    headerActions: _withCtx(() => [
      (!_unref(isNoteLoading))
        ? (_openBlock(), _createBlock(NoteItemControls, {
            key: 0,
            "is-completed": _unref(note).status === 'CLOSE',
            onAction: handleAction
          }, null, 8, ["is-completed"]))
        : _createCommentVNode("", true),
      _createVNode(WidgetCoach)
    ]),
    body: _withCtx(() => [
      _createVNode(NoteCardBody, {
        title: _unref(note).title,
        "onUpdate:title": _cache[0] || (_cache[0] = ($event: any) => ((_unref(note).title) = $event)),
        description: _unref(note).description,
        "onUpdate:description": _cache[1] || (_cache[1] = ($event: any) => ((_unref(note).description) = $event)),
        "is-loading": _unref(isNoteLoading)
      }, null, 8, ["title", "description", "is-loading"])
    ]),
    footer: _withCtx(() => [
      _createVNode(NoteCardModalFooter, { onSchedule: handleSchedule })
    ]),
    _: 1
  }, 8, ["is-open", "title"]))
}
}

})