import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notification-modal__content" }
const _hoisted_2 = {
  key: 0,
  class: "notification-modal__message"
}
const _hoisted_3 = { class: "notification-modal__btns" }

import { TranslateResult } from 'vue-i18n';
import UiButton from '@/components/control/UiButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalNotification',
  props: {
    isOpen: { type: Boolean },
    title: {},
    message: {},
    yes: {},
    no: {},
    noBtns: { type: Boolean }
  },
  emits: ['on-confirm', 'on-close'],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    title: _ctx.title,
    class: "notification-modal",
    "model-value": _ctx.isOpen
  }, _ctx.$attrs, {
    width: _ctx.$attrs.width || '30rem',
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-close')))
  }), {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (_ctx.message)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.noBtns)
          ? _renderSlot(_ctx.$slots, "footer", { key: 0 }, () => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(UiButton, {
                  plain: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-confirm')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.yes ?? _ctx.$t('yes')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(UiButton, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-close')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.no ?? _ctx.$t('cancel')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["title", "model-value", "width"]))
}
}

})