import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-control__dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

import { computed, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { taskStatuses } from '@/consts';
import { storeToRefs } from 'pinia';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { IItemDropdown } from '@/components/ui/UiDropdown/UiDropdown.vue';
import IconChevron from '@/components/icon/IconChevron.vue';
import { useTasksStore } from '@/stores/tasks/tasks';
import { ElNotification } from 'element-plus';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalStatusControl',
  props: {
    isReadonly: { type: Boolean, default: false }
  },
  setup(__props: any) {



const { t } = useI18n();
const viewTask = useViewTaskStore();
const tasksStore = useTasksStore();

const { activeTask, state } = storeToRefs(viewTask);

const isProcessUpdate = ref(false);
const isOpen = ref(false);

const activeStatus = computed<IItemDropdown | null>(() => {
  return (
    statusesList.value.find(
      (item) => item.value === activeTask.value.taskStatus,
    ) || null
  );
});

const statusesList = computed(() => {
  return [
    {
      value: taskStatuses.CREATED,
      title: t('view_modal_task.statuses.created'),
      disabled: true,
    },
    {
      value: taskStatuses.APPOINTED,
      title: t('view_modal_task.statuses.appointed'),
    },
    {
      value: taskStatuses.ACCEPTED,
      title: t('view_modal_task.statuses.accepted'),
    },
    {
      value: taskStatuses.DONE,
      title: t('view_modal_task.statuses.done'),
    },
    {
      value: taskStatuses.CONFIRMED,
      title: t('view_modal_task.statuses.confirmed'),
    },
    {
      value: taskStatuses.CLOSED,
      title: t('view_modal_task.statuses.closed'),
    },
    {
      value: taskStatuses.OPEN,
      title: t('view_modal_task.statuses.open'),
    },
  ];
});

const updateTaskStatus = async (status: keyof typeof taskStatuses) => {
  if (status === 'CREATED') return;

  if (!activeTask.value.executor?.id)
    return ElNotification({
      message: t('view_modal_task.select_executor_warning'),
      offset: 80,
    });

  isOpen.value = false;
  isProcessUpdate.value = true;

  if (activeStatus.value) {
    viewTask.setActiveTaskFields('taskStatus', status);
  }

  isProcessUpdate.value = false;

  if (state.value.isShared) return;
  await tasksStore.getTodoCount();
};

onUnmounted(() => (isOpen.value = false));

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_skeleton = _resolveDirective("skeleton")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    trigger: "click",
    placement: "top",
    width: "250",
    disabled: _ctx.isReadonly,
    visible: isOpen.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event)),
    onHide: _cache[1] || (_cache[1] = ($event: any) => (isOpen.value = false)),
    onShow: _cache[2] || (_cache[2] = ($event: any) => (isOpen.value = true))
  }, {
    reference: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        id: "view-status",
        class: _normalizeClass(["modal-control__active-status", { pending: isProcessUpdate.value }])
      }, [
        (activeStatus.value?.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              width: "16",
              src: 
            require(`@images/statuses/${String(
              activeStatus.value.value,
            ).toLowerCase()}.svg`)
          ,
              alt: "icon"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(activeStatus.value?.title) + " ", 1),
        _createVNode(IconChevron, {
          direction: "bottom",
          class: "modal-control__chevron"
        })
      ], 2)), [
        [
          _directive_skeleton,
          !activeStatus.value?.value,
          void 0,
          { large: true }
        ]
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(statusesList.value, (status) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["modal-control__dropdown-item", { active: status.title === activeStatus.value?.title }]),
            key: status.value,
            onClick: ($event: any) => (updateTaskStatus(status.value)),
            style: _normalizeStyle(
          status?.disabled && { opacity: '0.5', 'pointer-events': 'none' }
        )
          }, [
            _createElementVNode("img", {
              width: "16",
              src: 
            require(`@images/statuses/${String(
              status.value,
            ).toLowerCase()}.svg`)
          ,
              alt: "icon"
            }, null, 8, _hoisted_3),
            _createTextVNode(" " + _toDisplayString(status.title) + " ", 1),
            _createVNode(_component_el_radio, {
              "model-value": status.title,
              label: activeStatus.value?.title || '',
              class: "modal-control__dropdown-radio"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(_toDisplayString(''))
              ])),
              _: 2
            }, 1032, ["model-value", "label"])
          ], 14, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["disabled", "visible"]))
}
}

})