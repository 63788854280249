import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

type Props = {
  color?: string;
  size?: number | string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IconMarker',
  props: {
    color: { default: '#9BC95E' },
    size: { default: '8' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size,
    height: _ctx.size,
    viewBox: "0 0 8 8",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("circle", {
      cx: "4",
      cy: "4",
      r: "4",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})