import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "workspaces-accept__title purple-text" }
const _hoisted_2 = { class: "workspaces-accept__btns" }

import ModalNotification from '@/components/modals/ModalNotification.vue';
import { computed, ref } from 'vue';
import { concatName } from '@/utils/text';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { storeToRefs } from 'pinia';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useWorkspaceStore } from '@/stores/workspaces';

type Props = { title?: string };

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalAcceptWorkspace',
  props: {
    title: { default: 'workspaces.accept.title' }
  },
  emits: ['onInvitationsListIsOver'],
  setup(__props: any, { emit: __emit }) {


const emits = __emit;
const { t } = useI18n();
const invitationsStore = useInvitationsStore();
const { setInvitationStatus } = invitationsStore;
const { internalInvitations } = storeToRefs(invitationsStore);

const workspacesStore = useWorkspaceStore();

const pending = ref(false);

const isOpen = computed(() => internalInvitations.value.length > 0);

const currentInternalInvitation = computed(
  () => internalInvitations.value[internalInvitations.value.length - 1],
);

const creatorName = computed(() =>
  concatName(
    currentInternalInvitation.value?.nameCreator,
    currentInternalInvitation.value?.secondNameCreator,
  ),
);

const switchToNextInvitation = () => internalInvitations.value.pop();

const onSetInvitationStatus = async (isAccepted: boolean) => {
  try {
    pending.value = true;
    await setInvitationStatus({
      invitationCode: currentInternalInvitation.value.invitationCode,
      result: isAccepted,
    });
    if (isAccepted) {
      ElNotification.success({
        message: t('workspaces.accept.accepted_msg', {
          company: currentInternalInvitation.value.companyName,
        }),
        offset: 80,
      });
    }
    switchToNextInvitation();
    if (!internalInvitations.value.length) {
      await workspacesStore.fetchList();
      emits('onInvitationsListIsOver');
    }
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createBlock(ModalNotification, {
    "is-open": isOpen.value,
    "close-on-click-modal": false,
    "close-on-press-escape": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.title)), 1)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('workspaces.accept.message', {
          creator: creatorName.value,
          company: currentInternalInvitation.value?.companyName,
        })), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ui_button, {
          plain: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onSetInvitationStatus(false))),
          "is-disabled": pending.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('workspaces.accept.reject')), 1)
          ]),
          _: 1
        }, 8, ["is-disabled"]),
        _createVNode(_component_ui_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (onSetInvitationStatus(true))),
          "is-disabled": pending.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('workspaces.accept.accept')), 1)
          ]),
          _: 1
        }, 8, ["is-disabled"])
      ])
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})