<template>
  <div :class="['widget-todo', { active }]" @click="onTodoClick">
    <div class="widget-todo__counter">
      <img :src="require('@images/mini-clock.svg')" alt="" />
      <span>{{ todoTaskCounter || 0 }}</span>
    </div>
    <span class="widget-todo__label">{{ $t('todo') }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useTasksStore } from '@/stores/tasks/tasks';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/stores/workspaces';
import { whenever } from '@vueuse/core';

const router = useRouter();
const tasks = useTasksStore();
const { todoTaskCounter } = storeToRefs(tasks);

const workspacesStore = useWorkspaceStore();
const { currentWorkspace } = storeToRefs(workspacesStore);

const { setFilter } = tasks;
const { filters } = storeToRefs(tasks);

whenever(() => currentWorkspace.value?.id, tasks.getTodoCount);

const onTodoClick = () => {
  setFilter('taskFastFilters', 'TO_DO');
  router.push({
    path: '/calendar',
  });
};

const active = computed(
  () => filters.value.taskDTO.taskFastFilters === 'TO_DO',
);

onMounted(async () => await tasks.getTodoCount());
</script>

<style scoped lang="scss">
.widget-todo {
  @include flex-row(1rem);
  align-items: center;
  border-radius: 4.2rem;
  border: 0.1rem solid transparent;
  padding: 0.6rem 1.4rem 0.6rem 1.2rem;
  cursor: pointer;
  @include f14;
  line-height: 1.5rem;
  transition: all 0.15s ease-in-out;

  &:hover,
  &.active {
    border-color: #c8f48d;
  }

  &__counter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    border: 0.1rem solid #1a2a39;
    border-radius: 50%;

    & > span {
      color: var(--color-success);
    }

    & > img {
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
    }
  }
}
</style>
