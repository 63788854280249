import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "60",
  height: "60",
  viewBox: "0 0 60 60",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconSpinner',
  props: {
  color: {
    type: String,
    required: false,
    default: '#778BEE',
  },
  circleColor: {
    type: String,
    required: false,
    default: '#C8F48D',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      cx: "30",
      cy: "30",
      r: "27",
      stroke: __props.circleColor,
      "stroke-width": "6"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M41 30C41 31.4445 40.7155 32.8749 40.1627 34.2095C39.6099 35.5441 38.7996 36.7567 37.7782 37.7782C36.7567 38.7996 35.5441 39.6099 34.2095 40.1627C32.8749 40.7155 31.4445 41 30 41",
      stroke: __props.color,
      "stroke-width": "6",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M19 30C19 28.5555 19.2845 27.1251 19.8373 25.7905C20.3901 24.4559 21.2004 23.2433 22.2218 22.2218C23.2433 21.2004 24.4559 20.3901 25.7905 19.8373C27.1251 19.2845 28.5555 19 30 19",
      stroke: __props.color,
      "stroke-width": "6",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_4)
  ]))
}
}

})