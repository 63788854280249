import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import services from '@/api/services';
import { ISubordinate } from '@/types/subordinate';
import { Pageable } from '@/types/pagination';
import { ElNotification } from 'element-plus';
import { useSubordinatesFiltersStore } from '@/stores/subordinates/subordinatesFilters';
import { useDebounceFn } from '@vueuse/core';
import { subordinatesMappingToView } from '@/utils/subordinates';
import { AxiosError } from 'axios';
import { i18n } from '@/plugins/i18n';

export const useSubordinatesStore = defineStore('subordinates', () => {
  const subordinates = ref<Pageable<ISubordinate> | null>(null);
  const subordinatesView = computed(() => {
    return {
      ...subordinates.value,
      content: subordinates.value?.content.map(subordinatesMappingToView),
    };
  });
  const subordinatesOverallList = ref<ISubordinate[]>([]);
  const subordinatesOverallListView = computed(() =>
    subordinatesOverallList.value?.map(subordinatesMappingToView),
  );
  const overallListPending = ref(false);

  const subordinatesFilters = useSubordinatesFiltersStore();
  const { filters, sorts } = storeToRefs(subordinatesFilters);
  const { initialFilters, setFilter, setSort } = subordinatesFilters;

  const pending = ref(false);
  const error = ref(false);

  const debouncedFetch = useDebounceFn(async () => {
    await fetch();
  }, 0);

  watch(() => [filters, sorts], debouncedFetch, { deep: true });

  async function fetch() {
    try {
      error.value = false;
      pending.value = true;

      const response = await services.positions.getSubordinates({
        ...filters.value,
        ...sorts.value,
      });
      subordinates.value = response.data;
      return response;
    } catch (e) {
      error.value = true;
      console.error(e);
      (e as AxiosError).response?.status !== 401 &&
        ElNotification.error({
          message: i18n.global.t('some_error'),
          offset: 80,
        });
    } finally {
      pending.value = false;
    }
  }

  async function fetchOverallList() {
    const response = await services.positions.getSubordinates({
      pageNum: 0,
      pageSize: 50,
    });
    subordinatesOverallList.value = response.data.content;
  }

  async function deleteSubordinate(id?: string | number) {
    if (!id) return;
    return services.auth.deleteUser(id);
  }

  async function removeSubordinateFromCompany(params: {
    userId: number;
    companyId: number;
  }) {
    return services.workspaces.removeUser(params);
  }

  return {
    subordinates,
    subordinatesView,
    pending,
    error,
    filters,
    setFilter,
    fetch,
    initialFilters,
    setSort,
    sorts,
    fetchOverallList,
    subordinatesOverallList,
    subordinatesOverallListView,
    overallListPending,
    deleteSubordinate,
    removeSubordinateFromCompany,
  };
});
