import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

interface Props {
  tooltip?: string;
  active?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderActionButton',
  props: {
    tooltip: {},
    active: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.tooltip,
    disabled: !_ctx.tooltip,
    placement: "bottom",
    effect: "customized"
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", _mergeProps(_ctx.$attrs, {
        class: [_ctx.$style.wrapper, _ctx.active && _ctx.$style.active]
      }), [
        _renderSlot(_ctx.$slots, "default")
      ], 16)
    ]),
    _: 3
  }, 8, ["content", "disabled"]))
}
}

})