import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]

import { computed, PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'IconChevron',
  props: {
  direction: {
    type: String as PropType<'top' | 'left' | 'right' | 'bottom'>,
    required: false,
    default: 'right',
  },
  size: {
    type: String,
    required: false,
    default: '24',
  },
},
  setup(__props) {

const props = __props;

const directionMapping = {
  left: '90',
  right: '-90',
  top: '180',
  bottom: '0',
};

const rotateStyle = computed(
  () => `transform: rotate(${directionMapping[props.direction]}deg)`,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    style: _normalizeStyle([rotateStyle.value, {"cursor":"pointer","flex":"0 0 auto"}]),
    width: __props.size,
    height: __props.size,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M6 9L12 15L18 9",
      stroke: "currentColor",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ]), 12, _hoisted_1))
}
}

})