import { NewTaskData } from '@/stores/newTask';
import { Ref, ref } from 'vue';
import services from '@/api/services';
import { DescriptionAIParse } from '@/types/tasks';
import { SubordinateViewType } from '@/utils/subordinates';
import { format } from 'date-fns';
import { TASK_DAY_FORMAT } from '@/consts/timeFormats';
import { priorityOptions } from '@/consts';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

type DirtyState = Partial<Record<keyof NewTaskData, boolean>>;

export const useTaskDescriptionParse = (
  currentData: Ref<NewTaskData>,
  subordinates: Ref<SubordinateViewType[] | null>,
) => {
  const description = ref<DescriptionAIParse>();

  const initialDirtyState = (): DirtyState => ({
    executor: false,
    name: false,
    taskPriority: false,
    deadline: false,
  });
  const dirtyState = ref<DirtyState>(initialDirtyState());
  const hasSmartResponse = ref(false);
  const resetDirtyState = () => {
    hasSmartResponse.value = false;
    dirtyState.value = initialDirtyState();
  };

  // const setFieldWatcher = (
  //   prop: keyof IcurrentData,
  // ): [
  //   () => IcurrentData[keyof IcurrentData],
  //   () => void,
  //   WatchOptions,
  // ] => [
  //   () => currentData.value[prop],
  //   () => (dirtyState.value[prop] = true),
  //   { deep: true },
  // ];
  //
  // watch(...setFieldWatcher('executor'));
  // watch(...setFieldWatcher('name'));
  // watch(...setFieldWatcher('taskPriority'));
  // watch(...setFieldWatcher('deadline'));

  const getDescription = async () => {
    if (hasSmartResponse.value) return;
    try {
      const { data } = await services.tasks.sendDescription(
        currentData.value.taskContent,
      );

      description.value = data;
      // const data =
      //   '{"titleRequest": "Список тем для YT","importance": "1","participants": "455","date": "2024-04-21"}';
    } catch (e) {
      console.error(e);
    } finally {
      hasSmartResponse.value = true;
    }
  };

  const setValues = () => {
    if (description.value?.participants && !currentData.value.executor) {
      let userId = Number(description.value?.participants);
      if (userId === 0) {
        const { userData } = storeToRefs(useUserStore());
        userId = userData.value?.id || 0;
      }
      const user = subordinates.value?.find((s) => userId === s.id);
      user && (currentData.value.executor = user);
      dirtyState.value.executor = true;
    }

    if (description.value?.titleRequest && !currentData.value.name) {
      currentData.value.name = description.value?.titleRequest;
      dirtyState.value.name = true;
    }

    if (
      description.value?.importance &&
      currentData.value.taskPriority.value === 'NORMAL'
    ) {
      currentData.value.taskPriority =
        priorityOptions[Number(description.value?.importance)];
      dirtyState.value.taskPriority = true;
    }

    if (description.value?.date && !currentData.value.deadline) {
      currentData.value.deadline = currentData.value.deadline = format(
        new Date(description.value?.date),
        TASK_DAY_FORMAT,
      );
      dirtyState.value.deadline = true;
    }
  };

  const pending = ref(false);
  const parseDescription = async () => {
    try {
      pending.value = true;
      await getDescription();
      setValues();
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  return {
    parseDescription,
    resetDirtyState,
    dirtyState,
    hasSmartResponse,
    pending,
  };
};
