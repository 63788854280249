import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "popup-share__container" }

import UiInput from '../control/UiInput.vue';
import services from '@/api/services';
import { copyToClipboard } from '@/utils/copyToClipboard';
import IconCopy from '../icon/IconCopy.vue';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { useI18n } from 'vue-i18n';
import { ElNotification } from 'element-plus';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetShareTask',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const { activeTask } = storeToRefs(useViewTaskStore());

const emits = __emit;

const isLoading = ref(false);
const isCopied = ref(false);
const linkInput = ref('');

const createLink = async () => {
  try {
    isLoading.value = true;
    if (activeTask.value?.id) {
      const { data } = await services.share.create({
        taskId: activeTask.value?.id,
      });
      linkInput.value = data;
    }
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const copyText = () => {
  if (!linkInput.value) return;
  const result = copyToClipboard(linkInput.value);
  if (!result) return;
  isCopied.value = true;
  ElNotification.success({
    message: t('task_sharing.link_copied'),
    offset: 80,
  });
  emits('onClose');
};

onMounted(() => createLink());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "popup-share__create",
      onClick: copyText
    }, [
      _createVNode(UiInput, {
        modelValue: linkInput.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((linkInput).value = $event)),
        readonly: "",
        class: _normalizeClass({ success: isCopied.value })
      }, {
        action: _withCtx(() => [
          _createVNode(IconCopy, { class: "popup-share__create-copy" })
        ]),
        _: 1
      }, 8, ["modelValue", "class"])
    ])
  ]))
}
}

})