import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout"
}
const _hoisted_2 = { class: "setup-modal" }
const _hoisted_3 = { class: "setup-modal__content" }
const _hoisted_4 = { class: "setup-modal__spinner" }
const _hoisted_5 = { class: "setup-modal__label" }
const _hoisted_6 = { class: "setup-modal__description" }

import UiIntegrationSpinner from '@/components/ui/UiIntegrationSpinner.vue';

type Props = { pending?: boolean };

export default /*@__PURE__*/_defineComponent({
  __name: 'AccountSetupSpinner',
  props: {
    pending: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "blur",
    appear: "",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.pending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "spinner", {}, () => [
                    _createVNode(UiIntegrationSpinner, {
                      link: require('@images/integrations-spinner/google-logo.svg')
                    }, null, 8, ["link"])
                  ])
                ]),
                _createElementVNode("span", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "label")
                ]),
                _createElementVNode("span", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "description")
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})