import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

import { computed } from 'vue';
import UiDropdown from './UiDropdown.vue';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import { TaskPriority } from '@/consts';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDropdownPriority',
  props: {
    modelValue: {},
    items: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiDropdown, _mergeProps({
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    items: _ctx.items,
    "priority-links": "",
    label: _ctx.$t('priority')
  }, _ctx.$attrs, { class: "ui-dropdown-priority" }), {
    customModelValue: _withCtx(({ modelValue }) => [
      (model.value.value)
        ? (_openBlock(), _createBlock(UiColorfilledText, {
            key: 0,
            badge: "",
            type: modelValue.value as TaskPriority[number],
            class: "ui-dropdown-priority__active"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(`task_priority.${modelValue.title}`)), 1)
            ]),
            _: 2
          }, 1032, ["type"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(modelValue.title), 1)
          ], 64))
    ]),
    default: _withCtx(({ item }) => [
      _createVNode(UiColorfilledText, {
        badge: "",
        type: item.value as TaskPriority[number]
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(`task_priority.${item.title}`)), 1)
        ]),
        _: 2
      }, 1032, ["type"])
    ]),
    _: 1
  }, 16, ["modelValue", "items", "label"]))
}
}

})