import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import UiCoachCard from '@/components/ui/UiCoachCard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CoachNoteRecord',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiCoachCard, {
    title: _ctx.$t('notes.couch.record.title'),
    content: _ctx.$t('notes.couch.record.content'),
    "compact-mode": ""
  }, null, 8, ["title", "content"]))
}
}

})