import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { ITableSorts, TableFilter } from '@/types/common';
import { taskStatuses } from '@/consts';
import {
  filtersMapping,
  subordinatesFiltersMapping,
} from '@/utils/filtersMapping';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { useI18n } from 'vue-i18n';
import {
  SharedTask,
  SharedTaskAccessRequest,
  SharedTasksRequestParams,
} from '@/types/share';
import services from '@/api/services';
import { Pageable } from '@/types/pagination';
import { tableSorting } from '@/utils/table';
import UiTableFiltersCheckboxList from '@/components/table/filters/UiTableFiltersCheckboxList.vue';

export const useSharedTasksStore = defineStore('sharedTasks', () => {
  const { t } = useI18n();
  const subordinatesStore = useSubordinatesStore();
  const { subordinatesOverallList } = storeToRefs(subordinatesStore);
  const { fetchOverallList } = subordinatesStore;

  const sharedTasksDTO = ref<SharedTasksRequestParams>({
    // executorIdList: [],
    // statusList: [],
    search: '',
    pageNum: 1,
    pageSize: 10,
  });
  const sorts = ref<ITableSorts<SharedTask>>({
    order: null,
    columnSort: '',
  });

  const sharedTasksRaw = shallowRef<Pageable<SharedTask> | null>(null);
  const pending = ref(false);
  const hasError = ref(false);
  const sharedTasksView = computed(() => {
    if (!sharedTasksRaw.value) return;
    return Object.assign(
      { ...sharedTasksRaw.value },
      {
        content: sharedTasksRaw.value?.content.map((t) => ({
          ...t,
          viewed: !t.availableToOpen ? 'DENIED' : t.viewed ? 'VIEW' : 'UNSEEN',
          name: t.taskInfo.name,
          taskContent: t.taskInfo.taskContent,
          executor: t.taskInfo.executor,
        })),
      },
    );
  });
  const fetch = async () => {
    try {
      pending.value = true;
      hasError.value = false;
      sharedTasksRaw.value = (
        await services.share.list({ ...sharedTasksDTO.value, ...sorts.value })
      ).data;
    } catch (e) {
      hasError.value = true;
      console.error(e);
    } finally {
      pending.value = false;
    }
  };
  watch(() => [sharedTasksDTO, sorts], fetch, { deep: true });

  const requests = ref<SharedTaskAccessRequest[]>([]);
  const fetchRequests = async () => {
    try {
      requests.value = (await services.share.requestsList()).data;
    } catch (e) {
      console.error(e);
    }
  };

  const requestAccess = async (id: string | number) => {
    try {
      await services.share.requestAccess(id);
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const filtersList = computed<TableFilter[]>(() => [
    {
      options: Object.keys(taskStatuses).map(filtersMapping),
      label: 'Status',
      filterField: 'statusList',
      component: UiTableFiltersCheckboxList,
    },
    {
      options:
        subordinatesOverallList.value?.map(subordinatesFiltersMapping) || [],
      label: t('tasks.filters.performer'),
      filterField: 'executorIdList',
      component: UiTableFiltersCheckboxList,
    },
  ]);

  const changeVisibility = async (params: {
    availableToOpen: boolean;
    linkId: number;
  }) => {
    await services.share.setVisibility(params);
  };

  function setPagination(pageNum: number, pageSize: number) {
    sharedTasksDTO.value.pageNum = pageNum;
    sharedTasksDTO.value.pageSize = pageSize;
  }

  function setFilter<FilterField extends keyof typeof sharedTasksDTO.value>(
    field: FilterField,
    values: (typeof sharedTasksDTO.value)[FilterField],
  ) {
    sharedTasksDTO.value[field] = values;
  }

  function setSort(columnSort: ITableSorts<SharedTask>['columnSort']) {
    tableSorting<ITableSorts<SharedTask>>(sorts.value, columnSort);
  }

  return {
    filtersList,
    sharedTasksDTO,
    setFilter,
    setPagination,
    setSort,
    sorts,
    fetchOverallList,
    fetch,
    subordinatesOverallList,
    sharedTasksView,
    hasError,
    pending,
    changeVisibility,
    requests,
    fetchRequests,
    requestAccess,
  };
});
