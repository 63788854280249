import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calendar-item__content" }

import { GoogleCalendar } from '@/types/integrations';
import IconMarker from '@/components/icon/IconMarker.vue';
import { computed } from 'vue';
import OneWaySync from '@/components/integrations/google/settings/heplers/OneWaySync.vue';
import TwoWaySync from '@/components/integrations/google/settings/heplers/TwoWaySync.vue';

type Props = { calendar: GoogleCalendar };

export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleAccountSettingsCalendar',
  props: {
    calendar: {}
  },
  emits: ['onStatusChange', 'onModeChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const syncState = computed(() =>
  ['CREATE', 'CONFIRMED'].includes(props.calendar.status)
    ? { value: true, color: 'var(--color-success)', text: 'Sync on' }
    : { value: false, color: '#1A2A39', text: 'Sync off' },
);

const onStatusChange = () => {
  const status = syncState.value.value ? 'NOT_VISIBLE' : 'CREATE';
  emits('onStatusChange', {
    status,
    id: props.calendar.id,
  });
};

const onModeChange = (mode: {
  syncCalendarMode: GoogleCalendar['syncCalendarMode'];
}) => emits('onModeChange', { ...mode, id: props.calendar.id });

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['calendar-item', { open: syncState.value.value }])
  }, [
    _createElementVNode("div", {
      class: "calendar-item__reference",
      onClick: onStatusChange
    }, [
      _createVNode(IconMarker, {
        color: _ctx.calendar.color
      }, null, 8, ["color"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.calendar.title), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_el_switch, {
          class: "green",
          modelValue: syncState.value.value
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", {
          style: _normalizeStyle({ color: syncState.value.color })
        }, _toDisplayString(syncState.value.text), 5)
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(OneWaySync, {
        sync: _ctx.calendar.syncCalendarMode,
        onOnModeChange: onModeChange
      }, null, 8, ["sync"]),
      _createVNode(TwoWaySync, {
        sync: _ctx.calendar.syncCalendarMode,
        onOnModeChange: onModeChange
      }, null, 8, ["sync"])
    ])
  ], 2))
}
}

})