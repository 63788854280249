import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import IconAdd from '@/components/icon/IconAdd.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyAddButton',
  emits: ['click'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.$style.wrapper),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.outer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.inner)
      }, [
        _createVNode(IconAdd, { color: "currentColor" })
      ], 2)
    ], 2)
  ], 2))
}
}

})