<script setup lang="ts">
import UiModal from '@/components/ui/UiModal.vue';
import NoteTransformTaskBody from '@/components/notes/transform/task-card/NoteTransformTaskBody.vue';
import NoteTransformTaskFooter from '@/components/notes/transform/task-card/NoteTransformTaskFooter.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { onBeforeMount } from 'vue';
import { useNoteToTaskStore } from '@/stores/notes/useNoteToTask';

const noteTransformStore = useNotesTransformStore();
const noteToTaskStore = useNoteToTaskStore();

onBeforeMount(() => {
  noteToTaskStore.start(noteTransformStore.rawTasks[0]);
});
</script>

<template>
  <UiModal
    :is-open="true"
    @on-close="noteTransformStore.end()"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :close-disabled="true"
    :title="'Create a new task'"
    class="note-task-card-modal"
  >
    <template #body>
      <NoteTransformTaskBody />
    </template>
    <template #footer>
      <NoteTransformTaskFooter />
    </template>
  </UiModal>
</template>

<style lang="scss">
.note-task-card-modal {
  .modal__body {
    padding: 1.6rem;
  }

  .el-dialog__footer {
    padding: 2.2rem 2.4rem !important;
  }
}
</style>
