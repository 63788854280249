import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header__balance" }
const _hoisted_2 = { class: "header__balance-left" }
const _hoisted_3 = { class: "header__balance-item purple-text" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import UiPlanTitle from '@/components/ui/UiPlan/UiPlanTitle.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { useWorkspaceStore } from '@/stores/workspaces';
import { whenever } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetHeaderBalance',
  setup(__props) {

const billingStore = useTariffStore();
const { tariffsView, isPlansModalVisible } = storeToRefs(billingStore);

const workspaceStore = useWorkspaceStore();
const { currentWorkspace } = storeToRefs(workspaceStore);

whenever(
  () => currentWorkspace.value?.id,
  (newId, oldId) => {
    if (!oldId || oldId === newId) return;
    billingStore.fetchUserTariff();
  },
);

const { t } = useI18n();

const plans = {
  basic: {
    next: 'advanced',
  },
  advanced: {
    next: 'premium',
  },
  premium: {
    next: '',
  },
};

const nextPlan = computed(() => {
  return (
    plans[
      tariffsView?.value?.content?.name?.toLowerCase() as keyof typeof plans
    ]?.next || undefined
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(t)('header.balance')) + " ", 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(tariffsView).content.recognitionLeftHeader) + " " + _toDisplayString(_unref(t)('header.min_left')), 1)
    ]),
    (nextPlan.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "header__balance-upgrade",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isPlansModalVisible.value = true))
        }, [
          _createTextVNode(_toDisplayString(_unref(t)('header.upgrade')) + " ", 1),
          _createVNode(UiPlanTitle, {
            type: nextPlan.value,
            class: "header__balance-plan",
            size: "small"
          }, null, 8, ["type"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})