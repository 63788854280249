import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "15",
  height: "16",
  viewBox: "0 0 15 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconCopy',
  props: {
  color: {
    type: String,
    required: false,
    default: '#778BEE',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M5.21387 2.61272V10.7891C5.21387 11.1505 5.37049 11.4971 5.64928 11.7527C5.92808 12.0082 6.3062 12.1518 6.70047 12.1518H12.6469C13.0412 12.1518 13.4193 12.0082 13.6981 11.7527C13.9769 11.4971 14.1335 11.1505 14.1335 10.7891V4.8217C14.1335 4.64016 14.0939 4.46045 14.017 4.29313C13.9402 4.12581 13.8277 3.97425 13.686 3.84735L11.222 1.63838C10.9443 1.38945 10.5713 1.25004 10.1829 1.25H6.70047C6.3062 1.25 5.92808 1.39357 5.64928 1.64913C5.37049 1.90469 5.21387 2.25131 5.21387 2.61272V2.61272Z",
      stroke: __props.color,
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M11.1607 12.2178V13.6714C11.1607 14.0569 10.9867 14.4266 10.6769 14.6992C10.3671 14.9718 9.94701 15.1249 9.50893 15.1249H2.90179C2.4637 15.1249 2.04357 14.9718 1.7338 14.6992C1.42403 14.4266 1.25 14.0569 1.25 13.6714V5.67672C1.25 5.2912 1.42403 4.92148 1.7338 4.64889C2.04357 4.37629 2.4637 4.22314 2.90179 4.22314H4.55357",
      stroke: __props.color,
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_3)
  ]))
}
}

})