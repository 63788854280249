import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@images/integrations/google/voiset-logo.webp'
import _imports_1 from '@images/integrations/google/two-way-arrows.svg'
import _imports_2 from '@images/integrations/google/google-logo.svg'


import { GoogleCalendar } from '@/types/integrations';

type Props = { sync: GoogleCalendar['syncCalendarMode'] };

export default /*@__PURE__*/_defineComponent({
  __name: 'TwoWaySyncSmall',
  props: {
    sync: {}
  },
  emits: ['onModeChange'],
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onModeChange', { syncCalendarMode: 'DUAL' })))
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("img", {
      class: "logo",
      src: _imports_0,
      alt: ""
    }, null, -1),
    _createElementVNode("img", {
      src: _imports_1,
      alt: ""
    }, null, -1),
    _createElementVNode("img", {
      class: "logo",
      src: _imports_2,
      alt: ""
    }, null, -1)
  ])))
}
}

})