<script setup lang="ts">
import { computed } from 'vue';
import { differenceInMinutes, endOfDay } from 'date-fns';

type Props = { range?: number; start?: Date; end?: Date };
const props = defineProps<Props>();
defineEmits<{ (e: 'setPreset', data: number): void }>();

const presets = [
  { label: '15m', minutes: 15 },
  { label: '30m', minutes: 30 },
  { label: '45m', minutes: 45 },
  { label: '1h', minutes: 60 },
  { label: '2h', minutes: 120 },
];

const range = computed(
  () =>
    !!props.end && !!props.start && differenceInMinutes(props.end, props.start),
);

const minutesTillDayEnd = computed(
  () =>
    !!props.start && differenceInMinutes(endOfDay(props.start), props.start),
);

const disabled = (min: number) =>
  minutesTillDayEnd.value && min > minutesTillDayEnd.value;
</script>

<template>
  <div class="ui-time-preset">
    <span>How long?</span>
    <div>
      <div
        v-for="p in presets"
        :key="p.minutes"
        @click="$emit('setPreset', p.minutes)"
        :class="{ active: range === p.minutes, disabled: disabled(p.minutes) }"
      >
        {{ p.label }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ui-time-preset {
  @include f14;
  width: 100%;

  & > div {
    @include flex-row(1rem);
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    & > div {
      background: #cddeff;
      border-radius: 0.4rem;
      flex: 1;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      user-select: none;

      &.active {
        background: var(--color-primary);
        color: #fcfcfc;
      }

      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }
    }
  }
}
</style>
