import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal__title" }
const _hoisted_2 = {
  key: 0,
  class: "modal__icon"
}
const _hoisted_3 = { class: "modal__actions" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "modal__body" }

import IconClose from '@/components/icon/IconClose.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'UiModal',
  props: {
    isOpen: { type: Boolean },
    title: {}
  },
  emits: ['onClose', 'onCloseClick'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;


return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, _mergeProps({
    "model-value": _ctx.isOpen,
    class: "modal",
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('onClose'))),
    onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => {
      emit('onClose');
      emit('onCloseClick');
    }, ["stop"]), ["esc"]))
  }, _ctx.$attrs), {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$slots.headerIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "headerIcon")
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "headerTitle", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$slots.headerActions)
          ? _renderSlot(_ctx.$slots, "headerActions", { key: 0 })
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "modal__close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
            emit('onClose');
            emit('onCloseClick');
          }),
          disabled: !_ctx.$attrs['close-disabled']
        }, [
          (!_ctx.$attrs['show-close'])
            ? (_openBlock(), _createBlock(IconClose, { key: 0 }))
            : _createCommentVNode("", true)
        ], 8, _hoisted_4)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "body")
      ])
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    _: 3
  }, 16, ["model-value"]))
}
}

})