import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "market-links" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  href: "https://apps.apple.com/us/app/voiset/id6446641085",
  target: "_blank"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  href: "https://play.google.com/store/apps/details?id=com.unionsmarttechnology.voiset",
  target: "_blank"
}
const _hoisted_6 = ["src"]


export default /*@__PURE__*/_defineComponent({
  __name: 'MarketLinks',
  props: {
    largeButtons: { type: Boolean },
    noDescription: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.noDescription)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('mobile_app')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("a", _hoisted_3, [
      _createElementVNode("img", {
        src: 
          require(`@images/app-markets/apple-btn${
            _ctx.largeButtons ? '.webp' : '-small.svg'
          }`)
        ,
        alt: ""
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("a", _hoisted_5, [
      _createElementVNode("img", {
        src: 
          require(`@images/app-markets/google-btn${
            _ctx.largeButtons ? '.webp' : '-small.svg'
          }`)
        ,
        alt: ""
      }, null, 8, _hoisted_6)
    ])
  ]))
}
}

})