import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal-buy__title" }
const _hoisted_2 = { class: "modal-buy__cards" }

import ModalNotification from '@/components/modals/ModalNotification.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import CardTariff from '@/components/cards/CardTariff.vue';
import { onMounted, ref } from 'vue';
import ModalPlanChange from '@/components/modals/Billing/ModalPlanChange.vue';
import { TranslateResult } from 'vue-i18n';

type Props = { isOpen?: boolean; title?: string | TranslateResult };

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalPlanBuy',
  props: {
    isOpen: { type: Boolean },
    title: {}
  },
  emits: ['close'],
  setup(__props: any) {




const isChangeSubscriptionVisible = ref(false);
const currentTariffId = ref<number>();
const currentPaymentId = ref<string>();
const isAnnually = ref(false);

const onConfirmChangeSubscription = (
  tariffId: number,
  annuallyPeriod: boolean,
  paymentId: string,
) => {
  currentTariffId.value = tariffId;
  currentPaymentId.value = paymentId;
  isAnnually.value = annuallyPeriod;
  isChangeSubscriptionVisible.value = true;
};

const billingStore = useTariffStore();
const { tariffsList, isTariffsListLoading } = storeToRefs(billingStore);

onMounted(() => !isTariffsListLoading.value && billingStore.fetchTariffList());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ModalNotification, {
      width: "120rem",
      "is-open": _ctx.isOpen,
      onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, {
      header: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title || _ctx.$t('card_tariff.label')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tariffsList), (item) => {
            return (_openBlock(), _createBlock(CardTariff, {
              item: item,
              key: item.id,
              onChangeSubscription: onConfirmChangeSubscription
            }, null, 8, ["item"]))
          }), 128))
        ])
      ]),
      footer: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("div", null, null, -1)
      ])),
      _: 1
    }, 8, ["is-open"]),
    _createVNode(ModalPlanChange, {
      "payment-id": currentPaymentId.value,
      "is-open": isChangeSubscriptionVisible.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (isChangeSubscriptionVisible.value = false)),
      onCloseWithSuccess: _cache[2] || (_cache[2] = ($event: any) => {
      _ctx.$emit('close');
      isChangeSubscriptionVisible.value = false;
    }),
      onCloseWithFail: _cache[3] || (_cache[3] = ($event: any) => (
      _ctx.$router.push({
        name: 'PurchaseTariffId',
        params: { tariffId: currentTariffId.value },
        query: { isAnnually: String(isAnnually.value), paymentFailed: 'true' },
      })
    ))
    }, null, 8, ["payment-id", "is-open"])
  ], 64))
}
}

})