import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "calendar-popup__content" }
const _hoisted_2 = { class: "calendar-popup__date" }
const _hoisted_3 = {
  key: 0,
  class: "calendar-popup__executor"
}
const _hoisted_4 = { style: {"font-size":"1.4rem","line-height":"1.6rem"} }
const _hoisted_5 = { class: "calendar-popup__time" }
const _hoisted_6 = { class: "calendar-popup__time-row" }
const _hoisted_7 = { class: "calendar-popup__time-container" }
const _hoisted_8 = { class: "calendar-popup__time-row" }
const _hoisted_9 = { class: "calendar-popup__time-row" }
const _hoisted_10 = { class: "calendar-popup__time-row" }
const _hoisted_11 = { class: "calendar-popup__time-controls" }

import { computed, ref, toRef, watch } from 'vue';
import TimeSelectRange from '@/components/time/TimeSelectRange.vue';
import UiButton from '@/components/control/UiButton.vue';
import { SetTaskTimeParams, Task } from '@/types/tasks';
import {
  addHours,
  addMinutes,
  differenceInMinutes,
  endOfDay,
  getMonth,
  getYear,
  isSameDay,
  isValid,
  startOfHour,
} from 'date-fns';
import format from 'date-fns/format';
import { useCalendarWorkload } from '@/hooks/useCalendarWorkload';
import { useTasksStore } from '@/stores/tasks/tasks';
import { DatePicker } from 'v-calendar';
import { useI18n } from 'vue-i18n';
import { isAllDayDate } from '@/utils/time';
import { watchDebounced } from '@vueuse/core';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { SubordinateViewType } from '@/utils/subordinates';
import { concatName } from '@/utils/text';
import IconMarker from '@/components/icon/IconMarker.vue';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import TimeReminder from '@/components/time/TimeReminder.vue';
import { TASK_DAY_FORMAT } from '@/consts/timeFormats';
import UiInput from '@/components/control/UiInput.vue';
import { POPPER_MODIFIERS_DEFAULT } from '@/consts/agenda/popperModifiers';
import TimePresets from '@/components/time/TimePresets.vue';

type Props = {
  modelValue: string;
  endTime?: string;
  dateView: string;
  executor?: Task['executor'] | SubordinateViewType | null;
  companyId?: Task['companyId'];
  reminder?: string | false;
  isShare?: boolean;
  isReadonly?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'PopupTaskDatePicker',
  props: {
    modelValue: {},
    endTime: {},
    dateView: {},
    executor: {},
    companyId: {},
    reminder: { type: [String, Boolean] },
    isShare: { type: Boolean },
    isReadonly: { type: Boolean }
  },
  emits: ['update:modelValue', 'update:time'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const isShare = toRef(props, 'isShare');

const { t } = useI18n();
const { formatWithLocale } = useCurrentLanguage();

const calendar = ref<InstanceType<typeof DatePicker> | null>(null);
const executor = toRef(props, 'executor');
const tasksStore = useTasksStore();
const { getMonthTaskCounts } = tasksStore;

const { attributes, updateCalendar } = useCalendarWorkload(
  getMonthTaskCounts,
  computed(() => ({
    taskDTO: {
      ...(executor.value?.id && { executorIdList: [executor.value.id] }),
    },
    companyId: props.companyId,
  })),
);
const currentWorkloadLevel = computed(() => {
  const customColorMapping: Record<
    string,
    { color?: string; message: string }
  > = {
    'custom-green': { color: '#9bda4f', message: t('workload.low') },
    'custom-orange': { color: '#e98629', message: t('workload.average') },
    'custom-red': { color: '#ed4436', message: t('workload.high') },
    'custom-gray': { message: t('workload.empty') },
    empty: { message: t('workload.empty') },
  };
  const dayColor = attributes.value.find(
    (a) => a.dates.getDate() === new Date(date.value).getDate(),
  )?.highlight.color;
  return customColorMapping[dayColor || 'empty'];
});

const isCalendarVisible = ref(false);
const inputRef = ref<InstanceType<typeof UiInput>>();

const date = ref();
const start = ref<Date | undefined>();
const end = ref<Date | undefined>();
const reminder = ref<string | false>(false);
const isAllDay = ref(true);

const currentTimeView = computed(() => {
  let time = '';
  if (start.value) time += format(start.value, 'HH:mm');
  if (end.value && start.value) time += ` - ${format(end.value, 'HH:mm')}`;
  return time;
});

const calendarDateView = computed(() => {
  const computedDate =
    (isCalendarVisible.value &&
      isValid(date.value) &&
      formatWithLocale(date.value, 'dd MMMM yyyy')) ||
    props.dateView ||
    '';

  return (
    `${computedDate}${
      currentTimeView.value ? ' | ' + currentTimeView.value : ''
    }`.trim() || undefined
  );
});

const isCurrentDateValid = computed(() => isValid(new Date(props.modelValue)));

const onCalendarUpdate = (d: Date) => {
  // if (isEqual(d, new Date(props.modelValue))) return;
  date.value = d;
};

const onSaveTime = () => {
  emits('update:time', {
    startTime: start.value,
    endTime: end.value,
    reminderTime: reminder.value ? new Date(reminder.value) : false,
  } satisfies SetTaskTimeParams);
};

const onSave = () => {
  emits('update:modelValue', date.value);
  date.value = undefined;
  onSaveTime();
};

const onResetTime = () => {
  start.value = undefined;
  end.value = undefined;
  reminder.value = false;
};

const onExecutorOrWSChange = async () => {
  if (isShare.value) return;
  const date = isCurrentDateValid.value
    ? new Date(props.modelValue)
    : new Date();
  await updateCalendar([{ month: getMonth(date) + 1, year: getYear(date) }]);
  onDateChange();
};

const onDateChange = () => {
  if (!isCurrentDateValid.value) return onResetTime();
  date.value = props.modelValue || format(new Date(), TASK_DAY_FORMAT);
  end.value = props.endTime ? new Date(props.endTime) : undefined;
  isAllDay.value = !(start.value && end.value);
  start.value = isAllDayDate(props.modelValue)
    ? undefined
    : new Date(props.modelValue);
  reminder.value = props.reminder
    ? format(new Date(props.reminder), 'yyyy-MM-dd HH:mm')
    : false;
};

const onCalendarToggle = async () => {
  if (!isCalendarVisible.value) {
    date.value = undefined;
    isAllDay.value = true;
    queueMicrotask(() => inputRef.value?.input?.blur());
    return;
  }
  if (!isCurrentDateValid.value) return;
  onDateChange();
  await calendar.value?.move(props.modelValue);
};

const onToggleAllDay = (value: boolean) => {
  isAllDay.value = value;
  if (isAllDay.value) onResetTime();
  else {
    start.value = startOfHour(addHours(new Date(), 1));

    const endIntervalTime = addMinutes(start.value, 30);
    end.value = isSameDay(start.value, endIntervalTime)
      ? endIntervalTime
      : endOfDay(start.value);
  }
};

watch(() => isCalendarVisible.value, onCalendarToggle);
watch(() => [executor.value?.id, props.companyId], onExecutorOrWSChange);
watch(() => props.modelValue, onDateChange, { immediate: true });
watchDebounced(
  () => props.modelValue,
  async () => await onExecutorOrWSChange(),
  { debounce: 300 },
);

const emits = __emit;

return (_ctx: any,_cache: any) => {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    trigger: "click",
    width: "fit-content",
    "popper-class": "calendar-popup",
    disabled: _ctx.isReadonly,
    visible: isCalendarVisible.value,
    "popper-options": _unref(POPPER_MODIFIERS_DEFAULT)
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", {
        onClick: _cache[8] || (_cache[8] = ($event: any) => (isCalendarVisible.value = !isCalendarVisible.value))
      }, [
        _createVNode(UiInput, _mergeProps(_ctx.$attrs, {
          ref_key: "inputRef",
          ref: inputRef,
          "model-value": calendarDateView.value,
          label: _ctx.$t('modal_task.date_range'),
          "icon-align": "right",
          icon: _ctx.$attrs.isSmartValue ? 'smart' : 'calendar',
          readonly: ""
        }), null, 16, ["model-value", "label", "icon"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "calendar-popup__layout",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isCalendarVisible.value = false))
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (executor.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(UiAvatar, {
                  size: "3.5rem",
                  "avatar-link": executor.value?.avatar?.uuidLink,
                  "fallback-name": _unref(concatName)(executor.value.firstName, executor.value.lastName)
                }, null, 8, ["avatar-link", "fallback-name"]),
                _createElementVNode("span", null, _toDisplayString(_unref(concatName)(executor.value.firstName, executor.value.lastName)), 1),
                _createVNode(_Transition, {
                  name: "fade",
                  appear: "",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (currentWorkloadLevel.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          class: "calendar-popup__workload",
                          key: currentWorkloadLevel.value.color
                        }, [
                          (currentWorkloadLevel.value.color)
                            ? (_openBlock(), _createBlock(IconMarker, {
                                key: 0,
                                color: currentWorkloadLevel.value.color
                              }, null, 8, ["color"]))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(currentWorkloadLevel.value.message), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_component_v_date_picker, {
            key: isCalendarVisible.value,
            locale: _ctx.$i18n.locale,
            "model-value": _ctx.modelValue,
            attributes: _unref(attributes),
            "onUpdate:modelValue": onCalendarUpdate,
            onDidMove: _cache[1] || (_cache[1] = ($event: any) => (!isShare.value && _unref(updateCalendar)($event))),
            "min-date": new Date(),
            "is-required": "",
            borderless: "",
            ref_key: "calendar",
            ref: calendar,
            style: {"min-width":"unset","--vc-width":"30rem","margin":"auto"}
          }, {
            "header-title": _withCtx(({ title }) => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(title), 1)
            ]),
            _: 1
          }, 8, ["locale", "model-value", "attributes", "min-date"]))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_checkbox, {
              disabled: !date.value,
              modelValue: isAllDay.value,
              "onUpdate:modelValue": onToggleAllDay,
              class: "no-padding"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" All day ")
              ])),
              _: 1
            }, 8, ["disabled", "modelValue"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(['calendar-popup__time-options', { open: !isAllDay.value }])
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(TimePresets, {
                  onSetPreset: _cache[2] || (_cache[2] = ($event: any) => (end.value = _unref(addMinutes)(start.value, $event))),
                  start: start.value,
                  end: end.value
                }, null, 8, ["start", "end"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(TimeSelectRange, {
                  disabled: !date.value || isAllDay.value,
                  startTime: start.value,
                  "onUpdate:startTime": _cache[3] || (_cache[3] = ($event: any) => ((start).value = $event)),
                  endTime: end.value,
                  "onUpdate:endTime": _cache[4] || (_cache[4] = ($event: any) => ((end).value = $event))
                }, null, 8, ["disabled", "startTime", "endTime"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(TimeReminder, {
                  disabled: !date.value || isAllDay.value,
                  startTime: start.value,
                  modelValue: reminder.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((reminder).value = $event))
                }, null, 8, ["disabled", "startTime", "modelValue"])
              ])
            ])
          ], 2),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(UiButton, {
              plain: "",
              onClick: _cache[6] || (_cache[6] = ($event: any) => {
              onDateChange();
              isCalendarVisible.value = false;
            })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ]),
              _: 1
            }),
            _createVNode(UiButton, {
              onClick: _cache[7] || (_cache[7] = ($event: any) => {
              onSave();
              isCalendarVisible.value = false;
            }),
              "is-disabled": !date.value || (!isAllDay.value && !(start.value && end.value))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('save')), 1)
              ]),
              _: 1
            }, 8, ["is-disabled"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["disabled", "visible", "popper-options"]))
}
}

})