import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "note-transform-calendar"
}

import NoteTransformCalendarDay from '@/components/notes/transform/calendar/NoteTransformCalendarDay.vue';
import { useNotesByCalendar } from '@/stores/notes/useNotesByCalendar';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoteTransformCalendar',
  setup(__props) {

const notesByCalendar = useNotesByCalendar();
const { calendar, currentGroup } = storeToRefs(notesByCalendar);

return (_ctx: any,_cache: any) => {
  return (_unref(currentGroup) && _unref(calendar).length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(calendar), (day) => {
          return (_openBlock(), _createBlock(NoteTransformCalendarDay, {
            items: day.items,
            "onUpdate:items": ($event: any) => ((day.items) = $event),
            color: _unref(currentGroup).workspace.color,
            date: day.date,
            key: day.date
          }, null, 8, ["items", "onUpdate:items", "color", "date"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})