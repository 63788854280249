import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@images/workspaces/switch.webp'


const _hoisted_1 = { class: "workspaces-join__content" }
const _hoisted_2 = { class: "workspaces-join__footer" }
const _hoisted_3 = { class: "workspaces-join__switch-header" }

import ModalNotification from '@/components/modals/ModalNotification.vue';
import { ref } from 'vue';
import VOtpInput from 'vue3-otp-input';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { ElNotification } from 'element-plus';
import ModalSwitchWorkspace from '@/components/modals/Workspaces/ModalSwitchWorkspace.vue';
import { Workspace } from '@/types/workspaces';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalJoinWorkspace',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {


const emits = __emit;

const { t } = useI18n();

const invitationCode = ref('');
const joinedWorkspace = ref<Workspace>();
const step = ref(1);
const pending = ref(false);

const invitationsStore = useInvitationsStore();
const { setInvitationStatus } = invitationsStore;

const onJoin = async () => {
  if (!invitationCode.value) return;
  try {
    pending.value = true;
    joinedWorkspace.value = (
      await setInvitationStatus({
        invitationCode: invitationCode.value,
        result: true,
      })
    ).data;
    ElNotification.success({
      message: t('workspaces.join.joined_msg'),
      offset: 80,
    });
    step.value = 2;
  } catch (e) {
    console.error(e);
    ElNotification.error({
      message: t('workspaces.join.no_code_msg'),
      offset: 80,
    });
  } finally {
    pending.value = false;
  }
};

const onClose = () => {
  setTimeout(() => (step.value = 1), 1000);
  emits('close');
};

return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (step.value === 1)
    ? (_openBlock(), _createBlock(ModalNotification, {
        key: 0,
        onOnClose: onClose,
        width: "38rem",
        title: _ctx.$t('workspaces.join.title'),
        "is-open": _ctx.isOpen
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('workspaces.join.message')), 1),
            _createVNode(_unref(VOtpInput), {
              "input-classes": "large",
              ref: "otpInput",
              inputType: "number",
              "num-inputs": 8,
              value: invitationCode.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((invitationCode).value = $event)),
              "should-auto-focus": true,
              "should-focus-order": true
            }, null, 8, ["value"])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ui_button, {
              onClick: onJoin,
              "is-loading": pending.value,
              "is-disabled": invitationCode.value.length !== 8
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('workspaces.join.confirm')), 1)
              ]),
              _: 1
            }, 8, ["is-loading", "is-disabled"])
          ])
        ]),
        _: 1
      }, 8, ["title", "is-open"]))
    : (step.value === 2)
      ? (_openBlock(), _createBlock(ModalSwitchWorkspace, {
          key: 1,
          onClose: onClose,
          workspace: joinedWorkspace.value,
          width: "35rem",
          "is-open": ""
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('workspaces.join.success', { name: joinedWorkspace.value?.name })), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('workspaces.join.switch')), 1),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "workspaces-join__switch-img",
              src: _imports_0,
              alt: ""
            }, null, -1))
          ]),
          _: 1
        }, 8, ["workspace"]))
      : _createCommentVNode("", true)
}
}

})