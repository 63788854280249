import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-profile" }
const _hoisted_2 = { class: "widget-profile__container" }

import { ref, computed, watch } from 'vue';

import IconChevron from '@/components/icon/IconChevron.vue';
import UserDropDownMenu from '@/components/user/UserDropDownMenu.vue';
import ModalHelp from '@/components/modals/Support/ModalHelp.vue';
import ModalFeedback from '@/components/modals/Support/ModalFeedback.vue';

import { useUserStore } from '@/stores/user';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import { concatName } from '@/utils/text';


export default /*@__PURE__*/_defineComponent({
  __name: 'WidgetProfile',
  setup(__props) {

const userStore = useUserStore();
const userName = computed(() =>
  concatName(userStore.userData?.firstName, userStore.userData?.lastName),
);

const coachesStore = useCoachesStore();
const { activeCoach } = storeToRefs(coachesStore);

const isOpenContactModal = ref(false);
const isOpenFeedbackModal = ref(false);

const isShow = ref(false);

const toggleShow = (val: boolean) => {
  isShow.value = val;
};

const openFeedback = () => {
  toggleShow(false);
  isOpenContactModal.value = false;
  isOpenFeedbackModal.value = true;
};

const openContact = () => {
  toggleShow(false);
  isOpenFeedbackModal.value = false;
  isOpenContactModal.value = true;
};

const chevronDirection = computed(() => {
  return isShow.value ? 'top' : 'bottom';
});

watch(
  () => activeCoach.value,
  () => {
    isShow.value =
      activeCoach.value.activeStep === 6 &&
      activeCoach.value.name === 'dashboard';
  },
  { deep: true },
);

return (_ctx: any,_cache: any) => {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_popover, {
      placement: "bottom",
      "popper-class": "user__modal",
      width: "260",
      trigger: "click",
      onHide: _cache[1] || (_cache[1] = ($event: any) => (toggleShow(false))),
      onShow: _cache[2] || (_cache[2] = ($event: any) => (toggleShow(true))),
      visible: isShow.value,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((isShow).value = $event))
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(UiAvatar, {
            "avatar-link": _unref(userStore).userData?.avatar?.uuidLink,
            "fallback-name": userName.value
          }, null, 8, ["avatar-link", "fallback-name"]),
          _createVNode(IconChevron, {
            class: "ui-filter__button-arrow",
            direction: chevronDirection.value
          }, null, 8, ["direction"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(UserDropDownMenu, {
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => (toggleShow(false))),
          onOpenFeedback: openFeedback,
          onOpenContact: openContact,
          id: "dashboard-menu"
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(ModalFeedback, {
      "is-open": isOpenFeedbackModal.value,
      onOnClose: _cache[4] || (_cache[4] = ($event: any) => (isOpenFeedbackModal.value = false)),
      onOpenContact: openContact
    }, null, 8, ["is-open"]),
    _createVNode(ModalHelp, {
      "is-open": isOpenContactModal.value,
      onOnClose: _cache[5] || (_cache[5] = ($event: any) => (isOpenContactModal.value = false)),
      onOpenFeedback: openFeedback
    }, null, 8, ["is-open"])
  ]))
}
}

})