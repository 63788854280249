import { RouteRecordRaw } from 'vue-router';
const ShareView = () => import('@/views/shareLink.vue');

export default {
  path: '/shareLink/:uuid',
  component: ShareView,
  meta: {
    layout: 'unsigned',
    public: true,
    header: true,
  },
} as RouteRecordRaw;
