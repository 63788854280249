<template>
  <div :class="{ divided: state.isShowComments }" class="modal-view-footer">
    <div class="modal-view-footer__content" v-skeleton="state.isAudioLoading">
      <UiAudioPlayer v-if="audioFile" :audio="audioFile" />
      <ModalFooterControl :is-readonly="isReadonly" />
    </div>
    <div class="modal-view-footer__comments">
      <ui-input
        ref="commentInput"
        v-model="commentText"
        :placeholder="$t('view_modal_task.send_comments')"
        :readonly="isBlockSendComment || isMessageSend"
        class="modal-view-footer__input"
        @keydown.enter.exact.stop.prevent="sendComment"
      >
        <template #action>
          <img
            v-if="!isMessageSend"
            :class="{ 'modal-view-footer__send-disabled': isBlockSendComment }"
            :src="require(`@images/pictogram/send.svg`)"
            alt="send"
            class="modal-view-footer__send"
            @click="sendComment"
          />
          <IconSpinner
            v-else
            circle-color="gray"
            class="modal-view-footer__loading"
            color="gray"
          />
        </template>
      </ui-input>
    </div>
  </div>
</template>
<script lang="ts" setup>
import UiAudioPlayer from '@/components/ui/UIAudioPlayer.vue';
import ModalFooterControl from '@/components/modals/Tasks/ModalViewTask/ModalStatusControl.vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';
import { useViewTaskStore } from '@/stores/viewTask/viewTask';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

withDefaults(defineProps<{ isReadonly?: boolean }>(), { isReadonly: false });

const viewTask = useViewTaskStore();

const commentText = ref('');

const commentInput = ref();

const sendComment = async (e: any) => {
  if (isMessageSend.value) return;

  if (commentText.value) {
    isMessageSend.value = true;

    if (e) e.target?.blur();

    await viewTask.createComment(commentText.value);
    commentText.value = '';
    await viewTask.getComments(1);

    const element = document.getElementById('comments_list');

    if (element) {
      element.scrollBy({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    }

    isMessageSend.value = false;
  }
};

const isBlockSendComment = computed(() => {
  return activeTask.value.taskStatus === 'CLOSED';
});

const isMessageSend = ref(false);

const { audioFile, activeTask, state } = storeToRefs(viewTask);
</script>
<style lang="scss" scoped>
.modal-view-footer {
  display: grid;
  grid-template-columns: 1fr 0fr;
  height: 8rem;
  z-index: 100;
  position: relative;
  transition: all 0.5s ease-in-out;

  @media (max-width: 640px) {
    padding: 0 1.6rem;
    &.divided {
      padding: 0;
    }
  }

  &.divided {
    grid-template-columns: 64fr 39fr;

    @media (max-width: 850px) {
      grid-template-columns: 0fr 1fr;
    }
  }

  &__content {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 2rem;
  }

  &__comments {
    display: grid;
    overflow: hidden;
    box-sizing: content-box;
    border-left: 0.2rem solid transparent;
    transition: border-left-color 0.5s ease-in-out;
    min-width: 100%;

    .divided & {
      border-left-color: #dde3ec;

      @media (max-width: 850px) {
        border-left-color: transparent;
      }
    }
  }

  &__input {
    width: calc(100% - 4rem);
    margin: 0 auto;
  }

  &__send {
    position: absolute;
    right: 1.2rem;

    &-disabled {
      opacity: 0.5;
    }

    &:hover:not(.modal-view-footer__send-disabled) {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__loading {
    color: gray;
    animation: spin 1.2s linear infinite;
    width: 7%;
    position: absolute;
    right: 1.2rem;
  }
}
</style>
