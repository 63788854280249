import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ui-filter" }
const _hoisted_2 = { class: "ui-filter__header" }
const _hoisted_3 = { class: "ui-filter-dropdown" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 2 }

import { inject } from 'vue';
import { TableFilter } from '@/types/common';
import { useI18n } from 'vue-i18n';
import { TTaskPriorityItem } from '@/consts';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTableFiltersCheckboxList',
  props: {
    icon: {},
    options: {},
    label: {},
    filterField: {},
    component: {}
  },
  setup(__props: any) {

const { t, te } = useI18n();

const getLabel = (label?: string) => (label && te(label) ? t(label) : label);

const props = __props;

const filtersState: Record<string, any> | undefined = inject('filtersState');
const setFilter: ((...args: any[]) => void) | undefined = inject('setFilter');

const resetFilters = () => {
  const filter = filtersState?.[props.filterField];
  setFilter && setFilter(props.filterField, filter instanceof Array ? [] : '');
};

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(getLabel(_ctx.label)), 1),
      _createElementVNode("div", {
        class: "ui-filter__clear",
        onClick: resetFilters
      }, _toDisplayString(_ctx.$t('tasks.filters.clear')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ui-filter-dropdown__row",
          key: option?.label
        }, [
          _createVNode(_component_el_checkbox_group, {
            "model-value": _unref(filtersState)?.[_ctx.filterField],
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(setFilter)?.(_ctx.filterField, $event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                "model-value": option.value,
                value: option.value
              }, {
                default: _withCtx(() => [
                  (_ctx.filterField === 'priorityList')
                    ? (_openBlock(), _createBlock(UiColorfilledText, {
                        key: 0,
                        badge: "",
                        type: option.value as TTaskPriorityItem,
                        style: {"width":"70%"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["type"]))
                    : (['statusList', 'status'].includes(_ctx.filterField))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("img", {
                            width: "16",
                            src: 
                  option?.icon ||
                  require(`@images/statuses/${String(
                    option.value,
                  ).toLowerCase()}.svg`)
                ,
                            alt: "icon"
                          }, null, 8, _hoisted_4),
                          _createTextVNode(" " + _toDisplayString(getLabel(option.label) || option.value), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(getLabel(option.label) || option.value), 1))
                ]),
                _: 2
              }, 1032, ["model-value", "value"])
            ]),
            _: 2
          }, 1032, ["model-value"])
        ]))
      }), 128))
    ])
  ]))
}
}

})