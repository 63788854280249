import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-view-task-list__container"
}
const _hoisted_2 = { class: "modal-view-task-list__date" }
const _hoisted_3 = { key: 1 }

import { watchOnce } from '@vueuse/core';
import { useViewTaskCommentsStore } from '@/stores/viewTask/viewTaskComments';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { nextTick, onBeforeUnmount, ref } from 'vue';
import ModalViewCommentsItem from '@/components/modals/Tasks/ModalViewTask/ModalViewCommentsItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalViewCommentsList',
  setup(__props) {

const comments = ref();
const viewTaskComments = useViewTaskCommentsStore();

const { commentsList, isLoadingComment } = storeToRefs(viewTaskComments);
const { userData } = storeToRefs(useUserStore());

const nextLoading = () => {
  if (comments.value.scrollTop < 200) {
    viewTaskComments.loadNextPage();
  }
};

watchOnce(
  () => commentsList.value,
  () => {
    if (commentsList.value) {
      nextTick(() => {
        comments.value.scrollBy({
          top: comments.value.scrollHeight,
          behavior: 'smooth',
        });

        setTimeout(() => {
          comments.value.addEventListener('scroll', nextLoading);
        }, 2000);
      });
    }
  },
);

onBeforeUnmount(() => {
  comments.value.removeEventListener('scroll', nextLoading);
});

return (_ctx: any,_cache: any) => {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    id: "comments_list",
    ref_key: "comments",
    ref: comments,
    class: "modal-view-task-list"
  }, [
    (_unref(isLoadingComment))
      ? (_openBlock(), _createBlock(_component_el_skeleton, {
          key: 0,
          animated: "",
          class: "modal-view-task-list__skeleton"
        }, {
          template: _withCtx(() => [
            _createVNode(_component_el_skeleton_item, { variant: "text" }),
            _createVNode(_component_el_skeleton_item, { variant: "text" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      appear: "",
      mode: "out-in",
      name: "fade"
    }, {
      default: _withCtx(() => [
        (_unref(commentsList) && _unref(commentsList)?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(commentsList), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.block,
                  class: "modal-view-task-list__block"
                }, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(item.date), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.messages, ({
              createTime,
              avatar,
              message,
              creatorFullName,
              creatorId,
            }) => {
                    return (_openBlock(), _createBlock(ModalViewCommentsItem, {
                      key: createTime,
                      avatar: avatar,
                      "create-time": createTime,
                      "creator-full-name": creatorFullName,
                      "is-my-comment": creatorId === _unref(userData)?.id,
                      message: message
                    }, null, 8, ["avatar", "create-time", "creator-full-name", "is-my-comment", "message"]))
                  }), 128))
                ]))
              }), 128))
            ]))
          : (!_unref(commentsList) && !_unref(isLoadingComment))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('modal_task.empty_comments')), 1))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 512))
}
}

})