import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notification-modal__btns" }

import ModalNotification from '@/components/modals/ModalNotification.vue';
import IconTrash from '@/components/icon/IconTrash.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDeleteWorkspace',
  props: {
    isOpen: { type: Boolean },
    message: {},
    title: {}
  },
  emits: ['on-confirm', 'close'],
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  const _component_ui_button = _resolveComponent("ui-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ModalNotification, _mergeProps(props, {
      class: "notification-modal",
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
      width: "45rem"
    }), {
      footer: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer", {}, () => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ui_button, {
              plain: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-confirm')))
            }, {
              default: _withCtx(() => [
                _createVNode(IconTrash, {
                  color: "#EC7975",
                  style: {"max-height":"1.6rem"}
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('workspaces.delete.confirm')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ui_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('workspaces.delete.cancel')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 3
    }, 16)
  ]))
}
}

})