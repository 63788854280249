import { defineStore, storeToRefs } from 'pinia';
import { computed, markRaw, ref, watch } from 'vue';
import { ActiveCoach, CoachName, CoachCard } from '@/types/coaches';
import { sleep } from '@/utils/common';
import CoachAnalytics from '@/components/coaches/home/CoachAnalytics.vue';
import CoachTasks from '@/components/coaches/home/CoachTasks.vue';
import CoachNotifications from '@/components/coaches/home/CoachNotifications.vue';
import CoachNotes from '@/components/coaches/home/CoachNotes.vue';
import CoachMenu from '@/components/coaches/home/CoachMenu.vue';
import CoachWorkspaces from '@/components/coaches/home/CoachWorkspaces.vue';
import CoachCalendar from '@/components/coaches/home/CoachCalendar.vue';
import CoachCalendarTab from '@/components/coaches/calendar/CoachCalendarTab.vue';
import CoachAnalyticsTab from '@/components/coaches/analytics/CoachAnalyticsTab.vue';
import CoachUsersTab from '@/components/coaches/users/CoachUsersTab.vue';
import CoachNoteSchedule from '@/components/coaches/note/CoachNoteSchedule.vue';
import CoachBillingTab from '@/components/coaches/billing/CoachBillingTab.vue';
import CoachRecord from '@/components/coaches/createTask/CoachRecord.vue';
import CoachAssignment from '@/components/coaches/createTask/CoachAssignment.vue';
import CoachShareTask from '@/components/coaches/viewTask/CoachShareTask.vue';
import CoachStatus from '@/components/coaches/viewTask/CoachStatus.vue';
import CoachAboutTask from '@/components/coaches/sharedTask/CoachAboutTask.vue';
import CoachFiles from '@/components/coaches/sharedTask/CoachFiles.vue';
import CoachNoteRecord from '@/components/coaches/note/CoachNoteRecord.vue';
import CoachUsersInvite from '@/components/coaches/users/CoachUsersInvite.vue';
import router from '@/router';
import { useWorkspaceStore } from '@/stores/workspaces';
import { ACTIVE_COACH_ELEMENT_STYLES } from '@/consts/coach';

export const useCoachesStore = defineStore(
  'coaches',
  () => {
    const workspacesStore = useWorkspaceStore();
    const { isWidgetVisible } = storeToRefs(workspacesStore);

    const coaches = computed<Record<CoachName, CoachCard[]>>(() => ({
      dashboard: [
        {
          component: markRaw(CoachTasks),
          attachTo: 'dashboard-tasks',
        },
        {
          component: markRaw(CoachCalendar),
          attachTo: 'dashboard-calendar',
        },
        {
          component: markRaw(CoachNotifications),
          attachTo: 'dashboard-notifications',
        },
        {
          component: markRaw(CoachAnalytics),
          attachTo: 'dashboard-analytics',
        },
        {
          component: markRaw(CoachNotes),
          attachTo: 'dashboard-notes',
        },
        {
          component: markRaw(CoachMenu),
          attachTo: 'dashboard-menu',
        },
        {
          component: markRaw(CoachWorkspaces),
          attachTo: 'dashboard-workspaces',
          onBefore: () => (isWidgetVisible.value = true),
          onAfter: () => (isWidgetVisible.value = false),
        },
      ],
      myTasks: [
        {
          component: markRaw(CoachCalendarTab),
          attachTo: 'calendar-tab',
          highlight: ['sidebar'],
        },
      ],
      analytics: [
        {
          component: markRaw(CoachAnalyticsTab),
          attachTo: 'analytics',
          highlight: ['sidebar'],
        },
      ],
      users: [
        {
          component: markRaw(CoachUsersTab),
          attachTo: 'users-tab',
          highlight: ['sidebar'],
        },
        {
          component: markRaw(CoachUsersInvite),
          attachTo: 'users-invites',
          onBefore: async () => {
            if (router.currentRoute.value.name === 'Invitations') return;
            await router.push({ name: 'Invitations' });
            await sleep(500);
            return router.isReady();
          },
        },
      ],
      billing: [
        {
          component: markRaw(CoachBillingTab),
          attachTo: 'billing-tab',
          highlight: ['sidebar'],
        },
      ],
      createTask: [
        { component: markRaw(CoachRecord), attachTo: 'task-record' },
        { component: markRaw(CoachAssignment), attachTo: 'task-assigment' },
      ],
      viewTask: [
        { component: markRaw(CoachShareTask), attachTo: 'view-share' },
        { component: markRaw(CoachStatus), attachTo: 'view-status' },
      ],
      sharedTask: [
        { component: markRaw(CoachAboutTask), attachTo: 'view-share' },
        { component: markRaw(CoachFiles), attachTo: 'shared-files' },
      ],
      createNote: [
        { component: markRaw(CoachNoteRecord), attachTo: 'note-record' },
      ],
      scheduleNote: [
        { component: markRaw(CoachNoteSchedule), attachTo: 'note-schedule' },
      ],
      note: [],
      monthAgenda: [],
      files: [],
    }));

    const coachNames = Object.keys(coaches.value) as CoachName[];
    const passedCoaches = ref<Partial<Record<CoachName, boolean>>>({});

    const isCoachesEnabled = ref(false);

    const activeCoach = ref<ActiveCoach>({
      name: 'dashboard',
      activeStep: 1,
      steps: coaches.value['dashboard'].length,
      isEnabled: false,
    });

    const activeCard = computed(
      () =>
        coaches.value[activeCoach.value.name]?.[
          activeCoach.value.activeStep - 1
        ],
    );

    const isCoachesVisible = computed(
      () => isCoachesEnabled.value && activeCoach.value.isEnabled,
    );

    function disableCoaches() {
      isCoachesEnabled.value = false;
    }

    function enableCoaches() {
      isCoachesEnabled.value = true;
    }

    function disableActiveCoach() {
      activeCoach.value.isEnabled = false;
      activeCoach.value.activeStep = 1;
    }

    function enableActiveCoach() {
      activeCoach.value.isEnabled = true;
    }

    function resolveCouch() {
      isCoachesEnabled.value ? disableCoaches() : resetCoach();
    }

    function setCoach(name: CoachName) {
      activeCoach.value = {
        name,
        activeStep: 1,
        steps: coaches.value[name].length,
        isEnabled: !passedCoaches.value[name],
      };
    }

    function resetCoach(name?: CoachName) {
      coachNames.forEach((n) => (passedCoaches.value[n] = false));
      activeCoach.value = {
        ...activeCoach.value,
        ...(name && { name }),
        isEnabled: true,
        activeStep: 1,
      };
      enableCoaches();
    }

    function setStep(step: number) {
      if (step < 1) return;
      if (step > activeCoach.value.steps) {
        passedCoaches.value[activeCoach.value.name] = true;
        return disableCoaches();
      }
      activeCoach.value.activeStep = step;
    }

    const isHighlightedItem = (item: string) => {
      if (activeCard.value?.highlight?.includes(item))
        return ACTIVE_COACH_ELEMENT_STYLES;
    };

    watch(
      () => [activeCoach.value.name, activeCoach.value.isEnabled],
      () => activeCoach.value.steps === 0 && disableActiveCoach(),
      { immediate: true },
    );

    return {
      isCoachesEnabled,
      isCoachesVisible,
      activeCoach,
      activeCard,
      setCoach,
      resetCoach,
      disableActiveCoach,
      enableActiveCoach,
      disableCoaches,
      setStep,
      resolveCouch,
      isHighlightedItem,
    };
  },
  { persist: true },
);
